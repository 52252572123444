import config from '../../../config'
import {
    GET_DATA_AUDITS,
    GET_DATA_AUDITS_CLONE,
    GET_DATA_AUDITS_MODULE,
    LOADING_DATA_AUDITS,
    PAGINATE_AUDIT,
    FILTER_TEXT_AUDIT,
    FILTER_ORDER_AUDIT,
    TOTAL_REGISTER_AUDIT,
} from '../../../Constans/Audits/Audits'
import { format, parseISO } from 'date-fns'

export const CreateAuditsReducer = () => async ( dispatch ) => {
    
    await fetch(config.api_url+'audits/audits-create',
    {
        mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
            }),
    }
    )
    .then( async res => res.json())
    .then(data => {
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const GetAuditsReducer = () => async ( dispatch, getState ) => {
    const {
        rex_filter_text_audit,
        rex_filter_order_audit,
        rex_paginate_audit,
    } = getState().audits

    dispatch({
        type: LOADING_DATA_AUDITS,
        payload : true
    })

    await fetch(config.api_url+'audits/audits-show?page='+rex_paginate_audit,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_auddescripcion:     rex_filter_text_audit.auddescripcion ? rex_filter_text_audit.auddescripcion : "",
                req_audruta:            rex_filter_text_audit.audruta ? rex_filter_text_audit.audruta : "",
                req_audaccion:          rex_filter_text_audit.audaccion ? rex_filter_text_audit.audaccion : "",
                req_audjsonentrada:     rex_filter_text_audit.audjsonentrada ? rex_filter_text_audit.audjsonentrada : "",
                req_audjsonsalida:      rex_filter_text_audit.audjsonsalida ? rex_filter_text_audit.audjsonsalida : "",
                req_usuusuario:         rex_filter_text_audit.usuusuario ? rex_filter_text_audit.usuusuario : "",
                req_pernombrecompleto:  rex_filter_text_audit.pernombrecompleto ? rex_filter_text_audit.pernombrecompleto : "",
                req_tpunombre:          rex_filter_text_audit.tpunombre ? rex_filter_text_audit.tpunombre : "",
                req_created_at:         rex_filter_text_audit.created_at ? rex_filter_text_audit.created_at : "",
                req_date_format_start:  rex_filter_text_audit.date_format_start ? rex_filter_text_audit.date_format_start : "",
                req_date_format_end:    rex_filter_text_audit.date_format_end ? rex_filter_text_audit.date_format_end : "",
                req_orderby:            rex_filter_order_audit.ordenby ? rex_filter_order_audit.ordenby : "",
                req_typeorderby:        rex_filter_order_audit.typeorderby ? rex_filter_order_audit.typeorderby : "",
            }),
        }
    )
    .then( async res => res.json())
    .then(async data => {
        const data_audits = data.data
        await data_audits.map((aud, index) => {
            data_audits[index]["key"] = (15 * (rex_paginate_audit - 1)) + (index + 1)
        })
        dispatch({
            type: GET_DATA_AUDITS,
            payload: data_audits
        })
        dispatch({
            type: GET_DATA_AUDITS_CLONE,
            payload: data_audits
        })
        const get_audits_modulo = data.data_mod.map(aud => aud.auddescripcion)
        dispatch({
            type: GET_DATA_AUDITS_MODULE,
            payload: get_audits_modulo
        })

        dispatch({
            type: TOTAL_REGISTER_AUDIT,
            payload: data.data_total
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: LOADING_DATA_AUDITS,
        payload : false
    })

    return true
}

export const FilterAuditsReducer = (date, module, user) => async (dispatch, getState) => {
    const date_format_start = date.date_format_start ? date.date_format_start : ""
    const date_format_end = date.date_format_end ? date.date_format_end : ""
    const auddescripcion = module ? module : ""
    const usuusuario = user ? user : ""

    const { rex_filter_text_audit } = getState().audits
    let text_audit = { ...rex_filter_text_audit, auddescripcion: auddescripcion, usuusuario: usuusuario }

    if(date){
        text_audit = { ...text_audit, created_at: "", date_format_start: date_format_start, date_format_end: date_format_end }
    }

    dispatch({
        type: FILTER_TEXT_AUDIT,
        payload: text_audit
    })
    dispatch({
        type: PAGINATE_AUDIT,
        payload: 1
    })
}

export const FilterTextAuditsReducer = (text, type) => async (dispatch, getState) => {
    const { rex_filter_text_audit } = getState().audits
    let text_audit = {}
    if(type.length === 3){
        text_audit = { ...rex_filter_text_audit, [type[2]]: text }
    }else if(type.length === 2){
        text_audit = { ...rex_filter_text_audit, [type[1]]: text }
    }else{
        if(type[0] === "created_at"){
            text_audit = { ...rex_filter_text_audit, [type[0]]: text, date_format_start: "", date_format_end: "" }
        }else{
            text_audit = { ...rex_filter_text_audit, [type[0]]: text }
        }
    }

    dispatch({
        type: FILTER_TEXT_AUDIT,
        payload: text_audit
    })
    dispatch({
        type: PAGINATE_AUDIT,
        payload: 1
    })
}

export const FilterOrderAuditsReducer = (order, type) => async (dispatch, getState) => {
    const filtro_orden = { ordenby: order, typeorderby : type }
    dispatch({
        type: FILTER_ORDER_AUDIT,
        payload: filtro_orden
    })
    dispatch({
        type: PAGINATE_AUDIT,
        payload: 1
    })
}

export const PaginateAuditsReducer = (paginate) => async (dispatch, getState) => {
    dispatch({
        type: PAGINATE_AUDIT,
        payload: paginate
    })
}

export const ClearFilterOrderTextReducer = () => async (dispatch, getState) => {
    dispatch({
        type: FILTER_TEXT_AUDIT,
        payload: {}
    })
    dispatch({
        type: FILTER_ORDER_AUDIT,
        payload: { column: null, orden: null }
    })
    dispatch({
        type: PAGINATE_AUDIT,
        payload: 1
    })

    return true
}