import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LogOutReducer } from '../../Redux/Actions/Login/Login'
import { Button, Layout, Dropdown } from 'antd'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
    UserOutlined
} from '@ant-design/icons'
import '../../Styles/Components/Top/Top.css'
import { ValidateUser } from '../../Redux/Actions/Login/Top'
  
const Top = (props) => {

    const { Header } = Layout
    const dispatch = useDispatch()

    const {
        collapsed,
        setCollapsed,
        valToken
    } = props

    const items = [
        {key: 0, label: <div onClick={() => dispatch(LogOutReducer())}>Cerrar Sesión</div>},
    ]

    useEffect(() => {
        if(valToken == false){
            if(localStorage.getItem('token_val') == "true"){
                dispatch(ValidateUser(true))
            }else{
                dispatch(ValidateUser(false))
            }
        }
    }, [])

    return (
        <div>
            <Header
                style={{
                    height: '50px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        columnGap: '27px',
                        alignItems: 'center'
                    }}
                >
                    {
                        !collapsed
                            && <div className="Logo-Container"></div>
                    }
                    <Button
                        type="primary"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            marginLeft: collapsed ? '16px' : '',
                            fontSize: '10px',
                            height: '25px',
                            width : '40px'
                        }}
                    >
                        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                </div>
                <Dropdown
                    menu={{items}}
                    trigger={['click']}
                    className="Menu-Log-Out"
                >
                    <div>
                        <div className="Icon-User-Top">
                            <UserOutlined className="Icon-User-Item-Top" />
                        </div>
                        <DownOutlined className="Icon-Arrow-Top" />
                    </div>
                </Dropdown>
            </Header>
        </div>
    )
}

export default Top