import { 
    GET_DATA_HOMOLOGATIONS_KEY_WORDS,
    CREATE_HOMOLOGATION_KEY_WORD,
    UPDATE_HOMOLOGATION_KEY_WORD,
    LOADING_DATA_HOMOLOGATIONS_KEY_WORDS,
} from "../../../../Constans/Administracion/HomologationsKeyWords/HomologationsKeyWords"

const INIT_STATE = {
    rex_data_homologations_key_words     : [],
    rex_create_homlogation_key_word      : null,
    rex_update_homlogation_key_word      : {},
    rex_loading_homologations_key_words  : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_HOMOLOGATIONS_KEY_WORDS: {
            return {
                ...state,
                rex_data_homologations_key_words : action.payload
            }
        }
        case CREATE_HOMOLOGATION_KEY_WORD: {
            return {
                ...state,
                rex_create_homlogation_key_word: action.payload
            }
        }
        case UPDATE_HOMOLOGATION_KEY_WORD: {
            return {
                ...state,
                rex_update_homlogation_key_word: action.payload
            }
        }
        case LOADING_DATA_HOMOLOGATIONS_KEY_WORDS: {
            return {
                ...state,
                rex_loading_homologations_key_words : action.payload
            }
        }
        default:
            return state
    }
}