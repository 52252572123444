import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button, Pagination, Skeleton } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined
} from '@ant-design/icons'
import { 
    ObtenerMaestraClientesGrowReducer 
} from '../../../Redux/Actions/Administracion/MaestraGrow/MaestraGrow'
import moment from 'moment'

const MaestraClientesGrow = (props) => {

    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const [ currentDataSize, setCurrentDataSize ] = useState(null)
    const [ pageTable, setPageTable ] = useState(1)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterOutlined />
        ),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })
    
    const columns = [
        {
            title: 'Código organización',
            dataIndex: 'cod_organizacion',
            ...getColumnSearchProps('cod_organizacion','código organización'),
            sorter: (a, b) => a.cod_organizacion ? a.cod_organizacion.localeCompare(b.cod_organizacion) : false,
            showSorterTooltip: false,
            render: (cod_organizacion) => <div title={cod_organizacion}>{cod_organizacion}</div>,
            width: '120px',
        },
        {
            title: 'Organización ventas',
            dataIndex: 'organizacion_venta',
            ...getColumnSearchProps('organizacion_venta','organización ventas'),
            sorter: (a, b) => a.organizacion_venta ? a.organizacion_venta.localeCompare(b.organizacion_venta) :false,
            showSorterTooltip: false,
            render: (organizacion_venta) => <div title={organizacion_venta}>{organizacion_venta}</div>,
            width: '120px'
        },
        {
            title: 'Código división',
            dataIndex: 'codigo_division',
            ...getColumnSearchProps('codigo_division','código división'),
            sorter: (a, b) => a.codigo_division ? a.codigo_division.localeCompare(b.codigo_division) : false,
            showSorterTooltip: false,
            render: (codigo_division) => <div title={codigo_division}>{codigo_division}</div>,
            width: '120px'
        },
        {
            title: 'División',
            dataIndex: 'division',
            ...getColumnSearchProps('division', 'división'),
            sorter: (a, b) => a.division ? a.division.localeCompare(b.division) : false,
            showSorterTooltip: false,
            render: (division) => <div title={division}>{division}</div>,
            width: '120px'
        },
        {
            title: 'Código destinatario',
            dataIndex: 'codigo_destinatario',
            ...getColumnSearchProps('codigo_destinatario','código destinatario'),
            // sorter: (a, b) => b.codigo_destinatario?  parseFloat(a.codigo_destinatario) - parseFloat(b.codigo_destinatario) : false,
            sorter: (a, b) => b.codigo_destinatario?  a.codigo_destinatario.localeCompare(b.codigo_destinatario) : false,
            showSorterTooltip: false,
            render: (codigo_destinatario) => <div title={codigo_destinatario}>{codigo_destinatario}</div>,
            width: '130px'
        },
        {
            title: 'Destinatario',
            dataIndex: 'destinatario',
            ...getColumnSearchProps('destinatario', 'destinatario'),
            sorter: (a, b) => a.destinatario ? a.destinatario.localeCompare(b.destinatario) : false,
            showSorterTooltip: false,
            render: (destinatario) => <div title={destinatario}>{destinatario}</div>,
            width: '130px'
        },
        {
            title: 'Código solicitante',
            dataIndex: 'codigo_solicitante',
            ...getColumnSearchProps('codigo_solicitante','código solicitante'),
            sorter: (a, b) => a.codigo_solicitante ? a.codigo_solicitante.localeCompare(b.codigo_solicitante) : false,
            showSorterTooltip: false,
            render: (codigo_solicitante) => <div title={codigo_solicitante}>{codigo_solicitante}</div>,
            width: '130px'
        },
        {
            title: 'Solicitante',
            dataIndex: 'solicitante',
            ...getColumnSearchProps('solicitante', 'colicitante'),
            sorter: (a, b) => a.solicitante ? a.solicitante.localeCompare(b.solicitante) : false,
            showSorterTooltip: false,
            render: (solicitante) => <div title={solicitante}>{solicitante}</div>,
            width: '130px'
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            ...getColumnSearchProps('ruc', 'RUC'),
            sorter: (a, b) => a.ruc ? a.ruc.localeCompare(b.ruc) : false,
            showSorterTooltip: false,
            render: (ruc) => <div title={ruc}>{ruc}</div>,
            width: '130px'
        },
        {
            title: 'Cliente hml',
            dataIndex: 'cliente_hml',
            ...getColumnSearchProps('cliente_hml','cliente hml'),
            sorter: (a, b) => a.cliente_hml ? a.cliente_hml.localeCompare(b.cliente_hml) : false,
            showSorterTooltip: false,
            render: (cliente_hml) => <div title={cliente_hml}>{cliente_hml}</div>,
            width: '130px'
        },
        {
            title: 'Grupo cliente hml',
            dataIndex: 'grupo_cliente_hml',
            ...getColumnSearchProps('grupo_cliente_hml','grupo cliente hml'),
            sorter: (a, b) => a.grupo_cliente_hml ? a.grupo_cliente_hml.localeCompare(b.grupo_cliente_hml) : false,
            showSorterTooltip: false,
            render: (grupo_cliente_hml) => <div title={grupo_cliente_hml}>{grupo_cliente_hml}</div>,
            width: '130px'
        },
        {
            title: 'Sucursal hml',
            dataIndex: 'sucursal_hml',
            ...getColumnSearchProps('sucursal_hml','sucursal hml'),
            sorter: (a, b) => a.sucursal_hml ? a.sucursal_hml.localeCompare(b.sucursal_hml) : false,
            showSorterTooltip: false,
            render: (sucursal_hml) => <div title={sucursal_hml}>{sucursal_hml}</div>,
            width: '130px'
        },
        {
            title: 'Supervisor',
            dataIndex: 'supervisor',
            ...getColumnSearchProps('supervisor','supervisor'),
            sorter: (a, b) => a.supervisor ? a.supervisor.localeCompare(b.supervisor) : false,
            showSorterTooltip: false,
            render: (supervisor) => <div title={supervisor}>{supervisor}</div>,
            width: '130px'
        },
        {
            title: 'Territorio',
            dataIndex: 'territorio',
            ...getColumnSearchProps('territorio','territorio'),
            sorter: (a, b) => a.territorio ? a.territorio.localeCompare(b.territorio) : false,
            showSorterTooltip: false,
            render: (territorio) => <div title={territorio}>{territorio}</div>,
            width: '130px'
        },
        {
            title: 'Zona',
            dataIndex: 'zona',
            ...getColumnSearchProps('zona','zona'),
            sorter: (a, b) => a.zona ? a.zona.localeCompare(b.zona) : false,
            showSorterTooltip: false,
            render: (zona) => <div title={zona}>{zona}</div>,
            width: '130px'
        },
        {
            title: 'Canal atencion',
            dataIndex: 'canal_atencion',
            ...getColumnSearchProps('canal_atencion','canal atención'),
            sorter: (a, b) => a.canal_atencion ? a.canal_atencion.localeCompare(b.canal_atencion) : false,
            showSorterTooltip: false,
            render: (canal_atencion) => <div title={canal_atencion}>{canal_atencion}</div>,
            width: '130px'
        },
        {
            title: 'Segmento cliente',
            dataIndex: 'segmento_cliente',
            ...getColumnSearchProps('segmento_cliente','segmento cliente'),
            sorter: (a, b) => a.segmento_cliente ? a.segmento_cliente.localeCompare(b.segmento_cliente) : false,
            showSorterTooltip: false,
            render: (segmento_cliente) => <div title={segmento_cliente}>{segmento_cliente}</div>,
            width: '130px'
        },
        {
            title: 'Subsegmento',
            dataIndex: 'subsegmento',
            ...getColumnSearchProps('subsegmento','subsegmento'),
            sorter: (a, b) => a.subsegmento ? a.subsegmento.localeCompare(b.subsegmento) : false,
            showSorterTooltip: false,
            render: (subsegmento) => <div title={subsegmento}>{subsegmento}</div>,
            width: '130px'
        },
        {
            title: 'Tipo atencion',
            dataIndex: 'tipo_atencion',
            ...getColumnSearchProps('tipo_atencion','tipo atención'),
            sorter: (a, b) => a.tipo_atencion ? a.tipo_atencion.localeCompare(b.tipo_atencion) : false,
            showSorterTooltip: false,
            render: (tipo_atencion) => <div title={tipo_atencion}>{tipo_atencion}</div>,
            width: '130px'
        },
        {
            title: 'Segmento regional',
            dataIndex: 'segmento_regional',
            ...getColumnSearchProps('segmento_regional','segmento regional'),
            sorter: (a, b) => a.segmento_regional ? a.segmento_regional.localeCompare(b.segmento_regional) : false,
            showSorterTooltip: false,
            render: (segmento_regional) => <div title={segmento_regional}>{segmento_regional}</div>,
            width: '130px'
        },
        {
            title: 'Conexion',
            dataIndex: 'conexion',
            ...getColumnSearchProps('conexion','conexión'),
            sorter: (a, b) => a.conexion ? a.conexion.localeCompare(b.conexion) : false,
            showSorterTooltip: false,
            render: (conexion) => <div title={conexion}>{conexion}</div>,
            width: '130px'
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            ...getColumnSearchProps('estado','estado'),
            sorter: (a, b) => a.estado ? a.estado.localeCompare(b.estado) : false,
            showSorterTooltip: false,
            render: (estado) => <div title={estado}>{estado}</div>,
            width: '130px'
        },
        {
            title: 'Codigo pais',
            dataIndex: 'codigo_pais',
            ...getColumnSearchProps('codigo_pais','código país'),
            sorter: (a, b) => a.codigo_pais ? a.codigo_pais.localeCompare(b.codigo_pais) : false,
            showSorterTooltip: false,
            render: (codigo_pais) => <div title={codigo_pais}>{codigo_pais}</div>,
            width: '130px'
        },
        {
            title: 'Pais',
            dataIndex: 'pais',
            ...getColumnSearchProps('pais','país'),
            sorter: (a, b) => a.pais ? a.pais.localeCompare(b.pais) : false,
            showSorterTooltip: false,
            render: (pais) => <div title={pais}>{pais}</div>,
            width: '130px'
        },
        {
            title: 'Codigo departamento',
            dataIndex: 'codigo_departamento',
            ...getColumnSearchProps('codigo_departamento','código departamento'),
            sorter: (a, b) => a.codigo_departamento ? a.codigo_departamento.localeCompare(b.codigo_departamento) : false,
            showSorterTooltip: false,
            render: (codigo_departamento) => <div title={codigo_departamento}>{codigo_departamento}</div>,
            width: '130px'
        },
        {
            title: 'Departamento',
            dataIndex: 'departamento',
            ...getColumnSearchProps('departamento','departamento'),
            sorter: (a, b) => a.departamento ? a.departamento.localeCompare(b.departamento) : false,
            showSorterTooltip: false,
            render: (departamento) => <div title={departamento}>{departamento}</div>,
            width: '130px'
        },
        {
            title: 'Codigo provincia',
            dataIndex: 'codigo_provincia',
            ...getColumnSearchProps('codigo_provincia','código provincia'),
            sorter: (a, b) => a.codigo_provincia ? a.codigo_provincia.localeCompare(b.codigo_provincia) : false,
            showSorterTooltip: false,
            render: (codigo_provincia) => <div title={codigo_provincia}>{codigo_provincia}</div>,
            width: '130px'
        },
        {
            title: 'Provincia',
            dataIndex: 'provincia',
            ...getColumnSearchProps('provincia','provincia'),
            sorter: (a, b) => a.provincia ? a.provincia.localeCompare(b.provincia) : false,
            showSorterTooltip: false,
            render: (provincia) => <div title={provincia}>{provincia}</div>,
            width: '130px'
        },
        {
            title: 'Codigo distrito',
            dataIndex: 'codigo_distrito',
            ...getColumnSearchProps('codigo_distrito','código distrito'),
            sorter: (a, b) => a.codigo_distrito ? a.codigo_distrito.localeCompare(b.codigo_distrito) : false,
            showSorterTooltip: false,
            render: (codigo_distrito) => <div title={codigo_distrito}>{codigo_distrito}</div>,
            width: '130px'
        },
        {
            title: 'Distrito',
            dataIndex: 'distrito',
            ...getColumnSearchProps('distrito','distrito'),
            sorter: (a, b) => a.distrito ? a.distrito.localeCompare(b.distrito) : false,
            showSorterTooltip: false,
            render: (distrito) => <div title={distrito}>{distrito}</div>,
            width: '130px'
        },
        {
            title: 'Direccion',
            dataIndex: 'direccion',
            ...getColumnSearchProps('direccion','dirección'),
            sorter: (a, b) => a.direccion ? a.direccion.localeCompare(b.direccion) : false,
            showSorterTooltip: false,
            render: (direccion) => <div title={direccion}>{direccion}</div>,
            width: '130px'
        },
        {
            title: 'Zona venta',
            dataIndex: 'zona_venta',
            ...getColumnSearchProps('zona_venta','zona venta'),
            sorter: (a, b) => a.zona_venta ? a.zona_venta.localeCompare(b.zona_venta) : false,
            showSorterTooltip: false,
            render: (zona_venta) => <div title={zona_venta}>{zona_venta}</div>,
            width: '130px'
        },
        // {
        //     title: 'Fec. Actualización',
        //     dataIndex: 'created_at',
        //     ...getColumnSearchProps('created_at', 'fecha actualización'),
        //     sorter: (a, b) => {
        //         const creacionA = a.created_at || ''
        //         const creacionB = b.created_at || ''
        //         return creacionA.localeCompare(creacionB)
        //     },
        //     showSorterTooltip: false,
        //     render: (created_at) => <div title={created_at?created_at:''}>{created_at? moment(created_at).format('DD/MM/YYYY') : ''}</div>,
        //     width: '120px'
        // }
    ]

    const {
        rex_data_maestra_clientes_grow,
        rex_cargando_maestra_clientes_grow,
        rex_feha_actualizacion_maestra_clientes_grow
    } = useSelector(({maestraGrow}) => maestraGrow)
    
    const ObtenerClientesGrow = async () => {
        await dispatch(ObtenerMaestraClientesGrowReducer())
    }

    useEffect(() => {
        if(rex_data_maestra_clientes_grow.length == 0){
            ObtenerClientesGrow()
        }
    }, [])

    return (
        
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Title className="Title-Module">
                            Maestra de Clientes Grow {rex_feha_actualizacion_maestra_clientes_grow?.espfechaactualizacion
                                ? <span style={{fontSize: '12px'}}>{moment(rex_feha_actualizacion_maestra_clientes_grow.espfechaactualizacion).format('DD/MM/YYYY hh:mm:a')}</span>
                                : <span style={{fontSize: '12px'}}>(20/06/2023 17:20 PM)</span>
                            }
                        </Title>
                    }
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerClientesGrow()
                            }}
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        { rex_cargando_maestra_clientes_grow && rex_data_maestra_clientes_grow.length == 0
                        ? <Skeleton.Input active={true} size='default'/>
                        : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                            <Pagination
                                current={pageTable}
                                total={rex_data_maestra_clientes_grow.length}
                                pageSize={15}
                                onChange={(page, pageSize) => setPageTable(page)}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format( rex_data_maestra_clientes_grow.length)} registros</div>
                        </div>
                        }
                        
                    </div>
                    { rex_cargando_maestra_clientes_grow && rex_data_maestra_clientes_grow.length == 0
                    ? <div style={{marginTop:'10px'}}>
                            <Skeleton/>
                            <Skeleton/>
                    </div>
                    : <Table 
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 15,
                                current: pageTable
                            }}
                            scroll={{
                                x: '1500px',
                                y: '450px',
                            }}
                            // onChange={(pagination, filters, sorter, extra)=> {
                            //     setCurrentDataSize(extra.currentDataSource.length)
                            // }}
                            columns={columns}
                            dataSource={rex_data_maestra_clientes_grow} 
                            className="Table-Home"
                            size='small'
                        />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraClientesGrow