import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { 
    GetDataUnitsMeasuresReducer,
    DeleteUnitsMeasuresReducer
} from '../../../Redux/Actions/Administracion/UnitsMeasures/UnitsMeasures'
import { Typography, Table, Card, Button, Popconfirm, Input, Pagination, Skeleton } from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined,
    ArrowLeftOutlined,
    FilterOutlined,
} from '@ant-design/icons'

const UnitsMeasures = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pageTable, setPageTable] = useState(1)

    const {
        rex_data_units_measures,
        rex_loading_data_units_measures,
    } = useSelector(({unitsMeasures}) => unitsMeasures)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color:'white'}}/>),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    })

    const getDataUnitsMeasuresReducer = async () => {
        await dispatch(GetDataUnitsMeasuresReducer())
    }

    const deleteUnitsMeasuresReducer = async (umdid) => {
        const reponse = await dispatch(DeleteUnitsMeasuresReducer(umdid))
        if(reponse) getDataUnitsMeasuresReducer()
    }

    useEffect(() => {
        getDataUnitsMeasuresReducer()
    }, [])

    const columns = [
        {
            title: 'N°',
            dataIndex: 'index',
            sorter: (a, b) => a.index ? a.index - b.index : false,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.index}</div>,
            width: '20px',
            align:'center'
        },
        {
            title: 'Cod Uni',
            dataIndex: 'umdcod_un',
            ...getColumnSearchProps('umdcod_un', 'cod un'),
            sorter: (a, b) => a.umdcod_un? a.umdcod_un.localeCompare(b.umdcod_un) : false,
            showSorterTooltip: false,
            render: (umdcod_un) => <div title={umdcod_un}>{umdcod_un}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Uni Med',
            dataIndex: 'umdun_med',
            ...getColumnSearchProps('umdun_med', 'un med'),
            sorter: (a, b) => a.umdun_med? a.umdun_med.localeCompare(b.umdun_med) : false,
            showSorterTooltip: false,
            render: (umdun_med) => <div title={umdun_med}>{umdun_med}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <EditOutlined 
                    title='Editar'
                    onClick={() => navigate('/actualizar-unidades-de-medidas', { state: { umdid: record.umdid } })}
                    style={{fontSize:'15px', color:'blue', cursor:'pointer', margin:'0 2px'}}
                />
                <Popconfirm
                    title='Eliminar unidad de medida'
                    description='Está seguro de eliminar la unidad de medida?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => deleteUnitsMeasuresReducer(record.umdid)}
                >
                    <DeleteOutlined 
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>
            </div>,
            width: '50px',
            align:'center'
        }
    ]

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Unidades de Medidas</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div style={{display:'flex'}}>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> getDataUnitsMeasuresReducer()}
                            >
                                <ReloadOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Recargar
                            </Button>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> navigate('/crear-unidades-de-medidas')}    
                            >
                                <PlusOutlined className='Icon-Button-Extra-Option-Type-User'/>
                                Nuevo
                            </Button>
                        </div>
                        { rex_loading_data_units_measures && rex_data_units_measures.length == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_units_measures.length}
                                    pageSize={15}
                                    onChange={(page, pageSize) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_units_measures.length)} tipos de usuarios</div>
                            </div>
                        }
                        
                    </div>
                    { rex_loading_data_units_measures && rex_data_units_measures.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    :   <Table
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 15,
                                current: pageTable,
                            }}
                            columns={columns}
                            dataSource={rex_data_units_measures}
                            className="Table-Home Table-Tipos-Usuarios"
                            size='small'
                        />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default UnitsMeasures