import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from "react-router-dom"
import {
    ChangeModalUploadDataRestriction,
    ChangeUpdateUploadDataRestriction,
    GetUploadDataRestriction,
    UpdateUploadDataRestriction
} from '../../Redux/Actions/Administracion/Administrador/UploadDataRestriction'
import { 
    funPermisosObtenidosBoolean, 
    funPermisosObtenidosBooleanChildren 
} from '../../Functions/funPermiso'
import { Menu, Modal, Form, Input } from 'antd'
import {
    AppstoreOutlined,
    PieChartOutlined,
    HomeOutlined,
    UploadOutlined,
    OrderedListOutlined,
    DownloadOutlined,
    LockOutlined,
    SaveOutlined
} from '@ant-design/icons'
import '../../Styles/Components/Sidebar/Sidebar.css'

const Sidebar = (props) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    const {
        rex_data_user_permissions
      } = useSelector(({login}) => login)

    const {
        rex_data_upload_data_restriction,
        rex_data_update_upload_data_restriction,
        rex_open_modal_upload_data_restriction,
    } = useSelector(({uploadDataRestriction}) => uploadDataRestriction)

    const items = [
        {
            type: 'divider',
        },
        {
            key: '/status', label: 'Status', icon: <HomeOutlined />, slug : 'sidebar.status'
        },
        {
            key: '/upload-file', label: 'Upload Data', icon: <UploadOutlined />, slug : 'sidebar.uploadfile'
        },
        {
            key: '/homologados', label: 'SKU (Homologados)', icon: <PieChartOutlined />, slug : 'sidebar.skuhomologados'
        },
        {
            key: '/no-homologados', label: 'SKU (No Homologados)', icon: <PieChartOutlined />, slug : 'sidebar.skunohomologados'
        },
        {
            key: '/download-data', label: 'Download Data', icon: <DownloadOutlined />, slug : 'sidebar.downloadfile'
        },
        {
            key: '/maestra-productos-grow', label: 'Productos', icon: <OrderedListOutlined />, slug : 'sidebar.maestraproductos'
        },
        {
            key: '/maestra-clientes-grow', label: 'Clientes', icon: <OrderedListOutlined />, slug : 'sidebar.distribuidoras'
        },
        {
            key: '', 
            label: 'Administrador', 
            icon: null, 
            className: 'Menu-Item-Admin',
            children: [
                {
                    key: '/admin/usuarios', label: 'Usuarios', icon: <OrderedListOutlined />, slug : 'sidebar.usuarios'
                },
                {
                    key: '/admin/tipos-usuarios', label: 'Tipos de usuarios', icon: <OrderedListOutlined />, slug : 'sidebar.tiposusuarios'
                },
                {
                    key: '/admin/carga-archivos', label: 'Carga Archivos', icon: <OrderedListOutlined />, slug : 'sidebar.cargaarchivos'
                },
                {
                    key: '/audits', label: 'Auditorias', icon: <OrderedListOutlined />, slug : 'sidebar.auditorias'
                },
                {
                    key: '/control-distribuidoras', label: <span title='Control Distribuidoras'>Control Distribuidoras</span>, icon: <OrderedListOutlined />, slug : 'sidebar.controldistribuidoras'
                },
                {
                    key: '/upload-file-restriction', label: <span title='Upload Data Restriction'>Upload Data Restriction</span>, icon: <LockOutlined />, slug : 'sidebar.uploadfilerestriction'
                },
                {
                    key: '/manage-master-clientes', label: <span title='Administrar Master Clientes'>Administrar Master Clientes</span>, icon: <OrderedListOutlined />, slug : 'sidebar.managemasterclientes'
                },
                {
                    key: '/unidades-de-medidas', label: <span title='Unidades de Medidas'>Unidades de Medidas</span>, icon: <OrderedListOutlined />, slug : 'sidebar.managemasterclientes'
                },
                {
                    key: '/asignacion-de-productos-masivo', label: <span title='Asignacion de Producto Masivo'>Asignacion de Producto Masivo</span>, icon: <OrderedListOutlined />, slug : 'sidebar.managemasterclientes'
                },
            ],
        },
    ]

    const {
        collapsed,
        setCollapsed
    } = props

    const changeModalUploadDataRestriction = async (value) => {
        await dispatch(ChangeModalUploadDataRestriction(value))
    }

    const getUploadDataRestriction = () => {
        dispatch(GetUploadDataRestriction())
    }

    const updateUploadDataRestriction = async () => {
        const response = await dispatch(UpdateUploadDataRestriction())
        if(response) {
            changeModalUploadDataRestriction(false)
            if(pathname == "/upload-file") {
                getUploadDataRestriction()
            }
        }
    }

    const changeDataUpdateUploadDataRestriction = (value, type, name) => {
        dispatch(ChangeUpdateUploadDataRestriction(value, type, name))
    }

    return (
        <>
        <div>
            <div
                className='Sidebar-Menu'
                style={{
                    width: '160px',
                    position: 'relative',
                    zIndex: 1000,
                }}
            >
                <Menu
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    inlineCollapsed={collapsed}
                    items={
                        items.map(item => {
                            if(item.children){
                                return funPermisosObtenidosBooleanChildren(rex_data_user_permissions, item)
                            }
                            return { ...item }
                        }).filter((item) => funPermisosObtenidosBoolean(rex_data_user_permissions, item.children ? item.children : item.slug))
                    }
                    style={{height: '100vh', border: 0}}
                    onClick={async (e) => {
                        if(e.key == "/upload-file-restriction"){
                            await changeModalUploadDataRestriction(true)
                            await getUploadDataRestriction()
                        }else{
                            navigate(e.key)
                        }
                    }}
                >
                    <Link to="/homologados" key="hml">
                        <Menu.Item key="homologados" >
                            <AppstoreOutlined />
                            <span className="nav-text">Homologados</span>
                        </Menu.Item>
                    </Link>
                    <Link to="/no-homologados" key="no-hml">
                        <Menu.Item key="no-homologados" >
                            <AppstoreOutlined />
                            <span className="nav-text">No Homologados</span>
                        </Menu.Item>
                    </Link>
                </Menu>
            </div>
        </div>
        <Modal 
            className="uploadrestriccion"
            title="Upload Data Restriction" 
            open={rex_open_modal_upload_data_restriction} 
            closable={false}
            onOk={() => updateUploadDataRestriction()} 
            okButtonProps={{
                ghost: true,
                type: "primary",
                icon: <SaveOutlined />
            }}
            onCancel={() => changeModalUploadDataRestriction(false)}
            cancelButtonProps={{
                danger: true,
            }}
        >
            <div className="uploadrestriccion__text">Sección en donde se restringe los días de subida de archivos(upload data).</div>
            <Form
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                labelAlign="left"
            >
                <Form.Item 
                    className="uploadrestriccion__item" 
                    label="Sell Out (Cuota) día"
                    extra={`La subida de archivos estara habilitada desde el día 1 calendario hasta el dia ${rex_data_update_upload_data_restriction.sellout 
                        ? rex_data_update_upload_data_restriction.sellout.tcadiarestriccion 
                        : rex_data_upload_data_restriction.sellout 
                            ? rex_data_upload_data_restriction.sellout.tcadiarestriccion
                            : ""}
                    `}
                >
                    <Input 
                        onChange={(e) => changeDataUpdateUploadDataRestriction(e.target.value, "sellout", "Sell Out")}
                        value={
                            rex_data_update_upload_data_restriction.sellout 
                                ? rex_data_update_upload_data_restriction.sellout.tcadiarestriccion 
                                : rex_data_upload_data_restriction.sellout 
                                    ? rex_data_upload_data_restriction.sellout.tcadiarestriccion
                                    : null
                        } 
                    />
                </Form.Item>
            </Form>
        </Modal>
        </>
    )
}

export default Sidebar