import { 
    GET_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
    SELECTED_PRODUCT,
    LOADING_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
} from "../../../../Constans/Administracion/AssignmentProductMassive/AssignmentProductMassive"

const INIT_STATE = {
    rex_data_assignment_product_massive     : [],
    rex_selected_product                    : {},
    rex_loading_assignment_product_massive  : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_ASSIGNMENT_PRODUCT_MASSIVE: {
            return {
                ...state,
                rex_data_assignment_product_massive : action.payload
            }
        }
        case SELECTED_PRODUCT: {
            return {
                ...state,
                rex_selected_product : action.payload
            }
        }
        case LOADING_DATA_ASSIGNMENT_PRODUCT_MASSIVE: {
            return {
                ...state,
                rex_loading_assignment_product_massive : action.payload
            }
        }
        default:
            return state
    }
}