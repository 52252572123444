import {
    OBTENER_DATA_NO_HOMOLOGADOS,
    OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
    OBTENER_DATA_NO_HOMOLOGADOS_CALENDARY,
    GET_DATA_HML,
    GROUP_DATA_YEARS,
    GROUP_DATA_MONTHS,
    DATA_TOTAL_NO_HOMOLOGADOS,
    CARGANDO_DATA_NO_HOMOLOGADOS,
    GROUP_DATA_DAYS,
    TYPE_CALENDARY,
    CHECK_SELECT,
} from '../../../../Constans/Homologaciones/NoHomologados/AsignarNoHomologados'

const INIT_STATE = {
    rex_data_no_homologados             : [],
    rex_data_no_homologados_clone       : [],
    rex_data_no_homologados_calendary   : [],
    rex_get_data_hml                    : [],
    rex_group_data_years                : [],
    rex_group_data_months               : [],
    rex_total_no_homologados            : null,
    rex_cargando_data_no_homologados    : false,
    rex_group_data_days                 : [],
    re_type_calendary                   : "year",
    re_check_select                     : "year",
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_data_no_homologados : action.payload
            }
        }
        case CARGANDO_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_cargando_data_no_homologados : action.payload
            }
        }
        case DATA_TOTAL_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_total_no_homologados : action.payload
            }
        }
        case OBTENER_DATA_NO_HOMOLOGADOS_CLONE: {
            return {
                ...state,
                rex_data_no_homologados_clone : action.payload
            }
        }
        case OBTENER_DATA_NO_HOMOLOGADOS_CALENDARY: {
            return {
                ...state,
                rex_data_no_homologados_calendary : action.payload
            }
        }
        case GET_DATA_HML: {
            return {
                ...state,
                rex_get_data_hml : action.payload
            }
        }
        case GROUP_DATA_YEARS: {
            return {
                ...state,
                rex_group_data_years : action.payload
            }
        }
        case GROUP_DATA_MONTHS: {
            return {
                ...state,
                rex_group_data_months : action.payload
            }
        }
        case GROUP_DATA_DAYS: {
            return {
                ...state,
                rex_group_data_days : action.payload
            }
        }
        case TYPE_CALENDARY: {
            return {
                ...state,
                re_type_calendary : action.payload
            }
        }
        case CHECK_SELECT: {
            return {
                ...state,
                re_check_select : action.payload
            }
        }
        default:
            return state;
    }
}
