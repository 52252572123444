import config from '../../../../config'
import {
    OBTENER_DATA_NO_HOMOLOGADOS,
    OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
    GET_DATA_HML,
    GROUP_DATA_YEARS,
    GROUP_DATA_MONTHS,
    GROUP_DATA_DAYS,
    TYPE_CALENDARY,
    CHECK_SELECT,
} from '../../../../Constans/Homologaciones/NoHomologados/AsignarNoHomologados'
import { notification } from 'antd'
import { ObtenerInventarioReducer, ObtenerMaestraPreciosReducer, ObtenerSellInReducer, ObtenerSellOutReducer } from './ObtenerData'
import { ObtenerHomologadosReducer } from '../Homologados/Homologados'
import { ObtenerNoHomologadosReducer, SelectProductsNoHomologacionReducer } from './NoHomologados'
import { SELECT_PRODUCT_NO_HOMOLOGACION } from '../../../../Constans/Homologaciones/NoHomologados/NoHomologados'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}
   
export const GetUniqueNoHomologadoReducer = (id) => async ( dispatch, getState ) => {
    await fetch(config.api_url+'approvals/get-unique-non-approved-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id: parseInt(id)
            }),
        }
    )
    .then(async res => await res.json())
    .then(async data => {
        await dispatch(SelectProductsNoHomologacionReducer(data.data[0]))
    }).catch((error)=> {
        console.log(error)
    });

    return true
}

export const GetNoHomologadosReducer = (id, combo, ruta) => async ( dispatch, getState ) => {

    const find_select_product = getState().noHomologados.rex_select_product_no_homologacion
    
    if(Object.keys(find_select_product).length == 0){
        await dispatch(GetUniqueNoHomologadoReducer(id))
    }
    const select_product = getState().noHomologados.rex_select_product_no_homologacion
    const pk_id_venta = Object.keys(select_product).length > 0 ? select_product.pk_venta_so : id

    const req_combo = combo == 'combo' ? true : false
    const ruta_api = ruta == "asignar-homologados" ? "get-approved-product" : "get-non-approved-product"

    await fetch(config.api_url+'approvals/'+ruta_api,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_pk_venta_so: pk_id_venta,
                req_combo,
                req_select_product : select_product
            }),
        }
    )
    .then(res => res.json())
    .then(async data => {
        const indiceUnico = data.data
        await indiceUnico.map((d, index) => {
            
            let valueFormSku = {
                sku: {},
                unmin: null,
                codunmed: null,
                unmed: null,
                coef: null,
                unminuni: null,
                bonif: null,
            }

            indiceUnico[index]['key'] = index
            indiceUnico[index]['delete'] = false
            if(index == 0){
                indiceUnico[index]['checked'] = true
            }else{
                indiceUnico[index]['checked'] = false
            }
            indiceUnico[index]['valueForm'] = valueFormSku
            if(index == 0){
                indiceUnico[index]['valueForm']['unmin'] = d.valueForm.unmin
            }
            if(d.m_pro_grow){
                indiceUnico[index]['valueForm']['sku']['cod_producto']  = d.master_productos_grow.codigo_material
                indiceUnico[index]['valueForm']['sku']['nomb_producto'] = d.master_productos_grow.material_softys
                // indiceUnico[index]['valueForm']['sku']['cod_producto']  = d.codigo_producto
                // indiceUnico[index]['valueForm']['sku']['nomb_producto'] = d.descripcion_producto
                indiceUnico[index]['valueForm']['sku']['id_producto_homologado'] = d.m_pro_grow
                indiceUnico[index]['valueForm']['sku']['paquetexbulto'] = d.master_productos_grow ? d.master_productos_grow.paquetes_bulto : null
                indiceUnico[index]['valueForm']['unmin']                = d.unidad_minima
            }
            // indiceUnico[index]['valueForm']['sku']['cod_producto']  = d.codigo_producto
            // indiceUnico[index]['valueForm']['sku']['nomb_producto'] = d.descripcion_producto
        })

        let cont_indice = 0
        for await (const data of indiceUnico){
            const data_sellout = await dispatch(ObtenerSellOutReducer(data.pk_extractor_venta_so))
            indiceUnico[cont_indice]['sellout'] = data_sellout
            cont_indice = cont_indice + 1
        }

        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS,
            payload: indiceUnico
        })
        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
            payload: indiceUnico
        })

        dispatch({
            type: CHECK_SELECT,
            payload: indiceUnico.length > 0
                ?   [indiceUnico[0].key]
                :   []
        })

        if(data.data_hml){
            dispatch({
                type: GET_DATA_HML,
                payload: data.data_hml
            })
        }

        for await (const data of indiceUnico){
            const codigo_material_value = data.master_productos_grow ? data.master_productos_grow.codigo_material : null
            const id_producto_homologado_value = data.master_productos_grow ? data.master_productos_grow.id : null
            const master_productos_hml = {
                id_producto_homologado: data.master_productos_grow ? data.master_productos_grow.id : '', 
                nomb_producto: data.master_productos_grow ? data.master_productos_grow.material_softys : '',
                cod_producto : data.master_productos_grow ? data.master_productos_grow.codigo_material : '',
                paquetexbulto : data.master_productos_grow ? data.master_productos_grow.paquetes_bulto : '',
                id_producto_so_selecc: data.id,
                producto_so_select: data,
            }
            
            if(codigo_material_value){
                await dispatch(ObtenerMaestraPreciosReducer(data.master_productos_grow.codigo_material, data.key))
            }
            if(id_producto_homologado_value){
                await dispatch(ObtenerSellInReducer(master_productos_hml, data.key, data.master_productos_grow.id))
            }
        }

        const {
            rex_data_no_homologados_clone
        } = getState().asignarNoHomologados
        
        const clear_data_selected = rex_data_no_homologados_clone.map(data => {
            if(data.master_productos_grow){
                let producto_hml_seleccionado = data.producto_hml_seleccionado
                producto_hml_seleccionado.producto_so_select.producto_hml_seleccionado = null
                return {
                    ...data,
                    producto_hml_seleccionado: producto_hml_seleccionado
                }
            }else{
                return {
                    ...data
                }
            }
        })

        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS,
            payload: clear_data_selected
        })
        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
            payload: clear_data_selected
        })
        
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const AsignarNoHomologadosReducer = (combo, rangoFecha, location) => async ( dispatch, getState ) => {
    
    const { rex_data_no_homologados_clone } = getState().asignarNoHomologados
    let clearArray = []
    let filter_data_no_hml = []
    
    if(location == "asignar-homologados"){
        const { data_clear, data_filter } = await dispatch(DataAsignarHomlogadosReducer(rex_data_no_homologados_clone, combo))
        clearArray = data_clear
        filter_data_no_hml = data_filter
    }else{
        const { data_clear, data_filter } = await dispatch(DataAsignarNoHomlogadosReducer(rex_data_no_homologados_clone, combo))
        clearArray = data_clear
        filter_data_no_hml = data_filter
    }

    let respuesta = false

    if(clearArray.length > 0){
        await fetch(config.api_url+'approvals/update-non-approved-products',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
                body: JSON.stringify({
                    req_datos_homologados   : clearArray,
                    req_select_product_so   : filter_data_no_hml,
                    req_rango_fecha         : rangoFecha
                }),
            }
        )
        .then(res => res.json())
        .then(data => {
            if(data.response){
                notificacionServidor('success', data.message)
                respuesta = true
            }else{
                notificacionServidor('warning', data.message)
            }
            dispatch(ObtenerHomologadosReducer())
            dispatch(ObtenerNoHomologadosReducer(true))
            dispatch(ClearDataHomologadosNoHomologados())

        }).catch((error)=> {
            console.log(error)
        })
    }else{
        notificacionServidor('warning', "Por favor rellena los campos.")
    }

    return respuesta
}

export const DataAsignarNoHomlogadosReducer = (rex_data_no_homologados_clone, combo) => async (dispatch, getState) => {
    const comboVer = combo == "combo" ? true : false
    const filter_data_no_hml = rex_data_no_homologados_clone.filter(no_hml => {
        if(no_hml.masterclientes_grow){
            return {...no_hml}
        }else{
            return undefined
        }
    })

    const arraySend = rex_data_no_homologados_clone.map((data) => {
        const pk_venta_so_extract = rex_data_no_homologados_clone.find(vnso => vnso.id == data.id)
        const sku = Object.keys(data.valueForm.sku).length > 0
        const unmin = data.valueForm.unmin ? data.valueForm.unmin.toString() : "1"
        const codunmed = data.valueForm.codunmed
        const unmed = data.valueForm.unmed
        const coef = data.valueForm.coef ? data.valueForm.coef.toString() : "1"
        const unminuni = data.valueForm.unminuni ? data.valueForm.unminuni.toString() : "1"
        const bonif = data.valueForm.bonif || data.valueForm.bonif == false ? true : null
        return {
            req_id: data.id,
            req_id_copia: data.id,
            req_pk_extractor_venta_so: pk_venta_so_extract ? pk_venta_so_extract.pk_extractor_venta_so : '',
            req_id_producto_homologado: sku 
                ? data.valueForm.sku.id_producto_homologado 
                : data.master_productos_grow 
                    ? data.master_productos_grow.id : '',
            req_unidad_minima_homologado: unmin 
                ? unmin 
                : data.unidad_minima
                    ? data.unidad_minima : '1',
            req_combo: comboVer,
            req_cod_unidad_medida : data.cod_unidad_medida ? data.cod_unidad_medida : '',
            req_cod_unidad_medida_homologado: codunmed 
                ? data.valueForm.codunmed 
                : data.cod_unidad_medida_hml
                    ? data.cod_unidad_medida_hml : '',
            req_unidad_medida_homologado: unmed 
                ? data.valueForm.unmed 
                : data.unidad_medida_hml
                    ? data.unidad_medida_hml : '',
            req_coeficiente: coef 
                ? coef
                : data.coeficiente
                    ? data.coeficiente : '1',
            req_unidad_minima_unitario: unminuni 
                ? unminuni
                : data.unidad_minima_unitaria
                    ? data.unidad_minima_unitaria : "1",
            req_bonificado: bonif 
                ? data.valueForm.bonif 
                : data.bonificado
                    ? data.bonificado : '',
        }
    })

    const clearArray = arraySend.filter(as => {
        if(comboVer){
            if(as.req_id_producto_homologado && as.req_cod_unidad_medida_homologado && as.req_unidad_medida_homologado && as.req_coeficiente && as.req_bonificado !== ''){
                return as
            }else{
                return undefined
            }
        }else{
            if(as.req_id_producto_homologado){
            // if(as.req_id_producto_homologado && as.req_unidad_minima_homologado){
                return as
            }else{
                return undefined
            }
        }
    })

    return {
        data_clear: clearArray,
        data_filter: filter_data_no_hml,
    }
}

export const DataAsignarHomlogadosReducer = (rex_data_no_homologados_clone, combo) => async (dispatch, getState) => {
    const comboVer = combo == "combo" ? true : false
    const filter_data_no_hml = rex_data_no_homologados_clone.filter(no_hml => {
        if(no_hml.masterclientes_grow){
            return {...no_hml}
        }else{
            return undefined
        }
    })

    const arraySend = rex_data_no_homologados_clone.map((data) => {
        const pk_venta_so_extract = rex_data_no_homologados_clone.find(vnso => vnso.id == data.id)
        const sku = Object.keys(data.valueForm.sku).length > 0
        const codunmed = data.valueForm.codunmed
        const unmed = data.valueForm.unmed
        const bonif = data.valueForm.bonif || data.valueForm.bonif == false ? true : null
        return {
            req_id: data.id,
            req_id_copia: data.id,
            req_pk_extractor_venta_so: pk_venta_so_extract ? pk_venta_so_extract.pk_extractor_venta_so : '',
            req_id_producto_homologado: sku 
                ? data.valueForm.sku.id_producto_homologado 
                : data.master_productos_grow 
                    ? data.master_productos_grow.id : '',
            req_unidad_minima_homologado: data.valueForm.unmin 
                ? data.valueForm.unmin.toString() 
                : data.unidad_minima
                    ? data.unidad_minima : '1',
            req_combo: comboVer,
            req_cod_unidad_medida : data.cod_unidad_medida ? data.cod_unidad_medida : '',
            req_cod_unidad_medida_homologado: codunmed 
                ? data.valueForm.codunmed 
                : data.cod_unidad_medida_hml
                    ? data.cod_unidad_medida_hml : '',
            req_unidad_medida_homologado: unmed 
                ? data.valueForm.unmed 
                : data.unidad_medida_hml
                    ? data.unidad_medida_hml : '',
            req_coeficiente: data.valueForm.coef  
                ? data.valueForm.coef.toString()
                : data.coeficiente
                    ? data.coeficiente : '1',
            req_unidad_minima_unitario: data.valueForm.unminuni 
                ? data.valueForm.unminuni.toString()
                : data.unidad_minima_unitaria
                    ? data.unidad_minima_unitaria : "1",
            req_bonificado: bonif 
                ? data.valueForm.bonif 
                : data.bonificado || data.bonificado == false
                    ? data.bonificado : '',
        }
    })

    const clearArray = arraySend.filter(as => {
        if(comboVer){
            if(as.req_id_producto_homologado && as.req_cod_unidad_medida_homologado && as.req_unidad_medida_homologado && as.req_coeficiente && as.req_bonificado !== ''){
                return as
            }else{
                return undefined
            }
        }else{
            if(as.req_id_producto_homologado){
                return as
            }else{
                return undefined
            }
        }
    })

    return {
        data_clear: clearArray,
        data_filter: filter_data_no_hml,
    }
}

export const AsignarNoHomologadosCalendarioReducer = (filtrar, type) => async ( dispatch, getState ) => {
    let {
        rex_group_data_years,
        rex_group_data_months,
        rex_group_data_days,
        re_type_calendary,
        rex_data_no_homologados_clone
    } = getState().asignarNoHomologados

    let valYear = re_type_calendary == "year" ? true : false
    let valMonth = re_type_calendary == "month" ? true : false
    let valDay = re_type_calendary == "day" ? true : false

    let cont_indice = 0

    for await (const data of rex_data_no_homologados_clone){
        const data_filtrar = {
            "req_type_day" : valDay,
            "req_type_month" : valMonth,
            "req_type_year" : valYear,
            "req_pk_extractor_ventas_so" : data.pk_extractor_venta_so,
            "req_anio" : rex_group_data_years,
            "req_mes" : rex_group_data_months,
            "req_dia" : rex_group_data_days
        }

        if(filtrar){
            const data_sellout = await dispatch(ObtenerSellOutReducer(data.pk_extractor_venta_so, data_filtrar))
            rex_data_no_homologados_clone[cont_indice]['sellout'] = data_sellout

            const data_inv = await dispatch(ObtenerInventarioReducer(data.pk_extractor_venta_so, data_filtrar))
            rex_data_no_homologados_clone[cont_indice]['inventario'] = data_inv

            if(data.producto_hml_seleccionado){
                await dispatch(ObtenerSellInReducer(
                    data.producto_hml_seleccionado, cont_indice, data.producto_hml_seleccionado.id_producto_homologado, data_filtrar
                ))
                
                await dispatch(ObtenerMaestraPreciosReducer(
                    data.producto_hml_seleccionado.cod_producto, cont_indice, data_filtrar
                ))
            }
        }else{
            if(type == "sellout"){
                const data_sellout = await dispatch(ObtenerSellOutReducer(data.pk_extractor_venta_so, data_filtrar))
                rex_data_no_homologados_clone[cont_indice]['sellout'] = data_sellout
            }else if(type == "inv"){
                const data_inv = await dispatch(ObtenerInventarioReducer(data.pk_extractor_venta_so, data_filtrar))
                rex_data_no_homologados_clone[cont_indice]['inventario'] = data_inv
            }else if(type == "master"){
                if(data.producto_hml_seleccionado){
                    await dispatch(ObtenerMaestraPreciosReducer(
                        data.producto_hml_seleccionado.cod_producto, cont_indice, data_filtrar
                    ))
                }
            }else if(type == "sellin"){
                if(data.producto_hml_seleccionado){
                    await dispatch(ObtenerSellInReducer(
                        data.producto_hml_seleccionado, cont_indice, data.producto_hml_seleccionado.id_producto_homologado, data_filtrar
                    ))
                }
            }
        }

        cont_indice = cont_indice + 1
    }

    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS,
        payload: rex_data_no_homologados_clone
    })
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: rex_data_no_homologados_clone
    })
    
    return true
}

export const ChangeTypeCalendaryReducer = (type) => async ( dispatch, getState ) => {
    dispatch({
        type: TYPE_CALENDARY,
        payload: type
    })
}

export const AccumulateGroupYearsReducer = (yearSend) => async ( dispatch, getState ) => {
    let data_years = getState().asignarNoHomologados.rex_group_data_years
    const yearsFound = data_years.find(gy => gy == yearSend)
    
    dispatch({
        type: GROUP_DATA_YEARS,
        payload: yearsFound
                    ?   data_years.filter(gy => gy !== yearSend)
                    :   [...data_years, yearSend]
    })
}

export const AccumulateGroupMonthsReducer = (yearValue, monthSend) => async ( dispatch, getState ) => {
    let data_months = getState().asignarNoHomologados.rex_group_data_months
    const monthsFound = data_months.find(gd => gd.year == yearValue && gd.month == monthSend)
    const formObject = {year: yearValue, month: monthSend}

    dispatch({
        type: GROUP_DATA_MONTHS,
        payload: monthsFound
                    ?   data_months.filter(gd => gd.month !== monthSend)
                    :   [...data_months, formObject]
    })
}

export const AccumulateGroupDaysReducer = (yearValue, monthValue, daySend) => async ( dispatch, getState ) => {
    let data_days = getState().asignarNoHomologados.rex_group_data_days

    const daysFound = data_days.find(gd => gd.days == daySend)
    const formObject = {year: yearValue, month: monthValue, days: daySend}

    dispatch({
        type: GROUP_DATA_DAYS,
        payload: daysFound
                    ?   data_days.filter(gd => gd.days !== daySend)
                    :   [...data_days, formObject]
    })
}

export const LimpiarGroupDataDaysReducer = () => async ( dispatch, getState ) => {
    dispatch({
        type: GROUP_DATA_DAYS,
        payload: []
    })
}

export const LimpiarGroupDatasReducer = () => async ( dispatch, getState ) => {
    dispatch({
        type: GROUP_DATA_YEARS,
        payload: []
    })
    dispatch({
        type: GROUP_DATA_MONTHS,
        payload: []
    })
    dispatch({
        type: GROUP_DATA_DAYS,
        payload: []
    })
    dispatch({
        type: TYPE_CALENDARY,
        payload: "year"
    })
}

export const CheckSelectReducer = (estado, key) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, checked: estado}
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const SelectSkuReducer = (key, id_producto, producto_so, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    let data_product_selected = producto_so
    data_product_selected.producto_hml_seleccionado = null
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: {
                    ...get.valueForm,
                    sku: {   
                        id_producto_homologado: value.id, 
                        nomb_producto: value.material_softys,
                        cod_producto : value.codigo_material,
                        paquetexbulto : value.paquetes_bulto,
                        id_producto_so_selecc: id_producto,
                        producto_so_select: data_product_selected
                    }
                }}
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const InputUnidadMinimaReducer = (key, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        unmin: value
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const InputCodAndUnidadMedidaReducer = (key, value) => async ( dispatch, getState ) => {
    const dataSeparado = value.split("-")
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        codunmed: dataSeparado[0].trim(),
                        unmed: dataSeparado[1].trim(),
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const InputCodUnidadMedidaReducer = (key, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        codunmed: value
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const InputUnidadMedidaReducer = (key, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        unmed: value
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const InputCoeficienteReducer = (key, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        coef: value
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const InputUnidadMinimaUnitarioReducer = (key, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        unminuni: value
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const SelectBonificadoReducer = (key, value) => async ( dispatch, getState ) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = get_product_not.map(get => 
        get.key == key
            ?   {...get, valueForm: 
                    {
                        ...get.valueForm,
                        bonif: value
                    }
                }
            :   {...get}
    )
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const AddRowProductNoHomologadoFormReducer = () => async (dispatch, getState) => {
    // const add_row_product = getState().asignarNoHomologados.rex_data_no_homologados_clone
    // if(add_row_product.length > 0){
    //     const key_row_product = add_row_product[add_row_product.length - 1]
    //     dispatch({
    //         type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
    //         payload: [...add_row_product, {key: key_row_product.key + 1, delete: true, children: []}]
    //     })
    // }else{
    //     dispatch({
    //         type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
    //         payload: [{key: 0, delete: true, children: []}]
    //     })
    // }
}

export const DeleteRowProductNoHomologadoFormReducer = (key) => async (dispatch, getState) => {
    const add_row_product = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const filter = add_row_product.filter(arp => arp.key !== key)
    const arrayForm = filter.map((f, index) => {
        f.key = index
        return f
    })
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const AddChildrenRowProductNoHomologadoFormReducer = (keyValue, text, keyId, texto_medida) => async (dispatch, getState) => {
    const get_product_not = getState().asignarNoHomologados.rex_data_no_homologados_clone
    const arrayForm = []
    let contadorArray = 0
    for (let contador = 0; contador < get_product_not.length + 1; contador++) {
        if(contador == keyValue+1){
            arrayForm.push({
                key: contador, inventario: {}, cod_unidad_medida: text, codigo_distribuidor: '', codigo_producto: '', delete: true, descripcion_producto: '', desde: '', hasta: '', id: keyId, m_dt_id: '', pk_extractor_venta_so: '', pk_venta_so: '', precio_unitario: '', proid: null, ruc: '', s_mtd: '', s_ytd: '', unidad_medida: texto_medida, sellout: {}, checked: false, valueForm: {sku: {}, unmin: null, codunmed: null, unmed: null, coef: null, unminuni: null, bonif: null}
            })
        }else{
            arrayForm.push({...get_product_not[contadorArray], key: contador})
            contadorArray = contadorArray + 1
        }
    }

     dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: arrayForm
    })
}

export const RemoveChildrenRowProductNoHomologadoFormReducer = (keyValue, keyChildren) => async (dispatch, getState) => {
    // const { children } = get_product_not.find((gpt) => gpt.key == keyValue)
    // const arrayFilter = children.filter(ch => ch.key !== keyChildren)
    // const arrayToForm = get_product_not.map(gpt => 
    //     gpt.key == keyValue
    //         ?   {...gpt, children: arrayFilter}
    //         :   {...gpt}
    // )
    // dispatch({
    //     type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
    //     payload: arrayToForm
    // })
}

export const ClearDataHomologadosNoHomologados = () => async (dispatch, getState) => {
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS,
        payload: []
    })
    dispatch({
        type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
        payload: []
    })

    dispatch({
        type : SELECT_PRODUCT_NO_HOMOLOGACION,
        payload : []
    })
}
