import React, {useState, useEffect} from 'react'
import { Select, Button } from 'antd'
import { startOfMonth, endOfMonth, eachDayOfInterval, format, getMonth, getYear } from 'date-fns'
import { es } from 'date-fns/locale'
import { AccumulateGroupDaysReducer, AccumulateGroupMonthsReducer, AccumulateGroupYearsReducer, ChangeTypeCalendaryReducer, LimpiarGroupDataDaysReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import '../../../Styles/Components/Date/DateNoHomologados.css'

const DateNoHomologados = (props) => {
    const dispatch = props.dispatch
    const rex_group_data_years = props.rex_group_data_years
    const rex_group_data_months = props.rex_group_data_months
    const rex_group_data_days = props.rex_group_data_days
    const re_type_calendary = props.re_type_calendary
    const monthFechaActual = getMonth(new Date())
    const yearFechaActual = getYear(new Date())

    const [monthValue, setMonthValue] = useState(monthFechaActual)
    const [yearValue, setYearValue] = useState(yearFechaActual)
    const [numberDays, setNumberDays] = useState()
    const [numberDaysPrev, setNumberDaysPrev] = useState(null)
    const [numberDaysNext, setNumberDaysNext] = useState(null)

    useEffect(() => {
        const diaInicial = startOfMonth(new Date(yearValue, monthValue))
        const diaFinal = endOfMonth(new Date(yearValue, monthValue))
        const cantidadDias = eachDayOfInterval({start: diaInicial, end: diaFinal})
        setNumberDays(cantidadDias)

        const diaFinalMesAnterior = endOfMonth(new Date(yearValue, monthValue - 1))
        const diaStringA = format(diaFinalMesAnterior, 'iii', {locale: es})
        const formatMesA = parseInt(format(diaFinalMesAnterior, 'dd'))
        const diasMesAnterior = {
            "vie": [formatMesA-5, formatMesA-4, formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "jue": [formatMesA-4, formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "mié": [formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "mar": [formatMesA-2, formatMesA-1,  formatMesA],
            "lun": [formatMesA-1, formatMesA],
            "dom": [formatMesA],
        }
        setNumberDaysPrev(diasMesAnterior[diaStringA])

        const diaInicialMesSig = startOfMonth(new Date(yearValue, monthValue + 1))
        const diaStringS = format(diaInicialMesSig, 'iii', {locale: es})
        const formatMesS = parseInt(format(diaInicialMesSig, 'dd'))
        const diasMesSig = {
            "lun": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3), "0"+(formatMesS+4), "0"+(formatMesS+5)],
            "mar": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3), "0"+(formatMesS+4)],
            "mié": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3)],
            "jue": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2)],
            "vie": ["0"+(formatMesS), "0"+(formatMesS+1)],
            "sáb": ["0"+(formatMesS)],
        }
        setNumberDaysNext(diasMesSig[diaStringS])
    }, [])

    const yearSelect = [
        {value: 2021, label: 2021},
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
        {value: 2025, label: 2025},
        {value: 2026, label: 2026},
    ]
    const year = [
        {value: 2019, label: 2019},
        {value: 2020, label: 2020},
        {value: 2021, label: 2021},
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
        {value: 2025, label: 2025},
        {value: 2026, label: 2026},
        {value: 2027, label: 2027},
        {value: 2028, label: 2028},
        {value: 2029, label: 2029},
        {value: 2030, label: 2030},
    ]
    const month = [
        {value: 0, label: 'Ene'},
        {value: 1, label: 'Feb'},
        {value: 2, label: 'Mar'},
        {value: 3, label: 'Abr'},
        {value: 4, label: 'May'},
        {value: 5, label: 'Jun'},
        {value: 6, label: 'Jul'},
        {value: 7, label: 'Ago'},
        {value: 8, label: 'Sep'},
        {value: 9, label: 'Oct'},
        {value: 10, label: 'Nov'},
        {value: 11, label: 'Dic'},
    ]
    const dayString = [
        'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'
    ]

    const changeOfDaysYear = async (yearChange) => {
        await dispatch(LimpiarGroupDataDaysReducer())
        setYearValue(yearChange)
        const diaInicial = startOfMonth(new Date(yearChange, monthValue))
        const diaFinal = endOfMonth(new Date(yearChange, monthValue))
        const cantidadDias = eachDayOfInterval({start: diaInicial, end: diaFinal})
        setNumberDays(cantidadDias)

        const diaFinalMesAnterior = endOfMonth(new Date(yearChange, monthValue - 1))
        const diaStringA = format(diaFinalMesAnterior, 'iii', {locale: es})
        const formatMesA = parseInt(format(diaFinalMesAnterior, 'dd'))
        const diasMesAnterior = {
            "vie": [formatMesA-5, formatMesA-4, formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "jue": [formatMesA-4, formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "mié": [formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "mar": [formatMesA-2, formatMesA-1,  formatMesA],
            "lun": [formatMesA-1, formatMesA],
            "dom": [formatMesA],
        }
        setNumberDaysPrev(diasMesAnterior[diaStringA])

        const diaInicialMesSig = startOfMonth(new Date(yearChange, monthValue + 1))
        const diaStringS = format(diaInicialMesSig, 'iii', {locale: es})
        const formatMesS = parseInt(format(diaInicialMesSig, 'dd'))
        const diasMesSig = {
            "lun": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3), "0"+(formatMesS+4), "0"+(formatMesS+5)],
            "mar": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3), "0"+(formatMesS+4)],
            "mié": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3)],
            "jue": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2)],
            "vie": ["0"+(formatMesS), "0"+(formatMesS+1)],
            "sáb": ["0"+(formatMesS)],
        }
        setNumberDaysNext(diasMesSig[diaStringS])
    }

    const changeOfDaysMonth = async (monthChange) => {
        await dispatch(LimpiarGroupDataDaysReducer())
        setMonthValue(monthChange)
        const diaInicial = startOfMonth(new Date(yearValue, monthChange))
        const diaFinal = endOfMonth(new Date(yearValue, monthChange))
        const cantidadDias = eachDayOfInterval({start: diaInicial, end: diaFinal})
        setNumberDays(cantidadDias)

        const diaFinalMesAnterior = endOfMonth(new Date(yearValue, parseInt(monthChange) - 1))
        const diaStringA = format(diaFinalMesAnterior, 'iii', {locale: es})
        const formatMesA = parseInt(format(diaFinalMesAnterior, 'dd'))
        const diasMesAnterior = {
            "vie": [formatMesA-5, formatMesA-4, formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "jue": [formatMesA-4, formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "mié": [formatMesA-3, formatMesA-2, formatMesA-1,  formatMesA],
            "mar": [formatMesA-2, formatMesA-1,  formatMesA],
            "lun": [formatMesA-1, formatMesA],
            "dom": [formatMesA],
        }
        setNumberDaysPrev(diasMesAnterior[diaStringA])

        const diaInicialMesSig = startOfMonth(new Date(yearValue, parseInt(monthChange) + 1))
        console.log(diaInicialMesSig);
        const diaStringS = format(diaInicialMesSig, 'iii', {locale: es})
        const formatMesS = parseInt(format(diaInicialMesSig, 'dd'))
        const diasMesSig = {
            "lun": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3), "0"+(formatMesS+4), "0"+(formatMesS+5)],
            "mar": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3), "0"+(formatMesS+4)],
            "mié": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2), "0"+(formatMesS+3)],
            "jue": ["0"+(formatMesS), "0"+(formatMesS+1), "0"+(formatMesS+2)],
            "vie": ["0"+(formatMesS), "0"+(formatMesS+1)],
            "sáb": ["0"+(formatMesS)],
        }
        setNumberDaysNext(diasMesSig[diaStringS])
    }

    return(
        <div className="Date">
            <div className="Date-Header">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Select 
                        style={{
                            width: '70px'
                        }}
                        size='small'
                        defaultValue={yearValue}
                        options={yearSelect}
                        onChange={(e) => changeOfDaysYear(e)}
                    />
                    <Select 
                        style={{
                            width: '70px'
                        }}
                        size='small'
                        defaultValue={monthValue}
                        options={month}
                        onChange={(e) => changeOfDaysMonth(e)}
                    />
                </div>
                <div>
                    <Button 
                        size='small' 
                        onClick={() => dispatch(ChangeTypeCalendaryReducer("year"))}
                        className={`${re_type_calendary == "year" ? 'Button-Header-Active' : ''}`}
                    >
                        Año
                    </Button>
                    <Button 
                        size='small' 
                        onClick={() => dispatch(ChangeTypeCalendaryReducer("month"))}
                        className={`${re_type_calendary == "month" ? 'Button-Header-Active' : ''}`}
                    >
                        Mes
                    </Button>
                    <Button 
                        size='small' 
                        onClick={() => dispatch(ChangeTypeCalendaryReducer("day"))}
                        className={`${re_type_calendary == "day" ? 'Button-Header-Active' : ''}`}
                    >
                        Día
                    </Button>
                </div>
            </div>
            {   
                re_type_calendary == "year" &&  
                <div className="Date-Body">
                    {
                        year.map(m => {
                            let activeButton = rex_group_data_years.find(gy => gy == m.value)
                            return (
                                <div className="Container-Button-Date" key={m.value}>
                                    <Button 
                                        className={`Button-Date ${activeButton ? 'active' : ''}`} 
                                        size='small'
                                        onClick={() => dispatch(AccumulateGroupYearsReducer(m.value))}
                                    >
                                        {m.label}
                                    </Button>
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                re_type_calendary == "month" &&  
                <div className="Date-Body">
                    {
                        month.map(m => {
                            let activeButton = rex_group_data_months.find(gm => gm.year == yearValue && gm.month == m.value)
                            return (
                                <div className="Container-Button-Date" key={m.value}>
                                    <Button 
                                        className={`Button-Date ${activeButton ? 'active' : ''}`} 
                                        size='small'
                                        onClick={() => dispatch(AccumulateGroupMonthsReducer(yearValue, m.value))}
                                    >
                                        {m.label}
                                    </Button>
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                re_type_calendary == "day" &&
                <div className="Date-Body-Day">
                    {
                        dayString.map((days, index) => 
                            <div className="Container-Button-Date-Day" key={index}>
                                {days}
                            </div>
                        )
                    }
                    {
                        numberDaysPrev &&
                        numberDaysPrev.map(e => 
                            <div className="Container-Button-Date-Day" style={{opacity: '.5'}} key={e}>{e}</div>
                        )
                    }
                    {
                        numberDays.map((d, index) => {
                            let dayFormat = format(d, 'dd')
                            let activeButton = rex_group_data_days.find(gd => gd.days == dayFormat)
                            return (
                                <div className="Container-Button-Date-Day" key={index}>
                                    <Button 
                                        className={`Button-Date-Day ${activeButton ? 'active' : ''}`} 
                                        size='small'
                                        onClick={() => dispatch(AccumulateGroupDaysReducer(yearValue, monthValue, dayFormat))}
                                    >
                                        {dayFormat}
                                    </Button>
                                </div>
                            )
                        })
                    }
                    {
                        numberDaysNext &&
                        numberDaysNext.map(e => 
                            <div className="Container-Button-Date-Day" style={{opacity: '.5'}} key={e}>{e}</div>
                        )
                    }
                </div>
            }
        </div>
    )
}

export default DateNoHomologados