import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Login from './Login/Login'
import Homologados from './Homologaciones/Homologados/Homologados'
import NoHomologados from './Homologaciones/NoHomologados/NoHomologados'
import AsignarNoHomologados from './Homologaciones/NoHomologados/AsignarNoHomologados'
import DownloadData from './DownloadData/DownloadData'
import MaestraProductos from './Administracion/MaestraProductos/MaestraProductos'
import MaestraGrow from './Administracion/MaestraGrow/MaestraGrow'
import TiposUsuarios from './Administracion/Administrador/TiposUsuarios'
import Permisos from './Administracion/Administrador/Permisos'
import MaestraSoftys from './Administracion/MaestraSoftys/MaestraSoftys'
import Distribuidoras from './Administracion/Distribuidoras/Distribuidoras'
import ListaPrecios from './Administracion/ListaPrecios/ListaPrecios'
import UploadFile from './UploadFile/UploadFile'
import Status from './Status/Status'
import Usuarios from './Administracion/Administrador/Usuarios'
import CargaArchivos from './Administracion/Administrador/CargaArchivos'
import Audits from './Audits/Audits'
import DateData from './DateData/DateData'
import UploadDataRestriction from './Administracion/Administrador/UploadDataRestriction'
import ManageMasterClientes from './Administracion/Administrador/ManageMasterClientes'
import AssignmentProductMassive from './Administracion/AssignmentProductMassive/AssignmentProductMassive'
import HomologationsKeyWords from './Administracion/HomologationsKeyWords/HomologationsKeyWords'
import UnitsMeasures from './Administracion/UnitsMeasures/UnitsMeasures'

const createRootReducer = (history) => combineReducers({
    router : connectRouter(history),
    login                       : Login,
    homologados                 : Homologados,
    noHomologados               : NoHomologados,
    downloadData                : DownloadData,
    maestraProductos            : MaestraProductos,
    tiposUsuarios               : TiposUsuarios,
    permisos                    : Permisos,
    usuarios                    : Usuarios,
    maestraGrow                 : MaestraGrow,
    maestraSoftys               : MaestraSoftys,
    distribuidoras              : Distribuidoras,
    listaPrecios                : ListaPrecios, 
    asignarNoHomologados        : AsignarNoHomologados,
    uploadFile                  : UploadFile,
    status                      : Status,
    cargaArchivos               : CargaArchivos,
    audits                      : Audits,
    dateData                    : DateData,
    uploadDataRestriction       : UploadDataRestriction,
    manageMasterClientes        : ManageMasterClientes,
    assignmentProductMassive    : AssignmentProductMassive,
    homologationsKeyWords       : HomologationsKeyWords,
    unitsMeasures               : UnitsMeasures,
})

export default createRootReducer
