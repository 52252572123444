import {
    GET_DATA_ZONA_MASTER_CLIENTES,
    GET_DATA_CANAL_ATENCION_MASTER_CLIENTES,
    GET_DATA_SEGMENTO_REGIONAL_MASTER_CLIENTES,
    LOADING_ZONA,
    LOADING_CANAL_ATENCION,
    LOADING_SEGMENTO_REGIONAL,
} from "../../../../Constans/Administracion/Administrador/ManageMasterClientes"

const INIT_STATE = {
    rex_data_zona_master_clientes              : [],
    rex_data_canal_atencion_master_clientes    : [],
    rex_data_segmento_regional_master_clientes : [],
    rex_loading_zona                           : false,
    rex_loading_canal_atencion                 : false,
    rex_loading_segmento_regional              : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_ZONA_MASTER_CLIENTES: {
            return {
                ...state,
                rex_data_zona_master_clientes : action.payload
            }
        }
        case GET_DATA_CANAL_ATENCION_MASTER_CLIENTES: {
            return {
                ...state,
                rex_data_canal_atencion_master_clientes : action.payload
            }
        }
        case GET_DATA_SEGMENTO_REGIONAL_MASTER_CLIENTES: {
            return {
                ...state,
                rex_data_segmento_regional_master_clientes : action.payload
            }
        }
        case LOADING_ZONA: {
            return {
                ...state,
                rex_loading_zona : action.payload
            }
        }
        case LOADING_CANAL_ATENCION: {
            return {
                ...state,
                rex_loading_canal_atencion : action.payload
            }
        }
        case LOADING_SEGMENTO_REGIONAL: {
            return {
                ...state,
                rex_loading_segmento_regional : action.payload
            }
        }
        default:
            return state
    }
}