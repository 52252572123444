import config from '../../../../config'
import {
    GET_DATA_HOMOLOGATIONS_KEY_WORDS,
    CREATE_HOMOLOGATION_KEY_WORD,
    UPDATE_HOMOLOGATION_KEY_WORD,
    LOADING_DATA_HOMOLOGATIONS_KEY_WORDS,
} from '../../../../Constans/Administracion/HomologationsKeyWords/HomologationsKeyWords'
import { notification } from 'antd'

const STATUS_ERR = 400
const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataHomologationsKeyWordsReducer = () => async (dispatch, getState) => {
    dispatch({
        type: LOADING_DATA_HOMOLOGATIONS_KEY_WORDS,
        payload: true
    })

    await fetch(config.api_url+'administration/get-homologations-key-words',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({})
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_keywords = data.data
            data_keywords.map((words, index) => {
                data_keywords[index]['key'] = index + 1
                data_keywords[index]['editable'] = false
            })
            dispatch({
                type: GET_DATA_HOMOLOGATIONS_KEY_WORDS,
                payload: data_keywords
            })
        }else{
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: LOADING_DATA_HOMOLOGATIONS_KEY_WORDS,
        payload: false
    })
}

export const CreateHomologationsKeyWordsReducer = () => async (dispatch, getState) => {
    let response = true
    const { rex_create_homlogation_key_word } = getState().homologationsKeyWords

    if(rex_create_homlogation_key_word){
        await fetch(config.api_url+'administration/create-homologations-key-words',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
                body: JSON.stringify({
                    req_palabra: rex_create_homlogation_key_word,
                })
            }
        )
        .then(res => res.json())
        .then(data => {
            if(data.response){
                notificacionServidor('success', data.message)
                dispatch(CreateValueHomologationKeyWordReducer(null))
            }else{
                if(data.status == STATUS_ERR){
                    notificacionServidor('warning', data.message)
                }else{
                    notificacionServidor('error', data.message)
                }
                response = false
            }
        }).catch((error)=> {
            console.log(error)
            response = false
        })
    }else{
        notificacionServidor('warning', 'El campo no debe estar vacio.')
        response = false
    }

    return response
}

export const UpdateHomologationsKeyWordsReducer = () => async (dispatch, getState) => {
    let response = true
    const { rex_update_homlogation_key_word } = getState().homologationsKeyWords

    if(rex_update_homlogation_key_word.palabra){
        await fetch(config.api_url+'administration/update-homologations-key-words',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
                body: JSON.stringify({
                    req_id: rex_update_homlogation_key_word.id,
                    req_palabra: rex_update_homlogation_key_word.palabra,
                })
            }
        )
        .then(res => res.json())
        .then(data => {
            if(data.response){
                notificacionServidor('success', data.message)
                dispatch(UpdateValueHomologationKeyWordReducer({}))
            }else{
                if(data.status == STATUS_ERR){
                    notificacionServidor('warning', data.message)
                }else{
                    notificacionServidor('error', data.message)
                }
                response = false
            }
        }).catch((error)=> {
            console.log(error)
            response = false
        })
    }else{
        notificacionServidor('warning', 'El campo a editar no debe estar vacio.')
        response = false
    }

    return response
}

export const DeleteHomologationsKeyWordsReducer = (id_key_words) => async (dispatch, getState) => {
    let response = true
    await fetch(config.api_url+'administration/delete-homologations-key-words',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id: id_key_words,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
            dispatch(GetDataHomologationsKeyWordsReducer())
        }else{
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
            response = false
        }
    }).catch((error)=> {
        console.log(error)
        response = false
    })

    return response
}

export const CreateValueHomologationKeyWordReducer = (values) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_HOMOLOGATION_KEY_WORD,
        payload: values
    })
}

export const ActiveUpdateHomologarionKeyWordReducer = (id, palabra) => async (dispatch, getState) => {
    const { rex_data_homologations_key_words } = getState().homologationsKeyWords
    const data_keywords = rex_data_homologations_key_words.map(kw => {
        if(kw.pchid === id){
            return { ...kw, editable: true }
        }

        return { ...kw, editable: false }
    })
    dispatch({
        type: GET_DATA_HOMOLOGATIONS_KEY_WORDS,
        payload: data_keywords
    })

    dispatch(UpdateValueHomologationKeyWordReducer({id: id, palabra: palabra}))
}

export const UpdateValueHomologationKeyWordReducer = (values) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_HOMOLOGATION_KEY_WORD,
        payload: values
    })
}

export const ClearHomologarionKeyWordReducer = () => async (dispatch, getState) => {
    const { rex_data_homologations_key_words } = getState().homologationsKeyWords
    const data_keywords = rex_data_homologations_key_words.map(kw => ({ ...kw, editable: false }))
    dispatch({
        type: GET_DATA_HOMOLOGATIONS_KEY_WORDS,
        payload: data_keywords
    })

    dispatch(UpdateValueHomologationKeyWordReducer({}))
}