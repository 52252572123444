import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { ActivityLogIn, LoginReducer } from '../../Redux/Actions/Login/Login'
import { Row, Col, Typography, Form, Input, Button } from 'antd'
import {
    UserOutlined,
    LockOutlined
} from '@ant-design/icons'
import '../../Styles/Routes/Login/Login.css'
import ImgThanos from '../../Assets/Img/Login/thanso.png'
import ImgLeon from '../../Assets/Img/Login/leon.png'
import Reloj from '../../Assets/Img/Login/reloj.gif'
import Expired from '../../Assets/Img/Login/expired.gif'

const Login = () => {
    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
		rex_session_inactivity
    } = useSelector(({login}) => login)

    const onFinish = async (data) => {
        await dispatch(LoginReducer(data.correo, 1, data.password)) && navigate('/homologados')
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100vh'
            }}
        >
        {
            rex_session_inactivity
            ?<Row style={{height: '100%', backgroundColor: '#24235F'}}>
                <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={Expired} style={{width: '400px'}} />
                </Col>
                <Col span={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'}}>
                    <p style={{fontFamily: 'Inter', fontSize: '40px', color: '#fff', marginTop: '8px'}}>¡Lo sentimos tu sesión ha expirado!</p>
                    <Button type="primary" size='large' style={{width: '200px'}} onClick={() => dispatch(ActivityLogIn())}>Inicia Session</Button>
                </Col>
            </Row>
            :null
            // <Row style={{height: '100%'}}>
            //     <Col 
            //         span={12} 
            //         className="Col-Banner-Login"
            //     >
            //         <div className="Icon-Banner-Login">
            //             {/* <img src={ImgLeon} /> */}
            //         </div>
            //         <Title
            //             className="Title-Banner-Login"
            //         >
            //             Y tú ¿Cuánto <br/>vendistes ayer?
            //         </Title>
            //         <p 
            //             className="Text-Banner-Login"
            //         >
            //             Analiza lo mejor. Todo es realidad con nosotros
            //         </p>
            //     </Col>
            //     <Col 
            //         span={12} 
            //         className="Col-Form-Login"
            //     >
            //         <div className="Icon-Form-Login">
            //             <img src={ImgThanos} />
            //         </div>
            //         <div
            //             className="Container-Title-Login"
            //         >
            //             <Title 
            //                 className="Title-Form-Login"
            //                 level={2}
            //             >
            //                 Inicia Sesión
            //             </Title>
            //             <div className="Icon-Title-Login">
            //                 <img src={ImgLeon} />
            //             </div>
            //         </div>
            //         <Form
            //             onFinish={onFinish}
            //             className="Form-Container-Item-Login"
            //             layout="vertical"
            //             autoComplete="off"
            //         >
            //             <Form.Item
            //                 name="correo"
            //                 label="Usuario"
            //                 className="Item-Form-Login"
            //             >
            //                 <Input 
            //                     className="Input-Form-Login"
            //                     prefix={<UserOutlined />}
            //                     placeholder="Escribe tu usuario" 
            //                 />
            //             </Form.Item>
            //             <Form.Item
            //                 name="password"
            //                 label="Contraseña"
            //                 className="Item-Form-Login"
            //             >
            //                 <Input.Password 
            //                     className="Input-Form-Login"
            //                     prefix={<LockOutlined />}
            //                     placeholder="Escribe tu contraseña" 
            //                 />
            //             </Form.Item>
            //             <Form.Item>
            //                 <Button
            //                     className="Button-Form-Login"
            //                     htmlType="submit"
            //                 >
            //                     Ingresar
            //                 </Button>
            //             </Form.Item>
            //         </Form>
            //     </Col>
            // </Row>
        }
        </div>
    )
}

export default Login