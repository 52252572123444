import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button, Pagination, Skeleton } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined
} from '@ant-design/icons'
import { 
    ObtenerMaestraProductosGrowReducer
} from '../../../Redux/Actions/Administracion/MaestraGrow/MaestraGrow'
import moment from 'moment'

const MaestraProductosGrow = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const [ pageTable, setPageTable ] = useState(1)
    const [ currentDataSize, setCurrentDataSize ] = useState(null)
    
    const getColumnSearchProps = (dataIndex, text) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${text}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterOutlined />
        ),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })

    const columns = [
        {
            title: 'Código organización',
            dataIndex: 'cod_organizacion',
            ...getColumnSearchProps('cod_organizacion', 'Código Organización'),
            sorter: (a, b) => a.cod_organizacion ? a.cod_organizacion.localeCompare(b.cod_organizacion) : null,
            showSorterTooltip: false,
            render: (cod_organizacion) => <div title={cod_organizacion}>{cod_organizacion}</div>,
            width: '120px'
        },
        {
            title: 'Organización ventas',
            dataIndex: 'organizacion_venta',
            ...getColumnSearchProps('organizacion_venta', 'Organización ventas'),
            sorter: (a, b) => a.organizacion_venta ? a.organizacion_venta.localeCompare(b.organizacion_venta) : false,
            showSorterTooltip: false,
            render: (organizacion_venta) => <div title={organizacion_venta}>{organizacion_venta}</div>,
            width: '120px'
        },
        {
            title: 'Código división',
            dataIndex: 'codigo_division',
            ...getColumnSearchProps('codigo_division', 'Código división'),
            sorter: (a, b) => a.codigo_division ? a.codigo_division.localeCompare(b.codigo_division) : false,
            showSorterTooltip: false,
            render: (codigo_division) => <div title={codigo_division}>{codigo_division}</div>,
            width: '120px'
        },
        {
            title: 'División',
            dataIndex: 'division',
            ...getColumnSearchProps('division', 'División'),
            sorter: (a, b) => a.division ? a.division.localeCompare(b.division) : false,
            showSorterTooltip: false,
            render: (division) => <div title={division}>{division}</div>,
            width: '120px'
        },
        {
            title: 'Código material',
            dataIndex: 'codigo_material',
            ...getColumnSearchProps('codigo_material', 'Código material'),
            sorter: (a, b) => a.codigo_material ? a.codigo_material.localeCompare(b.codigo_material) : false,
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material}>{codigo_material}</div>,
            width: '130px'
        },
        {
            title: 'Código material Softys',
            dataIndex: 'material_softys',
            ...getColumnSearchProps('material_softys', 'Código material Softys'),
            sorter: (a, b) => a.material_softys ? a.material_softys.localeCompare(b.material_softys) : false,
            showSorterTooltip: false,
            render: (material_softys) => <div title={material_softys}>{material_softys}</div>,
            width: '130px'
        },
        {
            title: 'Categoría Softys',
            dataIndex: 'categoria_softys',
            ...getColumnSearchProps('categoria_softys', 'Categoría Softys'),
            sorter: (a, b) => a.categoria_softys ? a.categoria_softys.localeCompare(b.categoria_softys) : false,
            showSorterTooltip: false,
            render: (categoria_softys) => <div title={categoria_softys}>{categoria_softys}</div>,
            width: '130px'
        },
        {
            title: 'Categoría marketing',
            dataIndex: 'categoria_marketing',
            ...getColumnSearchProps('categoria_marketing', 'Categoría marketing'),
            sorter: (a, b) => a.categoria_marketing ? a.categoria_marketing.localeCompare(b.categoria_marketing) : false,
            showSorterTooltip: false,
            render: (categoria_marketing) => <div title={categoria_marketing}>{categoria_marketing}</div>,
            width: '130px'
        },
        {
            title: 'Código sector',
            dataIndex: 'codigo_sector',
            ...getColumnSearchProps('codigo_sector', 'Código sector'),
            sorter: (a, b) => a.codigo_sector ? a.codigo_sector.localeCompare(b.codigo_sector): false,
            showSorterTooltip: false,
            render: (codigo_sector) => <div title={codigo_sector}>{codigo_sector}</div>,
            width: '130px'
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            ...getColumnSearchProps('sector', 'Sector'),
            sorter: (a, b) => a.sector ? a.sector.localeCompare(b.sector) : false,
            showSorterTooltip: false,
            render: (sector) => <div title={sector}>{sector}</div>,
            width: '130px'
        },
        {
            title: 'Código segmentación',
            dataIndex: 'codigo_segmentacion',
            ...getColumnSearchProps('codigo_segmentacion', 'Código segmentación'),
            sorter: (a, b) => a.codigo_segmentacion ? a.codigo_segmentacion.localeCompare(b.codigo_segmentacion) : false,
            showSorterTooltip: false,
            render: (codigo_segmentacion) => <div title={codigo_segmentacion}>{codigo_segmentacion}</div>,
            width: '130px'
        },
        {
            title: 'Segmentación',
            dataIndex: 'segmentacion',
            ...getColumnSearchProps('segmentacion', 'Segmentación'),
            sorter: (a, b) => a.segmentacion ? a.segmentacion.localeCompare(b.segmentacion) : false,
            showSorterTooltip: false,
            render: (segmentacion) => <div title={segmentacion}>{segmentacion}</div>,
            width: '130px'
        },
        {
            title: 'Código presentación',
            dataIndex: 'codigo_presentacion',
            ...getColumnSearchProps('codigo_presentacion', 'Código presentación'),
            sorter: (a, b) => a.codigo_presentacion ? a.codigo_presentacion.localeCompare(b.codigo_presentacion) : false,
            showSorterTooltip: false,
            render: (codigo_presentacion) => <div title={codigo_presentacion}>{codigo_presentacion}</div>,
            width: '130px'
        },
        {
            title: 'Presentación',
            dataIndex: 'presentacion',
            ...getColumnSearchProps('presentacion', 'Presentación'),
            sorter: (a, b) => a.presentacion ? a.presentacion.localeCompare(b.presentacion) : false,
            showSorterTooltip: false,
            render: (presentacion) => <div title={presentacion}>{presentacion}</div>,
            width: '130px'
        },
        {
            title: 'Código marca',
            dataIndex: 'codigo_marca',
            ...getColumnSearchProps('codigo_marca', 'Código marca'),
            sorter: (a, b) => a.codigo_marca ? a.codigo_marca.localeCompare(b.codigo_marca) : false,
            showSorterTooltip: false,
            render: (codigo_marca) => <div title={codigo_marca}>{codigo_marca}</div>,
            width: '130px'
        },
        {
            title: 'Marca',
            dataIndex: 'marca',
            ...getColumnSearchProps('marca', 'Marca'),
            sorter: (a, b) => a.marca ? a.marca.localeCompare(b.marca) : false,
            showSorterTooltip: false,
            render: (marca) => <div title={marca}>{marca}</div>,
            width: '130px'
        },
        {
            title: 'Código formato',
            dataIndex: 'codigo_formato',
            ...getColumnSearchProps('codigo_formato', 'Código formato'),
            sorter: (a, b) => a.codigo_formato ? a.codigo_formato.localeCompare(b.codigo_formato) : false,
            showSorterTooltip: false,
            render: (codigo_formato) => <div title={codigo_formato}>{codigo_formato}</div>,
            width: '130px'
        },
        {
            title: 'Formato',
            dataIndex: 'formato',
            ...getColumnSearchProps('formato', 'Formato'),
            sorter: (a, b) => a.formato ? a.formato.localeCompare(b.formato) : false,
            showSorterTooltip: false,
            render: (formato) => <div title={formato}>{formato}</div>,
            width: '130px'
        },
        {
            title: 'Código talla',
            dataIndex: 'codigo_talla',
            ...getColumnSearchProps('codigo_talla', 'Código talla'),
            sorter: (a, b) => a.codigo_talla ? a.codigo_talla.localeCompare(b.codigo_talla) : false,
            showSorterTooltip: false,
            render: (codigo_talla) => <div title={codigo_talla}>{codigo_talla}</div>,
            width: '130px'
        },
        {
            title: 'Talla',
            dataIndex: 'talla',
            ...getColumnSearchProps('talla', 'Talla'),
            sorter: (a, b) => a.talla ? a.talla.localeCompare(b.talla) : false,
            showSorterTooltip: false,
            render: (talla) => <div title={talla}>{talla}</div>,
            width: '130px'
        },
        {
            title: 'Código conteo',
            dataIndex: 'codigo_conteo',
            ...getColumnSearchProps('codigo_conteo', 'Código conteo'),
            sorter: (a, b) => a.codigo_conteo ? a.codigo_conteo.localeCompare(b.codigo_conteo) : false,
            showSorterTooltip: false,
            render: (codigo_conteo) => <div title={codigo_conteo}>{codigo_conteo}</div>,
            width: '130px'
        },
        {
            title: 'Conteo',
            dataIndex: 'conteo',
            ...getColumnSearchProps('conteo', 'Conteo'),
            sorter: (a, b) => a.conteo ? a.conteo.localeCompare(b.conteo) : false,
            showSorterTooltip: false,
            render: (conteo) => <div title={conteo}>{conteo}</div>,
            width: '130px'
        },
        {
            title: 'Subcategoría marketing',
            dataIndex: 'subcategoria_marketing',
            ...getColumnSearchProps('subcategoria_marketing', 'Subcategoría marketing'),
            sorter: (a, b) => a.subcategoria_marketing ? a.subcategoria_marketing.localeCompare(b.subcategoria_marketing) : false,
            showSorterTooltip: false,
            render: (subcategoria_marketing) => <div title={subcategoria_marketing}>{subcategoria_marketing}</div>,
            width: '130px'
        },
        {
            title: 'Division softys',
            dataIndex: 'division_softys',
            ...getColumnSearchProps('division_softys', 'Division softys'),
            sorter: (a, b) => a.division_softys?  a.division_softys.localeCompare(b.division_softys) : false,
            showSorterTooltip: false,
            render: (division_softys) => <div title={division_softys}>{division_softys}</div>,
            width: '130px'
        },
        {
            title: 'Subcategoría',
            dataIndex: 'subcategoria',
            ...getColumnSearchProps('subcategoria', 'Subcategoría'),
            sorter: (a, b) => a.subcategoria ? a.subcategoria.localeCompare(b.subcategoria) : false,
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria}>{subcategoria}</div>,
            width: '130px'
        },
        {
            title: 'Disponible Softys',
            dataIndex: 'disponible_softys',
            ...getColumnSearchProps('disponible_softys', 'Disponible Softys'),
            sorter: (a, b) => a.disponible_softys ? a.disponible_softys.localeCompare(b.disponible_softys) : false,
            showSorterTooltip: false,
            render: (disponible_softys) => <div title={disponible_softys}>{disponible_softys}</div>,
            width: '130px'
        },
        {
            title: 'Peso (kg)',
            dataIndex: 'peso_kg',
            ...getColumnSearchProps('peso_kg', 'Peso (kg)'),
            sorter: (a, b) => a.peso_kg ? a.peso_kg.localeCompare(b.peso_kg) : false,
            showSorterTooltip: false,
            render: (peso_kg) => <div title={peso_kg}>{peso_kg}</div>,
            width: '130px'
        },
        {
            title: 'Factor bultos',
            dataIndex: 'factor_bultos',
            ...getColumnSearchProps('factor_bultos', 'Factor bultos'),
            sorter: (a, b) => a.factor_bultos ? a.factor_bultos.localeCompare(b.factor_bultos) : false,
            showSorterTooltip: false,
            render: (factor_bultos) => <div title={factor_bultos}>{factor_bultos}</div>,
            width: '130px'
        },
        {
            title: 'Paquetes bultos',
            dataIndex: 'paquetes_bulto',
            ...getColumnSearchProps('paquetes_bulto', 'Paquetes bultos'),
            sorter: (a, b) => a.paquetes_bulto ? a.paquetes_bulto.localeCompare(b.paquetes_bulto) : false,
            showSorterTooltip: false,
            render: (paquetes_bulto) => <div title={paquetes_bulto}>{paquetes_bulto}</div>,
            width: '130px'
        },
        {
            title: 'Factor unidad minima',
            dataIndex: 'factor_unidad_minima',
            ...getColumnSearchProps('factor_unidad_minima', 'Factor unidad minima'),
            sorter: (a, b) => a.factor_unidad_minima ? a.factor_unidad_minima.localeCompare(b.factor_unidad_minima) : false,
            showSorterTooltip: false,
            render: (factor_unidad_minima) => <div title={factor_unidad_minima}>{factor_unidad_minima}</div>,
            width: '130px'
        },
        {
            title: 'Factor toneladas',
            dataIndex: 'factor_toneladas',
            ...getColumnSearchProps('factor_toneladas', 'Factor toneladas'),
            sorter: (a, b) => a.factor_toneladas? a.factor_toneladas.localeCompare(b.factor_toneladas) : false,
            showSorterTooltip: false,
            render: (factor_toneladas) => <div title={factor_toneladas}>{factor_toneladas}</div>,
            width: '130px'
        },
        {
            title: 'Factor miles',
            dataIndex: 'factor_miles',
            ...getColumnSearchProps('factor_miles', 'Factor miles'),
            sorter: (a, b) => a.factor_miles? a.factor_miles.localeCompare(b.factor_miles) : false,
            showSorterTooltip: false,
            render: (factor_miles) => <div title={factor_miles}>{factor_miles}</div>,
            width: '130px'
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            ...getColumnSearchProps('estado', 'Estado'),
            sorter: (a, b) => a.estado ? a.estado.localeCompare(b.estado) : false,
            showSorterTooltip: false,
            render: (estado) => <div title={estado}>{estado}</div>,
            width: '130px'
        },
        {
            title: 'Unidades hojas',
            dataIndex: 'unidades_hojas',
            ...getColumnSearchProps('unidades_hojas', 'Unidades hojas'),
            sorter: (a, b) => a.unidades_hojas ? a.unidades_hojas.localeCompare(b.unidades_hojas) : false,
            showSorterTooltip: false,
            render: (unidades_hojas) => <div title={unidades_hojas}>{unidades_hojas}</div>,
            width: '130px'
        },
        {
            title: 'Metros unidad',
            dataIndex: 'metros_unidad',
            ...getColumnSearchProps('metros_unidad', 'Metros unidad'),
            sorter: (a, b) => a.metros_unidad ? a.metros_unidad.localeCompare(b.metros_unidad) : false,
            showSorterTooltip: false,
            render: (metros_unidad) => <div title={metros_unidad}>{metros_unidad}</div>,
            width: '130px'
        },
        {
            title: 'Disponible',
            dataIndex: 'disponible',
            ...getColumnSearchProps('disponible', 'Disponible'),
            sorter: (a, b) => a.disponible ? a.disponible.localeCompare(b.disponible) : null,
            showSorterTooltip: false,
            render: (disponible) => <div title={disponible}>{disponible}</div>,
            width: '130px'
        },
        // {
        //     title: 'Fec. Actualización',
        //     dataIndex: 'created_at',
        //     ...getColumnSearchProps('created_at', 'fecha actualización'),
        //     sorter: (a, b) => {
        //         const creacionA = a.created_at || ''
        //         const creacionB = b.created_at || ''
        //         return creacionA.localeCompare(creacionB)
        //     },
        //     showSorterTooltip: false,
        //     render: (created_at) => <div title={created_at?created_at:''}>{created_at? moment(created_at).format('DD/MM/YYYY') : ''}</div>,
        //     width: '120px'
        // }
    ]

    const ObtenerProductosGrow = async () => {
        await dispatch(ObtenerMaestraProductosGrowReducer())
    }

    const {
        rex_data_maestra_productos_grow,
        rex_cargando_maestra_productos_grow,
        rex_feha_actualizacion_maestra_productos_grow
    } = useSelector(({maestraGrow}) => maestraGrow)


    useEffect(() => {
        if(rex_data_maestra_productos_grow.length == 0){
            ObtenerProductosGrow()
        }
    }, [])

    return (

        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Title className="Title-Module">
                        Maestra de Productos Grow {rex_feha_actualizacion_maestra_productos_grow?.espfechaactualizacion
                            ? <span style={{fontSize: '12px'}}>{moment(rex_feha_actualizacion_maestra_productos_grow.espfechaactualizacion).format('DD/MM/YYYY hh:mm:a')}</span>
                            : <span style={{fontSize: '12px'}}>(09/06/2023 11:40 AM)</span>
                        }
                        </Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerProductosGrow()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        { rex_cargando_maestra_productos_grow && rex_data_maestra_productos_grow.length == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_maestra_productos_grow.length}
                                    pageSize={15}
                                    onChange={(page, pageSize) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos_grow.length )} registros</div>
                            </div>
                        }
                        
                    </div>

                    { rex_cargando_maestra_productos_grow && rex_data_maestra_productos_grow.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                        
                    : <Table 
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 15,
                            current: pageTable,
                        }}
                        scroll={{
                            x: '1500px',
                            y: '450px',
                        }}
                        // onChange={(pagination, filters, sorter, extra)=> {
                        //     console.log(extra);
                        //     setCurrentDataSize(extra.currentDataSource.length)
                        // }}
                        columns={columns}
                        dataSource={rex_data_maestra_productos_grow} 
                        className="Table-Home"
                        size='small'
                    />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraProductosGrow