import config from '../../../../config'
import {
    OBTENER_DATA_MAESTRA_CLIENTES_GROW,
    OBTENER_DATA_MAESTRA_PRODUCTOS_GROW,
    CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
    CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
    FECHA_ACTUALIZACION_MAESTRA_CLIENTES_GROW,
    FECHA_ACTUALIZACION_MAESTRA_PRODUCTOS_GROW
} from '../../../../Constans/Administracion/MaestraGrow/MaestraGrow'

export const ObtenerMaestraProductosGrowReducer = () => async ( dispatch ) => {

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
        payload : true,
    })

    await fetch(config.api_url+'administration/get-master-products-grow',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_MAESTRA_PRODUCTOS_GROW,
            payload: data.data
        })
        dispatch({
            type : FECHA_ACTUALIZACION_MAESTRA_PRODUCTOS_GROW,
            payload : data.date_updated
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
        payload : false,
    })
}

export const ObtenerMaestraClientesGrowReducer = () => async ( dispatch ) => {

    dispatch({
        type    : CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
        payload : true
    })

    await fetch(config.api_url+'administration/get-master-clients-grow',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_MAESTRA_CLIENTES_GROW,
            payload: data.data
        })
        dispatch({
            type : FECHA_ACTUALIZACION_MAESTRA_CLIENTES_GROW,
            payload : data.date_updated
        })

    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
        payload : false
    })
}