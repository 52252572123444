import React, {useState} from 'react'
import { Card, Row, Col, Typography, Button, DatePicker } from 'antd'
import {
    DownloadOutlined,
    ArrowLeftOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import {
    GenerarExcelReducer, RemoveExcelReducer
} from '../../Redux/Actions/DownloadData/DownloadData'
import IconoCalendario from '../../Assets/Img/Comunes/calendario.png'
import { format, parseISO } from 'date-fns'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { funPermisosObtenidos } from '../../Functions/funPermiso'

const DownloadData = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const collapsed = props.collapsed
    const { Title } = Typography

    const [loadingDownload, setLoadingDownload] = useState(false)
    const [dateDownloadData, setDateDownloadData] = useState()
    const [buttonFeatures, setButtonFeatures] = useState(() => {
        const permisos = localStorage.getItem('tpuprivilegio') == "todo";
        return permisos
            ? {
                    linkFile: "products-so",
                    nombreFile: "Master Productos Homologados.xlsx",
                    deleteFile: "products-so",
                }
            : {
                    linkFile: "products-approveds",
                    nombreFile: "Master Productos Homologados.xlsx",
                    deleteFile: "products-approveds",
                }
    })

    const {
        rex_data_user_permissions
    } = useSelector(({login}) => login)

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card 
                className="Card-Container Card-Container-CargarData"
                style={{
                    height: "100%",
                    display: "flex",
                    // alignItems: "center",
                    width: '100%',
                    position:'relative'
                }}
            >
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                        <Title className="Title-Module">Descargar Data</Title>

                        </div>
                    }
                    className="Card-Item"
                >
                    <Row
                        style={{
                            marginTop:'10px',
                            marginLeft:'5px',
                            marginRight:'5px',
                        }}
                    >
                        <Col xl={24} style={{marginBottom: '20px', display: 'flex', columnGap: '15px'}}>

                        {
                            funPermisosObtenidos(
                                rex_data_user_permissions,
                                'download.data.reseterar.excel',
                                <Button
                                    style={{
                                        display:'flex', 
                                        alignItems:'center', 
                                        marginRight:'10px'
                                    }} 
                                    onClick={() => dispatch(RemoveExcelReducer(buttonFeatures.deleteFile))}
                                    size='small'
                                >
                                    <ReloadOutlined className='Button-Action-Reload' />
                                    Resetear Excel
                                </Button>
                            )
                        }
                        
                        {
                            funPermisosObtenidos(
                                rex_data_user_permissions,
                                'download.data.filtro.fecha',
                                buttonFeatures.linkFile == "products-so" || buttonFeatures.linkFile == "inventories"
                                ?<div>
                                    <img src={IconoCalendario} style={{marginRight:'5px', width:'20px', height: '20px'}} />
                                    <span className='Text-Filter-Date-Status-Top'>Fecha</span>
                                    <DatePicker
                                        size='small'
                                        placeholder='Fecha'
                                        className='Date-Picker-Status-Top'
                                        picker="month"
                                        inputReadOnly={true}
                                        format={['MM-YYYY']}
                                        onChange={(e) => {
                                            if(e){
                                                const mes = e.$M+1
                                                const date = mes < 10 ? "0"+mes+"/"+e.$y : mes+"/"+e.$y
                                                setDateDownloadData(date)
                                            }else{
                                                setDateDownloadData(format(new Date(), "MM/yyyy"))
                                            }
                                        }}
                                    />
                                </div>
                                :null
                            )
                        }
                        </Col>
                        <Col xl={24} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div
                                style={{
                                    display:'flex'
                                }}
                            >
                                {/* <Button
                                    style={{
                                        marginRight:'20px'
                                    }}
                                    onClick={async () => {
                                        setLoadingProductos(true)
                                        const type_download = "products"
                                        const fileUrl = await dispatch(GenerarExcelReducer(type_download))
                                        const link = document.createElement('a');
                                        link.href = fileUrl;
                                        link.download = 'Maestra de Productos.xlsx';
                                        link.click();
                                        setLoadingProductos(false)
                                    }}
                                    loading={loadingProductos}
                                >
                                    Maestra de Productos
                                </Button> */}
                                {/* <Button
                                    style={{
                                        marginRight:'20px'
                                    }}
                                    onClick={async () => {
                                        setLoadingDistribuidoras(true)
                                        const type_download = "distributors"
                                        const fileUrl = await dispatch(GenerarExcelReducer(type_download))
                                        const link = document.createElement('a');
                                        link.href = fileUrl;
                                        link.download = 'Maestra de Distribuidoras.xlsx';
                                        link.click();
                                        setLoadingDistribuidoras(false)
                                    }}
                                    loading={loadingDistribuidoras}
                                >
                                    Maestra de Distribuidoras
                                </Button> */}

                                {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.ventas',
                                        <Button
                                            style={{
                                                marginRight:'20px',
                                                color: buttonFeatures.linkFile == "products-so" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "products-so" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "products-so",
                                                    nombreFile: "Productos Homologados.xlsx",
                                                    deleteFile: "products-so",
                                                })
                                            }
                                        >
                                            Ventas
                                        </Button>
                                    )
                                }
                                

                                {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.productos.hml',
                                        <Button
                                            style={{
                                                marginRight:'20px',
                                                color: buttonFeatures.linkFile == "products-approveds" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "products-approveds" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "products-approveds",
                                                    nombreFile: "Master Productos Homologados.xlsx",
                                                    deleteFile: "products-approveds",
                                                })
                                            }
                                        >
                                            Productos Homologados
                                        </Button>
                                    )
                                }

                                {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.productos.no.hml',
                                        <Button
                                            style={{
                                                marginRight:'20px',
                                                color: buttonFeatures.linkFile == "products-non-approveds" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "products-non-approveds" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "products-non-approveds",
                                                    nombreFile: "Master Productos No Homologados.xlsx",
                                                    deleteFile: "products-non-approveds",
                                                })
                                            }
                                        >
                                            Productos No Homologados
                                        </Button>
                                    )
                                }

                                {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.master.clientes',
                                        <Button
                                            style={{
                                                marginRight:'20px',
                                                color: buttonFeatures.linkFile == "master-clients" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "master-clients" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "master-clients",
                                                    nombreFile: "Master Clientes.xlsx",
                                                    deleteFile: "master-clients",
                                                })
                                            }
                                        >
                                            Master Clientes
                                        </Button>
                                    )
                                }

                                {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.master.productos',
                                        <Button
                                            style={{
                                                marginRight:'20px',
                                                color: buttonFeatures.linkFile == "master-products" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "master-products" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "master-products",
                                                    nombreFile: "Master Productos.xlsx",
                                                    deleteFile: "master-products",
                                                })
                                            }
                                        >
                                            Master Productos
                                        </Button>
                                    )
                                }

                                {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.inventario',
                                        <Button
                                            style={{
                                                marginRight:'20px',
                                                color: buttonFeatures.linkFile == "inventories" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "inventories" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "inventories",
                                                    nombreFile: "Inventarios.xlsx",
                                                    deleteFile: "inventories",
                                                })
                                            }
                                        >
                                            Inventario
                                        </Button>
                                    )
                                }
                                
                            </div>
                            <div>
                                <Button
                                    shape="round"
                                    onClick={async () => {
                                        setLoadingDownload(true)
                                        const fileUrl = await dispatch(GenerarExcelReducer(buttonFeatures.linkFile, dateDownloadData))
                                        const link = document.createElement('a');
                                        link.href = fileUrl;
                                        link.download = buttonFeatures.nombreFile;
                                        link.click();
                                        setLoadingDownload(false)
                                    }}
                                    loading={loadingDownload}
                                >
                                    Descargar <DownloadOutlined />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
                

            </Card>

        </div>
    )
}

export default DownloadData