import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Modal, Button } from 'antd'
import DateNoHomologados from './DateNoHomologados'
import '../../../Styles/Routes/Homologaciones/NoHomologados/AsignarNoHomologados.css'
import { AsignarNoHomologadosCalendarioReducer, LimpiarGroupDatasReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import '../../../Styles/Components/Date/DateNoHomologados.css'

const ModalNoHomologaciones = (props) => {
    const abrirModal = props.abrirModal
    const setAbrirModal = props.setAbrirModal
    const keyModal = props.keyModal
    const dataModal = props.dataModal
    const typeFilter = props.typeFilter
    const data_seleccionada = props.data_seleccionada
    const positionTable = props.positionTable

    const dispatch = useDispatch()

    const {
        rex_group_data_years,
        rex_group_data_months,
        rex_group_data_days,
        re_type_calendary,
    } = useSelector(({asignarNoHomologados}) => asignarNoHomologados)

    return (
        <Modal 
            title={null}
            open={
                abrirModal.key == keyModal && abrirModal.data == dataModal
                    ? true 
                    : false
            } 
            onCancel={async () => {
                await dispatch(LimpiarGroupDatasReducer())
                setAbrirModal({key: null, data:null})
            }}
            onOk={null}
            className="Modal-Date"
            closable={false}
            footer={null}
        >
            <Row gutter={16}>
                <Col span={7} style={{display: 'flex', flexDirection: 'column', rowGap: '10px', justifyContent: 'center'}}>
                    <div className="Card-Price-Date">
                        <p className="Card-Text">Precio Unitario</p>
                        <p className="Card-Price">
                            {
                                positionTable == 6
                                ?data_seleccionada.sellout
                                    ? "S/"+parseFloat(data_seleccionada.sellout.promedio).toFixed(2)
                                    :""
                                :positionTable == 11
                                    ?data_seleccionada.m_precios
                                        ?data_seleccionada.m_precios[0]
                                            ?data_seleccionada.m_precios[0].promedio
                                                ?"S/"+parseFloat(data_seleccionada.m_precios[0].promedio).toFixed(2) 
                                                :"-"
                                            :"-"
                                        :"-"
                                    :positionTable == 12
                                        ?data_seleccionada.m_precios
                                            ?data_seleccionada.m_precios[1]
                                                ?data_seleccionada.m_precios[1].promedio
                                                    ?"S/"+parseFloat(data_seleccionada.m_precios[1].promedio).toFixed(2) 
                                                    :"-"
                                                :"-"
                                            :"-"
                                        :positionTable == 9
                                            ?data_seleccionada.inventario
                                                ?data_seleccionada.inventario.promedio
                                                    ?"S/"+parseFloat(data_seleccionada.inventario.promedio).toFixed(2) 
                                                    :"-"
                                                :"-"
                                            :positionTable == 13
                                                ?data_seleccionada.sellin
                                                    ?data_seleccionada.sellin.promedio
                                                        ?"S/"+parseFloat(data_seleccionada.sellin.promedio).toFixed(2)
                                                        :"-"
                                                    :"-"
                                                :"-"
                            }
                        </p>
                    </div>
                    <div className="Card-Price-Date">
                        <p className="Card-Text">Precio Unitario más alto</p>
                        <p className="Card-Price">
                            {
                                positionTable == 6
                                ?data_seleccionada.sellout
                                    ? "S/"+parseFloat(data_seleccionada.sellout.maximo).toFixed(2)
                                    :""
                                :positionTable == 11
                                    ?data_seleccionada.m_precios
                                        ?data_seleccionada.m_precios[0]
                                            ?data_seleccionada.m_precios[0].maximo
                                                ?"S/"+parseFloat(data_seleccionada.m_precios[0].maximo).toFixed(2) 
                                                :"-"
                                            :"-"
                                        :"-"
                                    :positionTable == 12
                                        ?data_seleccionada.m_precios
                                            ?data_seleccionada.m_precios[1]
                                                ?data_seleccionada.m_precios[1].maximo
                                                    ?"S/"+parseFloat(data_seleccionada.m_precios[1].maximo).toFixed(2) 
                                                    :"-"
                                                :"-"
                                            :"-"
                                        :positionTable == 9
                                            ?data_seleccionada.inventario
                                                ?data_seleccionada.inventario.maximo
                                                    ?"S/"+parseFloat(data_seleccionada.inventario.maximo).toFixed(2) 
                                                    :"-"
                                                :"-"
                                            :positionTable == 13
                                                ?data_seleccionada.sellin
                                                    ?data_seleccionada.sellin.maximo
                                                        ?"S/"+parseFloat(data_seleccionada.sellin.maximo).toFixed(2)
                                                        :"-"
                                                    :"-"
                                                :"-"
                            }
                        </p>
                    </div>
                    <div className="Card-Price-Date">
                        <p className="Card-Text">Precio Unitario menor</p>
                        <p className="Card-Price">
                            {
                                positionTable == 6
                                ?data_seleccionada.sellout
                                    ? "S/"+parseFloat(data_seleccionada.sellout.minimo).toFixed(2)
                                    :""
                                :positionTable == 11
                                    ?data_seleccionada.m_precios
                                        ?data_seleccionada.m_precios[0]
                                            ?data_seleccionada.m_precios[0].minimo
                                                ?"S/"+parseFloat(data_seleccionada.m_precios[0].minimo).toFixed(2) 
                                                :"-"
                                            :"-"
                                        :"-"
                                    :positionTable == 12
                                        ?data_seleccionada.m_precios
                                            ?data_seleccionada.m_precios[1]
                                                ?data_seleccionada.m_precios[1].minimo
                                                    ?"S/"+parseFloat(data_seleccionada.m_precios[1].minimo).toFixed(2) 
                                                    :"-"
                                                :"-"
                                            :"-"
                                        :positionTable == 9
                                            ?data_seleccionada.inventario
                                                ?data_seleccionada.inventario.minimo
                                                    ?"S/"+parseFloat(data_seleccionada.inventario.minimo).toFixed(2) 
                                                    :"-"
                                                :"-"
                                            :positionTable == 13
                                                ?data_seleccionada.sellin
                                                    ?data_seleccionada.sellin.minimo
                                                        ?"S/"+parseFloat(data_seleccionada.sellin.minimo).toFixed(2)
                                                        :"-"
                                                    :"-"
                                                :"-"
                            }
                        </p>
                    </div>
                </Col>
                <Col span={17}>
                    <DateNoHomologados 
                        dispatch={dispatch} 
                        rex_group_data_years={rex_group_data_years}
                        rex_group_data_months={rex_group_data_months}
                        rex_group_data_days={rex_group_data_days}
                        re_type_calendary={re_type_calendary}
                    />
                </Col>
            </Row>
            <div style={{display: 'flex', justifyContent: 'center', columnGap: '10px', marginTop: '10px'}}>
                <Button 
                    onClick={async () => {
                        await dispatch(AsignarNoHomologadosCalendarioReducer(false, typeFilter))
                        await dispatch(LimpiarGroupDatasReducer())
                        setAbrirModal({key: null, data:null})
                    }}
                >
                    Filtrar
                </Button>
                <Button
                    type="primary"
                    onClick={async () => {
                        await dispatch(AsignarNoHomologadosCalendarioReducer(true))
                        await dispatch(LimpiarGroupDatasReducer())
                        setAbrirModal({key: null, data:null})
                    }}
                >
                    Filtrar Todo
                </Button>
            </div>
        </Modal>
    )
}

export default ModalNoHomologaciones