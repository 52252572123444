import config from '../../../config'
import { notification } from 'antd'
import {
    DATA_USER_LOGIN,
    DATA_USER_PERMISSIONS,
    SESSION_INACTIVITY
} from '../../../Constans/Login/Login'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const LoginReducer = (usu_correo, usu_pais, usu_contrasenia) => async ( dispatch ) => {
    let val = false
    await fetch(config.api_public+'log-in',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usucorreo: usu_correo,
                req_paiid: usu_pais,
                req_usucontrasenia: usu_contrasenia
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.respuesta){
            localStorage.setItem('usutoken', data.auth_token)
            localStorage.setItem('token_val', data.user.token_val)
            localStorage.setItem('tpuprivilegio', data.user.tputiposusuarios.tpuprivilegio)
            dispatch({
                type: DATA_USER_LOGIN,
                payload: data.user
            })
            dispatch({
                type: DATA_USER_PERMISSIONS,
                payload: data.user.tputiposusuarios.tuptiposusuariospermisos
            })
            notificacionServidor('success', data.message)
            val = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return val
}

export const LogOutReducer = () => async (dispatch, getState) => {
    
    await fetch(config.api+'/log-out',
    {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
        }
    })
    .then(async res => {
        localStorage.clear()
        dispatch({
            type: DATA_USER_LOGIN,
            payload: []
        })
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const InactivityLogOutReducer = () => async (dispatch, getState) => {
    localStorage.clear()
    dispatch({
        type: DATA_USER_LOGIN,
        payload: []
    })
    dispatch({
        type: SESSION_INACTIVITY,
        payload: true
    })
}

export const ActivityLogIn = () => async (dispatch, getState) => {
    dispatch({
        type: SESSION_INACTIVITY,
        payload: false
    })
}