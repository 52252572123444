import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { 
    ChangeDataManageMasterClientesReducer, 
    GetDataManageMasterClientesReducer, 
    UpdateDataManageMasterClientesReducer, 
} from '../../../Redux/Actions/Administracion/Administrador/ManageMasterClientes'
import { Typography, Card, Button, Row, Col, Select, Spin } from 'antd'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'

const ManageMasterClientes = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography
    const [loadingButton, setLoadingButton] = useState(false)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const {
        rex_data_zona_master_clientes,
        rex_data_canal_atencion_master_clientes,
        rex_data_segmento_regional_master_clientes,
        rex_loading_zona,
        rex_loading_canal_atencion,
        rex_loading_segmento_regional,
    } = useSelector(({manageMasterClientes}) => manageMasterClientes)
    
    useEffect(() => {
        dispatch(GetDataManageMasterClientesReducer())
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Administrador de Maestra de Clientes</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <Row gutter={[30, 0]} style={{marginTop: '5px'}}>
                        <Col span={8}>
                            <Title style={{marginBottom: '15px'}} level={5}>Zona:</Title>
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                                spinning={rex_loading_zona}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Zona"
                                    onChange={(e) => dispatch(ChangeDataManageMasterClientesReducer("zona", e))}
                                    options={rex_data_zona_master_clientes}
                                    value={rex_data_zona_master_clientes.filter(zon => zon.active)}
                                />
                            </Spin>
                        </Col>
                        <Col span={8}>
                            <Title style={{marginBottom: '15px'}} level={5}>Canal de Atención:</Title>
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                                spinning={rex_loading_canal_atencion}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Canal de Atención"
                                    onChange={(e) => dispatch(ChangeDataManageMasterClientesReducer("canal_atencion", e))}
                                    options={rex_data_canal_atencion_master_clientes}
                                    value={rex_data_canal_atencion_master_clientes.filter(can => can.active)}
                                />
                            </Spin>
                        </Col>
                        <Col span={8}>
                            <Title style={{marginBottom: '15px'}} level={5}>Segmento Regional:</Title>
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                                spinning={rex_loading_segmento_regional}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Segmento Regional"
                                    onChange={(e) => dispatch(ChangeDataManageMasterClientesReducer("segmento_regional", e))}
                                    options={rex_data_segmento_regional_master_clientes}
                                    value={rex_data_segmento_regional_master_clientes.filter(seg => seg.active)}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'end', marginTop: '40px'}}>
                        <Button 
                            onClick={async () => {
                                setLoadingButton(true)
                                await dispatch(UpdateDataManageMasterClientesReducer())
                                setLoadingButton(false)
                            }}
                            loading={loadingButton}
                        >
                            Guardar
                        </Button>
                    </div>
                </Card>
            </Card>
        </div>
    )
}

export default ManageMasterClientes