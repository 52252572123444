import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
    MenuOutlined,
    ReloadOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'
import { format } from 'date-fns'
import { ObtenerMaestraProductosReducer } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import moment from 'moment'

const MaestraProductos = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <MenuOutlined />
        ),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })

    
    const {
        rex_data_maestra_productos,
        rex_cargando_data_maestra_productos
    } = useSelector(({maestraProductos}) => maestraProductos)

    const columns = [
        {
            title: 'Código Producto',
            dataIndex: 'codigo_material',
            ...getColumnSearchProps('codigo_material', 'codigo de material'),
            sorter: (a, b) => {
                const cod_productoA = a.codigo_material || ''
                const cod_productoB = b.codigo_material || ''
                return cod_productoA.localeCompare(cod_productoB)
            },
            render: (codigo_material) => <div title={codigo_material}>{codigo_material}</div>,
            width: '120px'
        },
        {
            title: 'Nombre Producto',
            dataIndex: 'material_softys',
            ...getColumnSearchProps('material_softys', 'nombre de producto'),
            sorter: (a, b) => {
                const nomb_productoA = a.material_softys || ''
                const nomb_productoB = b.material_softys || ''
                return nomb_productoA.localeCompare(nomb_productoB)
            },
            render: (material_softys) => <div title={material_softys}>{material_softys}</div>,
            width: '120px'
        },
        {
            title: 'División',
            dataIndex: 'division',
            ...getColumnSearchProps('division', 'división'),
            sorter: (a, b) => {
                const divisionA = a.division || ''
                const divisionB = b.division || ''
                return divisionA.localeCompare(divisionB)
            },
            render: (division) => <div title={division}>{division}</div>,
            width: '120px'
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            ...getColumnSearchProps('sector', 'sector'),
            sorter: (a, b) => {
                const sectorA = a.sector || ''
                const sectorB = b.sector || ''
                return sectorA.localeCompare(sectorB)
            },
            showSorterTooltip: false,
            render: (sector) => <div title={sector}>{sector}</div>,
            width: '120px'
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria_softys',
            ...getColumnSearchProps('categoria_softys', 'categoria'),
            sorter: (a, b) => {
                const categoriaA = a.categoria_softys || ''
                const categoriaB = b.categoria_softys || ''
                return categoriaA.localeCompare(categoriaB)
            },
            showSorterTooltip: false,
            render: (categoria_softys) => <div title={categoria_softys}>{categoria_softys}</div>,
            width: '120px'
        },
        {
            title: 'Subcategoría',
            dataIndex: 'subcategoria',
            ...getColumnSearchProps('subcategoria', 'subcategoría'),
            sorter: (a, b) => {
                const subcategoriaA = a.subcategoria || ''
                const subcategoriaB = b.subcategoria || ''
                return subcategoriaA.localeCompare(subcategoriaB)
            },
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria}>{subcategoria}</div>,
            width: '120px'
        },
        {
            title: 'Segmento',
            dataIndex: 'segmento',
            ...getColumnSearchProps('segmento', 'segmento'),
            sorter: (a, b) => {
                const segmentoA = a.segmento || ''
                const segmentoB = b.segmento || ''
                return segmentoA.localeCompare(segmentoB)
            },
            showSorterTooltip: false,
            render: (segmento) => <div title={segmento}>{segmento}</div>,
            width: '120px'
        },
        {
            title: 'Presentación',
            dataIndex: 'presentacion',
            ...getColumnSearchProps('presentacion', 'presentación'),
            sorter: (a, b) => {
                const presentacionA = a.presentacion || ''
                const presentacionB = b.presentacion || ''
                return presentacionA.localeCompare(presentacionB)
            },
            showSorterTooltip: false,
            render: (presentacion) => <div title={presentacion}>{presentacion}</div>,
            width: '120px'
        },
        {
            title: 'Peso',
            dataIndex: 'peso_kg',
            ...getColumnSearchProps('peso_kg', 'peso kg'),
            sorter: (a, b) => a.peso_kg ? a.peso_kg - b.peso_kg : false,
            showSorterTooltip: false,
            render: (peso_kg) => <div title={peso_kg}>{peso_kg}</div>,
            width: '120px'
        },
        {
            title: 'Paquete x/Bulto',
            dataIndex: 'paquetes_bulto',
            ...getColumnSearchProps('paquetes_bulto', 'paquetes x/bulto'),
            sorter: (a, b) => a.paquetes_bulto ? a.paquetes_bulto - b.paquetes_bulto : false,
            showSorterTooltip: false,
            render: (paquetes_bulto) => <div title={paquetes_bulto}>{paquetes_bulto}</div>,
            width: '120px'
        },
        {
            title: 'Unidad x/Paquete',
            dataIndex: 'unidadxpqte',
            ...getColumnSearchProps('unidadxpqte', 'unidad x paquete'),
            sorter: (a, b) => a.unidadxpqte ? a.unidadxpqte - b.unidadxpqte : false,
            showSorterTooltip: false,
            render: (unidadxpqte) => <div title={unidadxpqte}>{unidadxpqte}</div>,
            width: '120px'
        },
        {
            title: 'Metros x/Unidad',
            dataIndex: 'metros_unidad',
            ...getColumnSearchProps('metros_unidad', 'metros x/unidad'),
            sorter: (a, b) => a.metros_unidad ? a.metros_unidad - b.metros_unidad : false,
            showSorterTooltip: false,
            render: (metros_unidad) => <div title={metros_unidad}>{metros_unidad}</div>,
            width: '120px'
        },
        // {
        //     title: 'EAN13',
        //     dataIndex: 'ean13',
        //     ...getColumnSearchProps('ean13'),
        //     sorter: (a, b) => a.ean13 - b.ean13,
        //     showSorterTooltip: false,
        //     render: (ean13) => <div title={ean13}>{ean13}</div>,
        //     width: '120px'
        // },
        // {
        //     title: 'EAN14',
        //     dataIndex: 'ean14',
        //     ...getColumnSearchProps('ean14'),
        //     sorter: (a, b) => a.ean14 - b.ean14,
        //     showSorterTooltip: false,
        //     render: (ean14) => <div title={ean14}>{ean14}</div>,
        //     width: '120px'
        // },
        {
            title: 'MinUnd',
            dataIndex: 'minund',
            ...getColumnSearchProps('minund', 'unidad minima'),
            sorter: (a, b) => a.minund ? a.minund - b.minund : false,
            showSorterTooltip: false,
            render: (minund) => <div title={minund}>{minund}</div>,
            width: '120px'
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            ...getColumnSearchProps('estado', 'estado'),
            sorter: (a, b) => {
                return a.estado ? a.estado.localeCompare(b.estado) : false
            },
            showSorterTooltip: false,
            render: (estado) => <div title={estado}>{estado}</div>,
            width: '120px'
        },
        {
            title: 'Marca',
            dataIndex: 'marca',
            ...getColumnSearchProps('marca', 'marca'),
            sorter: (a, b) => {
                const marcoA = a.marca || ''
                const marcoB = b.marca || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (marca) => <div title={marca}>{marca}</div>,
            width: '120px'
        },
        {
            title: 'Fec. Actualización',
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha actualización'),
            sorter: (a, b) => {
                const creacionA = a.created_at || ''
                const creacionB = b.created_at || ''
                return creacionA.localeCompare(creacionB)
            },
            showSorterTooltip: false,
            render: (marca) => <div title={marca}>{moment(marca).format('DD/MM/YYYY')}</div>,
            width: '120px'
        }
    ]

    const ObtenerProductos = async () => {
        await dispatch(ObtenerMaestraProductosReducer())
    }

    useEffect(() => {
        if(rex_data_maestra_productos.length == 0){
            ObtenerProductos()
        }
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} style={{marginRight:'10px'}}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Title level={4} style={{marginBottom: 0}}>Maestra de Productos</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerProductos()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos.length)} registros</div>
                    </div>
                    
                    <Table 
                        pagination={{
                            position: ["topRight", "none"],
                            pageSize:15
                        }}
                        scroll={{
                            x: '1500px',
                            y: '430px',
                        }}
                        loading={rex_cargando_data_maestra_productos}
                        columns={columns}
                        dataSource={rex_data_maestra_productos} 
                        className="Table-Home"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default MaestraProductos