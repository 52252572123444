import React, {useEffect, useState} from 'react'
import { Modal, Table, Input, Select } from 'antd'
import {
    MenuOutlined,
} from '@ant-design/icons'
import { AsignarFiltrosMaestraProductosReducer, FiltrarMaestraProductosReducer } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import { useDispatch, useSelector } from 'react-redux'
import { SelectSkuReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'

const ModalSkuNoHomologaciones = (props) => {

    useEffect(() => {
        dispatch(AsignarFiltrosMaestraProductosReducer())
    },[])

    const aceptar = props.aceptar
    const abrirModalSku = props.abrirModalSku
    const setAbrirModalSku = props.setAbrirModalSku
    const rex_data_maestra_productos = props.rex_data_maestra_productos
    const idRow = props.idRow
    const id_producto_so = props.id_producto_so
    const producto_so_select = props.producto_so_select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const dispatch = useDispatch()

    const [ txtSegmento, setTxtSegmento ] = useState('')
    const [ txtNombre, setTxtNombre ] = useState('')
    const [ txtCategoria, setTxtCategoria ] = useState('')
    const [ txtSubcategoria, setTxtSubcategoria ] = useState('')

    const {
        rex_filtro_data_maestra_segmento,
        rex_filtro_data_maestra_nombre,
        rex_filtro_data_maestra_categoria,
        rex_filtro_data_maestra_sector
    } = useSelector(({maestraProductos}) => maestraProductos)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (<MenuOutlined />),
        onFilter: (value, record) => {
            const text = record[dataIndex] || ''
            return text.toString().toLowerCase().includes(value.toLowerCase())
        }
    })

    const columns = [
        {
            title: 'Código Organizacion',
            dataIndex: 'cod_organizacion',
            ...getColumnSearchProps('cod_organizacion', 'código organizacion'),
            sorter: (a, b) => {
                const segmentoA = a.cod_organizacion || ''
                const segmentoB = b.cod_organizacion || ''
                return segmentoA.localeCompare(segmentoB)
            },
            showSorterTooltip: false,
            render: (cod_organizacion) => <div title={cod_organizacion ? cod_organizacion : ''}>{cod_organizacion ? cod_organizacion : ''}</div>,
            width: '120px'
        },
        {
            title: 'Organización venta',
            dataIndex: 'organizacion_venta',
            ...getColumnSearchProps('organizacion_venta', 'organización venta'),
            sorter: (a, b) => {
                const segmentoA = a.organizacion_venta || ''
                const segmentoB = b.organizacion_venta || ''
                return segmentoA.localeCompare(segmentoB)
            },
            showSorterTooltip: false,
            render: (organizacion_venta) => <div title={organizacion_venta ? organizacion_venta : ''}>{organizacion_venta ? organizacion_venta : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código división',
            dataIndex: 'codigo_division',
            ...getColumnSearchProps('codigo_division', 'código división'),
            sorter: (a, b) => {
                const divisionA = a.codigo_division || ''
                const divisionB = b.codigo_division || ''
                return divisionA.localeCompare(divisionB)
            },
            showSorterTooltip: false,
            render: (codigo_division) => <div title={codigo_division ? codigo_division : ''}>{codigo_division ? codigo_division : ''}</div>,
            width: '120px'
        },
        {
            title: 'División',
            dataIndex: 'division',
            ...getColumnSearchProps('division', 'división'),
            sorter: (a, b) => {
                const divisionA = a.division || ''
                const divisionB = b.division || ''
                return divisionA.localeCompare(divisionB)
            },
            showSorterTooltip: false,
            render: (division) => <div title={division ? division : ''}>{division ? division : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código material',
            // title: 'Código Producto',
            dataIndex: 'codigo_material',
            ...getColumnSearchProps('codigo_material', 'código material'),
            sorter: (a, b) => {
                const cod_productoA = a.codigo_material || ''
                const cod_productoB = b.codigo_material || ''
                return cod_productoA.localeCompare(cod_productoB)
            },
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material ? codigo_material : ''}>{codigo_material ? codigo_material : ''}</div>,
            width: '120px'
        },
        {
            title: 'Material Softys',
            dataIndex: 'material_softys',
            ...getColumnSearchProps('material_softys', 'código material softys'),
            sorter: (a, b) => {
                const cod_productoA = a.material_softys || ''
                const cod_productoB = b.material_softys || ''
                return cod_productoA.localeCompare(cod_productoB)
            },
            showSorterTooltip: false,
            render: (material_softys) => <div title={material_softys ? material_softys : '' }>{material_softys ? material_softys : ''}</div>,
            width: '120px'
        },
        {
            title: 'Categoría Softys',
            // title: 'Categoría Softys',
            dataIndex: 'categoria_softys',
            ...getColumnSearchProps('categoria_softys', 'categoría softys'),
            sorter: (a, b) => {
                const categoriaA = a.categoria_softys || ''
                const categoriaB = b.categoria_softys || ''
                return categoriaA.localeCompare(categoriaB)
            },
            showSorterTooltip: false,
            render: (categoria_softys) => <div title={categoria_softys ? categoria_softys : ''}>{categoria_softys ? categoria_softys : ''}</div>,
            width: '120px'
        },
        {
            title: 'Categoría marketing',
            dataIndex: 'categoria_marketing',
            ...getColumnSearchProps('categoria_marketing', 'categoria_marketing'),
            sorter: (a, b) => {
                const nomb_productoA = a.categoria_marketing || ''
                const nomb_productoB = b.categoria_marketing || ''
                return nomb_productoA.localeCompare(nomb_productoB)
            },
            showSorterTooltip: false,
            render: (categoria_marketing) => <div title={categoria_marketing ? categoria_marketing : ''}>{categoria_marketing ? categoria_marketing : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código sector',
            dataIndex: 'codigo_sector',
            ...getColumnSearchProps('codigo_sector', 'codigo sector'),
            sorter: (a, b) => {
                const sectorA = a.codigo_sector || ''
                const sectorB = b.codigo_sector || ''
                return sectorA.localeCompare(sectorB)
            },
            showSorterTooltip: false,
            render: (codigo_sector) => <div title={codigo_sector ? codigo_sector : ''}>{codigo_sector ? codigo_sector : ''}</div>,
            width: '120px'
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            ...getColumnSearchProps('sector', 'sector'),
            sorter: (a, b) => {
                const sectorA = a.sector || ''
                const sectorB = b.sector || ''
                return sectorA.localeCompare(sectorB)
            },
            showSorterTooltip: false,
            render: (sector) => <div title={sector ? sector : ''}>{sector ? sector : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código segmentación',
            dataIndex: 'codigo_segmentacion',
            ...getColumnSearchProps('codigo_segmentacion', 'codigo segmentacion'),
            sorter: (a, b) => {
                const segmentoA = a.codigo_segmentacion || ''
                const segmentoB = b.codigo_segmentacion || ''
                return segmentoA.localeCompare(segmentoB)
            },
            showSorterTooltip: false,
            render: (codigo_segmentacion) => <div title={codigo_segmentacion ? codigo_segmentacion : ''}>{codigo_segmentacion ? codigo_segmentacion : ''}</div>,
            width: '120px'
        },
        {
            title: 'Segmento',
            dataIndex: 'segmentacion',
            ...getColumnSearchProps('segmentacion', 'segmentación'),
            sorter: (a, b) => {
                const segmentoA = a.segmentacion || ''
                const segmentoB = b.segmentacion || ''
                return segmentoA.localeCompare(segmentoB)
            },
            showSorterTooltip: false,
            render: (segmentacion) => <div title={segmentacion ? segmentacion : ''}>{segmentacion ? segmentacion : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código presentación',
            dataIndex: 'codigo_presentacion',
            ...getColumnSearchProps('codigo_presentacion', 'codigo presentación'),
            sorter: (a, b) => {
                const presentacionA = a.codigo_presentacion || ''
                const presentacionB = b.codigo_presentacion || ''
                return presentacionA.localeCompare(presentacionB)
            },
            showSorterTooltip: false,
            render: (codigo_presentacion) => <div title={codigo_presentacion ? codigo_presentacion : ''}>{codigo_presentacion ? codigo_presentacion : ''}</div>,
            width: '120px'
        },
        {
            title: 'Presentación',
            dataIndex: 'presentacion',
            ...getColumnSearchProps('presentacion', 'presentación'),
            sorter: (a, b) => {
                const presentacionA = a.presentacion || ''
                const presentacionB = b.presentacion || ''
                return presentacionA.localeCompare(presentacionB)
            },
            showSorterTooltip: false,
            render: (presentacion) => <div title={presentacion ? presentacion : ''}>{presentacion ? presentacion : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código marca',
            dataIndex: 'codigo_marca',
            ...getColumnSearchProps('codigo_marca', 'codigo marca'),
            sorter: (a, b) => {
                const marcoA = a.codigo_marca || ''
                const marcoB = b.codigo_marca || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (codigo_marca) => <div title={codigo_marca ? codigo_marca : ''}>{codigo_marca ? codigo_marca : ''}</div>,
            width: '120px'
        },
        {
            title: 'Marca',
            dataIndex: 'marca',
            ...getColumnSearchProps('marca', 'marca'),
            sorter: (a, b) => {
                const marcoA = a.marca || ''
                const marcoB = b.marca || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (marca) => <div title={marca ? marca : ''}>{marca ? marca : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código formato',
            dataIndex: 'codigo_formato',
            ...getColumnSearchProps('codigo_formato', 'codigo formato'),
            sorter: (a, b) => {
                const marcoA = a.codigo_formato || ''
                const marcoB = b.codigo_formato || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (codigo_formato) => <div title={codigo_formato ? codigo_formato : ''}>{codigo_formato ? codigo_formato : ''}</div>,
            width: '120px'
        },
        {
            title: 'Formato',
            dataIndex: 'formato',
            ...getColumnSearchProps('formato', 'formato'),
            sorter: (a, b) => {
                const marcoA = a.formato || ''
                const marcoB = b.formato || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (formato) => <div title={formato ? formato : ''}>{formato ? formato : ''}</div>,
            width: '120px'
        },

        {
            title: 'Código talla',
            dataIndex: 'codigo_talla',
            ...getColumnSearchProps('codigo_talla', 'codigo talla'),
            sorter: (a, b) => {
                const marcoA = a.codigo_talla || ''
                const marcoB = b.codigo_talla || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (codigo_talla) => <div title={codigo_talla ? codigo_talla : ''}>{codigo_talla ? codigo_talla : ''}</div>,
            width: '120px'
        },
        {
            title: 'Talla',
            dataIndex: 'talla',
            ...getColumnSearchProps('talla', 'talla'),
            sorter: (a, b) => {
                const marcoA = a.talla || ''
                const marcoB = b.talla || ''
                return marcoA.localeCompare(marcoB)
            },
            showSorterTooltip: false,
            render: (talla) => <div title={talla ? talla : ''}>{talla ? talla : ''}</div>,
            width: '120px'
        },
        {
            title: 'Código conteo',
            dataIndex: 'codigo_conteo',
            ...getColumnSearchProps('codigo_conteo', 'codigo conteo'),
            sorter: (a, b) => a.codigo_conteo - b.codigo_conteo,
            showSorterTooltip: false,
            render: (codigo_conteo) => <div title={codigo_conteo ? codigo_conteo : ''}>{codigo_conteo ? codigo_conteo : ''}</div>,
            width: '90px'
        },
        {
            title: 'Conteo',
            dataIndex: 'conteo',
            ...getColumnSearchProps('conteo', 'conteo'),
            sorter: (a, b) => a.conteo - b.conteo,
            showSorterTooltip: false,
            render: (conteo) => <div title={conteo ? conteo : ''}>{conteo ? conteo : ''}</div>,
            width: '90px'
        },
        {
            title: 'Subcategoría marketing',
            dataIndex: 'subcategoria_marketing',
            ...getColumnSearchProps('subcategoria_marketing', 'subcategoria marketing'),
            sorter: (a, b) => {
                const subcategoriaA = a.subcategoria_marketing || ''
                const subcategoriaB = b.subcategoria_marketing || ''
                return subcategoriaA.localeCompare(subcategoriaB)
            },
            showSorterTooltip: false,
            render: (subcategoria_marketing) => <div title={subcategoria_marketing ? subcategoria_marketing : ''}>{subcategoria_marketing ? subcategoria_marketing : ''}</div>,
            width: '120px'
        },
        {
            title: 'División',
            dataIndex: 'division',
            ...getColumnSearchProps('division', 'division'),
            sorter: (a, b) => {
                const subcategoriaA = a.division || ''
                const subcategoriaB = b.division || ''
                return subcategoriaA.localeCompare(subcategoriaB)
            },
            showSorterTooltip: false,
            render: (division) => <div title={division ? division : ''}>{division ? division : ''}</div>,
            width: '120px'
        },
        {
            title: 'Subcategoría',
            dataIndex: 'subcategoria',
            ...getColumnSearchProps('subcategoria', 'subcategoría'),
            sorter: (a, b) => {
                const subcategoriaA = a.subcategoria || ''
                const subcategoriaB = b.subcategoria || ''
                return subcategoriaA.localeCompare(subcategoriaB)
            },
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria ? subcategoria : ''}>{subcategoria ? subcategoria : ''}</div>,
            width: '120px'
        },
        {
            title: 'Disponible',
            dataIndex: 'disponible',
            ...getColumnSearchProps('disponible', 'disponible'),
            sorter: (a, b) => {
                const subcategoriaA = a.disponible || ''
                const subcategoriaB = b.disponible || ''
                return subcategoriaA.localeCompare(subcategoriaB)
            },
            showSorterTooltip: false,
            render: (disponible) => <div title={disponible ? disponible : ''}>{disponible ? disponible : ''}</div>,
            width: '120px'
        },
        {
            title: 'Peso',
            dataIndex: 'peso_kg',
            ...getColumnSearchProps('peso_kg', 'peso kg'),
            sorter: (a, b) => a.peso_kg - b.peso_kg,
            showSorterTooltip: false,
            render: (peso_kg) => <div title={peso_kg ? peso_kg : ''}>{peso_kg ? peso_kg : ''}</div>,
            width: '120px'
        },
        {
            title: 'Factor bultos',
            dataIndex: 'factor_bultos',
            ...getColumnSearchProps('factor_bultos', 'factor bultos'),
            sorter: (a, b) => a.peso_kg - b.peso_kg,
            showSorterTooltip: false,
            render: (factor_bultos) => <div title={factor_bultos ? factor_bultos: ''}>{factor_bultos ? factor_bultos : ''}</div>,
            width: '120px'
        },
        {
            title: 'Paquete x/Bulto',
            dataIndex: 'paquetes_bulto',
            ...getColumnSearchProps('paquetes_bulto', 'paquete x/bulto'),
            sorter: (a, b) => a.paquetes_bulto - b.paquetes_bulto,
            showSorterTooltip: false,
            render: (paquetes_bulto) => <div title={paquetes_bulto ? paquetes_bulto : ''}>{paquetes_bulto ? paquetes_bulto : ''}</div>,
            width: '120px'
        },
        {
            title: 'MinUnd',
            dataIndex: 'minund',
            ...getColumnSearchProps('factor_unidad_minima', 'unidad minima'),
            sorter: (a, b) => {
                return a.factor_unidad_minima ? a.factor_unidad_minima - b.factor_unidad_minima : false
            },
            showSorterTooltip: false,
            render: (factor_unidad_minima) => <div title={factor_unidad_minima ? factor_unidad_minima : ''}>{factor_unidad_minima ? factor_unidad_minima : ''}</div>,
            width: '120px'
        },
        {
            title: 'Factor toneladas',
            dataIndex: 'factor_toneladas',
            ...getColumnSearchProps('factor_toneladas', 'factor toneladas'),
            sorter: (a, b) => a.factor_toneladas - b.factor_toneladas,
            showSorterTooltip: false,
            render: (factor_toneladas) => <div title={factor_toneladas ? factor_toneladas : ''}>{factor_toneladas ? factor_toneladas : ''}</div>,
            width: '120px'
        },
        {
            title: 'Factor miles',
            dataIndex: 'factor_miles',
            ...getColumnSearchProps('factor_miles', 'factor miles'),
            sorter: (a, b) => a.factor_miles - b.factor_miles,
            showSorterTooltip: false,
            render: (factor_miles) => <div title={factor_miles ? factor_miles : ''}>{factor_miles ? factor_miles : ''}</div>,
            width: '110px'
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            ...getColumnSearchProps('estado', 'estado'),
            sorter: (a, b) => {
                const estadoA = a.estado || ''
                const estadoB = b.estado || ''
                return estadoA.localeCompare(estadoB)
            },
            showSorterTooltip: false,
            render: (estado) => <div title={estado ? estado : ''}>{estado ? estado : ''}</div>,
            width: '120px'
        },
        {
            title: 'Unidades / Hojas',
            dataIndex: 'unidades_hojas',
            ...getColumnSearchProps('unidades_hojas', 'unidades /hojas'),
            sorter: (a, b) => a.unidades_hojas - b.unidades_hojas,
            showSorterTooltip: false,
            render: (unidades_hojas) => <div title={unidades_hojas ? unidades_hojas : ''}>{unidades_hojas ? unidades_hojas : ''}</div>,
            width: '120px'
        },
        {
            title: 'Metros x/Unidad',
            dataIndex: 'metros_unidad',
            ...getColumnSearchProps('metros_unidad', 'metros x/unidad'),
            sorter: (a, b) => a.metros_unidad - b.metros_unidad,
            showSorterTooltip: false,
            render: (metros_unidad) => <div title={metros_unidad ? metros_unidad: ''}>{metros_unidad ? metros_unidad: ''}</div>,
            width: '120px'
        },
        {
            title: 'Disponible',
            dataIndex: 'disponible',
            ...getColumnSearchProps('disponible', 'disponible'),
            sorter: (a, b) => {
                const estadoA = a.estado || ''
                const estadoB = b.estado || ''
                return estadoA.localeCompare(estadoB)
            },
            showSorterTooltip: false,
            render: (disponible) => <div title={disponible ? disponible : ''}>{disponible ? disponible : ''}</div>,
            width: '120px'
        },
    ]

    const seleccionarFila = (record) => {
        return {
            onClick: event => {
                setSelectedRowKeys([record.key])
                dispatch(SelectSkuReducer(idRow, id_producto_so, producto_so_select, record))
            }
        }
    }

    const rowClassName = (record) => {
        return selectedRowKeys.includes(record.key) ? 'Row-Active' : ''
    }

    return (
        <Modal 
            title="Maestra de Productos"
            open={idRow == abrirModalSku ? true : false} 
            onCancel={() => {
                setAbrirModalSku(null)
                dispatch(FiltrarMaestraProductosReducer('','','',''))
                setTxtNombre('')
                setTxtSegmento('')
                setTxtCategoria('')
                setTxtSubcategoria('')
            }}
            onOk={() => {
                aceptar()
                setAbrirModalSku(null)
                dispatch(FiltrarMaestraProductosReducer('','','',''))
                setTxtNombre('')
                setTxtSegmento('')
                setTxtCategoria('')
                setTxtSubcategoria('')
            }}
            centered
            closable={false}
            width={1000}
        >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                <Select
                    showSearch
                    placeholder="Nombre"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtNombre('')
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, '', txtCategoria, txtSubcategoria))
                    }}
                    onSelect={ (value) => {
                        setTxtNombre(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, value, txtCategoria, txtSubcategoria))
                    }}
                    onSearch={(value ) => {
                        setTxtNombre(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, value, txtCategoria, txtSubcategoria))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length + txtSubcategoria.length == 0
                                ? rex_filtro_data_maestra_nombre
                                : rex_filtro_data_maestra_nombre
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.material_softys ? ma.material_softys.toUpperCase() == rex.value : false))}
                />
        
                <Select
                    showSearch
                    placeholder="Segmento"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtSegmento('')
                        dispatch(FiltrarMaestraProductosReducer('',txtNombre, txtCategoria, txtSubcategoria))
                    }}
                    onSelect={ (value) => {
                        setTxtSegmento(value)
                        dispatch(FiltrarMaestraProductosReducer(value, txtNombre, txtCategoria, txtSubcategoria))
                    }}
                    onSearch={(value ) => {
                        setTxtSegmento(value)
                        dispatch(FiltrarMaestraProductosReducer(value, txtNombre, txtCategoria, txtSubcategoria))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length + txtSubcategoria.length == 0
                                ? rex_filtro_data_maestra_segmento
                                : rex_filtro_data_maestra_segmento
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.segmentacion ? ma.segmentacion.toUpperCase() == rex.value : false))}
                />
                <Select
                    showSearch
                    placeholder="Categoria"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtCategoria('')
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, '', txtSubcategoria))
                    }}
                    onSelect={ (value) => {
                        setTxtCategoria(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, value, txtSubcategoria))
                    }}
                    onSearch={(value ) => {
                        setTxtCategoria(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, value, txtSubcategoria))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length + txtSubcategoria.length == 0
                                ? rex_filtro_data_maestra_categoria
                                : rex_filtro_data_maestra_categoria
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.categoria_softys ? ma.categoria_softys.toUpperCase() == rex.value : false))}
                />
                <Select
                    showSearch
                    placeholder="Sector"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtSubcategoria('')
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, txtCategoria, ''))
                    }}
                    onSelect={ (value) => {
                        setTxtSubcategoria(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, txtCategoria, value))
                    }}
                    onSearch={(value ) => {
                        setTxtSubcategoria(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, txtCategoria, value))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length + txtSubcategoria.length == 0
                                ? rex_filtro_data_maestra_sector
                                : rex_filtro_data_maestra_sector
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.sector ? ma.sector.toUpperCase() == rex.value : false))}
                />
                </div>

                <div style={{textAlign: 'end', marginBottom: '3px'}}>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos.length)} registros</div>
            </div>
            <Table 
                pagination={{
                    position: ["topRight", "none"],
                    pageSize: 15
                }}
                scroll={{
                    x: '1500px',
                    y: '430px',
                }}
                onRow={seleccionarFila}
                columns={columns} 
                dataSource={rex_data_maestra_productos} 
                className="Table-Home Modal-No-Homologados"
                rowKey="key"
                rowClassName={rowClassName}
                size='small'
            />
        </Modal>
    )
}

export default ModalSkuNoHomologaciones