import React, {useState} from 'react'
import { Button, Modal, Select, Input, Form, notification } from 'antd'
import {
    SaveOutlined
} from '@ant-design/icons'
import '../../Styles/Components/Modal/ModalAdd.css'
import { useSelector, useDispatch } from 'react-redux'

const ModalUpdate = (props) => {
    const filtroData = props.filtroMasterProductos
    const informacion = props.informacion
    const isModalOpen = props.isModalOpen
    const setIsModalOpen = props.setIsModalOpen
    const action = props.action

    const dispatch = useDispatch()

    const onFinish = async (data) => {
        
        if(data.select && data.unidadMedida){
            await dispatch(action(informacion.id, data.select, false, [], data.unidadMedida))
            setIsModalOpen(null)
        }else if(data.select){
            notification['warning']({
                message: 'Advertencia',
                description: "Lo sentimos debe ingresar una Unidad Medida para actualizar",
            })
        }else{
            notification['warning']({
                message: 'Advertencia',
                description: "Lo sentimos debe seleccionar un SKU para actualizar",
            })
        }
    }

    return (
        <Modal 
            title="Actualizar Homologación" 
            open={isModalOpen == informacion.key ? true : false} 
            onCancel={() => setIsModalOpen(null)}
            centered={true}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                    codigoDistribuidor: informacion.masterclientes_grow.codigo_destinatario,
                    nombreDistribuidor: informacion.masterclientes_grow.cliente_hml,
                    codigoProducto: informacion.codigo_producto,
                    descripcionProducto: informacion.descripcion_producto,
                    // codigoSoftys: informacion.master_productos.cod_producto,
                    codigoSoftys: informacion.master_productos_grow.codigo_material,
                    // descripcionSoftys: informacion.master_productos.nomb_producto,
                    descripcionSoftys: informacion.master_productos_grow.material_softys,
                    desde: informacion.desde,
                    unidadMedida : informacion.unidad_medida ? informacion.unidad_medida : ''
                }}
            >
                <Form.Item
                    name="select"
                    style={{
                        marginTop: '10px',
                        marginBottom: '0px'
                    }}
                    required={true}
                >
                    <Select
                        placeholder="Buscando SKU Softys..."
                        className="Form-Item-Select"
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={filtroData.map(f => {
                            return {
                                value: f.id,
                                label: f.cod_producto+" - "+f.nomb_producto
                            }
                        })}
                        required={true}
                    />
                </Form.Item>
                <div style={{marginTop:'20px'}}>
                    <Form.Item
                        name="unidadMedida"
                        label='Unidad de medida'
                        className="Form-Item-Input"
                    >
                        <Input
                            placeholder='Unidad de medida'
                            className=""
                        />
                    </Form.Item>
                </div>
                <div className="Form-Separador"></div>
                <Form.Item
                    name="codigoDistribuidor"
                    label="Codigo Distribuidor"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    name="nombreDistribuidor"
                    label="Nombre Distribuidor"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    name="codigoProducto"
                    label="Codigo Producto DT"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    name="descripcionProducto"
                    label="Descripcion Producto DT"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    name="codigoSoftys"
                    label="Codigo Softys"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    name="descripcionSoftys"
                    label="Descripcion Softys"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    name="desde"
                    label="Desde"
                    className="Form-Item-Input"
                >
                    <Input disabled={true} className="Input-Modal" />
                </Form.Item>
                <Form.Item
                    style={{
                        textAlign: 'end',
                        marginBottom: 0
                    }}
                >
                    <Button 
                        type="primary" 
                        ghost
                        // onClick={() => setIsModalOpen(false)}
                        htmlType="submit"
                    >
                        <SaveOutlined />
                        Update
                    </Button>
                    <Button 
                        danger
                        onClick={() => setIsModalOpen(null)}
                        style={{
                            marginLeft: '10px'
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalUpdate