import { 
    OBTENER_DATA_MAESTRA_PRODUCTOS,
    OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
    FILTRO_DATA_MAESTRA_PRODUCTOS_SEGMENTO,
    FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
    FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA,
    FILTRO_DATA_MAESTRA_PRODUCTOS_SECTOR,
    CARGANDO_DATA_MAESTRA_PRODUCTOS
} from "../../../../Constans/Administracion/MaestraProductos/MaestraProductos";


const INIT_STATE = {
    rex_data_maestra_productos : [],
    rex_data_maestra_productos_copia : [],
    rex_filtro_data_maestra_segmento : [],
    rex_filtro_data_maestra_nombre: [],
    rex_filtro_data_maestra_categoria: [],
    rex_filtro_data_maestra_sector: [],
    rex_cargando_data_maestra_productos : true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CARGANDO_DATA_MAESTRA_PRODUCTOS: {
            return {
                ...state,
                rex_cargando_data_maestra_productos : action.payload
            }
        }
        case OBTENER_DATA_MAESTRA_PRODUCTOS: {
            return {
                ...state,
                rex_data_maestra_productos : action.payload
            }
        }
        case OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA: {
            return {
                ...state,
                rex_data_maestra_productos_copia : action.payload
            }
        }
        case FILTRO_DATA_MAESTRA_PRODUCTOS_SEGMENTO: {
            return {
                ...state,
                rex_filtro_data_maestra_segmento : action.payload
            }
        }
        case FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE: {
            return {
                ...state,
                rex_filtro_data_maestra_nombre : action.payload
            }
        }
        case FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA: {
            return {
                ...state,
                rex_filtro_data_maestra_categoria : action.payload
            }
        }
        case FILTRO_DATA_MAESTRA_PRODUCTOS_SECTOR: {
            return {
                ...state,
                rex_filtro_data_maestra_sector : action.payload
            }
        }
        default:
            return state;
    }
}
