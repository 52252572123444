import config from '../../../../config'
import { 
    OBTENER_DATA_MAESTRA_PRODUCTOS,
    OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
    FILTRO_DATA_MAESTRA_PRODUCTOS_SEGMENTO,
    FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
    FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA,
    FILTRO_DATA_MAESTRA_PRODUCTOS_SECTOR,
    CARGANDO_DATA_MAESTRA_PRODUCTOS
} from '../../../../Constans/Administracion/MaestraProductos/MaestraProductos'

export const ObtenerMaestraProductosReducer = () => async ( dispatch ) => {

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS,
        payload : true
    })

    await fetch(config.api_url+'administration/get-products',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {

        dispatch({
            type: OBTENER_DATA_MAESTRA_PRODUCTOS,
            payload: data.data
        })
        dispatch({
            type: OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS,
        payload : false
    })
}

export const FiltrarMaestraProductosReducer = (segmento, nombre, categoria, sector) => async (dispatch, getState) =>{
    
    let lista_ma_pro_orig   = getState().maestraProductos.rex_data_maestra_productos
    let mae_pro_filtrado

    //Cuando se hace clear en el select
    if(nombre == undefined){
        nombre = ''
    }
    if(segmento == undefined){
        segmento = ''
    }
    if(categoria == undefined){
        categoria = ''
    }
    if(sector == undefined){
        sector = ''
    }
    
    mae_pro_filtrado = lista_ma_pro_orig.filter((pro) => 
        (
            (pro.segmentacion ? pro.segmentacion.toLowerCase().includes(segmento?.toLocaleLowerCase()) : segmento != '' ? false : true)
            && ( pro.material_softys ? pro.material_softys.toLowerCase().includes(nombre?.toLocaleLowerCase()) : nombre != '' ? false : true)
            && ( pro.categoria_softys ? pro.categoria_softys.toLowerCase().includes(categoria?.toLocaleLowerCase()) : categoria != '' ? false : true)
            && ( pro.sector ? pro.sector.toLowerCase().includes(sector?.toLocaleLowerCase()) : sector != '' ? false : true)
        )
    )

    if(nombre == '' && segmento == '' && categoria == '' && sector == ''){
        dispatch({
            type    : OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
            payload : lista_ma_pro_orig
        })
    }else{
        dispatch({
            type    : OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
            payload : mae_pro_filtrado
        })
    }
}

export const AsignarFiltrosMaestraProductosReducer = ( ) => async ( dispatch, getState) => {

    let data   = getState().maestraProductos.rex_data_maestra_productos

    let filtro_segmento = []
    let filtro_categoria = []
    let filtro_sector = []
    let filtro_nombre = []
    
    data.forEach( dat => {
        
        if(dat.segmentacion){
            let existe_segmento = filtro_segmento.findIndex( (filtro ) => filtro.value ==  dat.segmentacion.toUpperCase())
            if(existe_segmento == -1){
                filtro_segmento.push({
                    label : dat.segmentacion.toUpperCase(),
                    value : dat.segmentacion.toUpperCase()
                })
            }
        }
        if(dat.categoria_softys){
            let existe_categoria = filtro_categoria.findIndex( (filtro ) => filtro.value ==  dat.categoria_softys.toUpperCase())
            if(existe_categoria == -1){
                filtro_categoria.push({
                    label : dat.categoria_softys.toUpperCase(),
                    value : dat.categoria_softys.toUpperCase()
                })
            }
        }
        if(dat.sector){
            let existe_subcategoria = filtro_sector.findIndex( (filtro ) => filtro.value ==  dat.sector.toUpperCase())
            if(existe_subcategoria == -1){
                filtro_sector.push({
                    label : dat.sector.toUpperCase(),
                    value : dat.sector.toUpperCase()
                })
            }
        }
        if(dat.material_softys){
            let existe_nombre = filtro_nombre.findIndex( (filtro ) => filtro.value ==  dat.material_softys.toUpperCase())
            if(existe_nombre == -1){
                filtro_nombre.push({
                    label : dat.material_softys.toUpperCase(),
                    value : dat.material_softys.toUpperCase()
                })
            }
        }
    });

    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_SEGMENTO,
        payload : filtro_segmento
    })
    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
        payload : filtro_nombre
    })
    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA,
        payload : filtro_categoria
    })
    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_SECTOR,
        payload : filtro_sector
    })
}