import React, { useState } from 'react'
import { 
    Typography, Table, Card, Button, 
    Popconfirm, Input, Tooltip 
} from 'antd'
import {
    CalendarOutlined,
} from '@ant-design/icons'
import ModalNoHomologaciones from '../../../Components/Homologados/NoHomologados/ModalNoHomologaciones'

const TablaIndicadores = (props) => {
    
    const [abrirModal, setAbrirModal] = useState({key: null, data:null})
    const {
        data_table,
        loading_table
    } = props
    
    const columns = [
        // {
        //     title: <div className='Cabecera-SO-Indicares-Table-HML'>COD DT</div>,
        //     dataIndex: 'codigo_distribuidor',
        //     render: (_, record) => (
        //         <div>{record.master_distribuidoras.codigo_dt}</div>
        //         // <div title={codigo_distribuidor == "LALIBERTAD.01.262635" ?"ALVAREZ BOHL - TRUJILLO" :"262751" }>
        //         //     {
        //         //         codigo_distribuidor == "LALIBERTAD.01.262635" 
        //         //         ? "ALVAREZ BOHL - TRUJILLO" 
        //         //         : "262751"
        //         //     }
        //         // </div>
        //     ),
        //     width: '60px'
        // },
        {
            title: <div className='Cabecera-SO-Indicares-Table-HML' >CLIENTE HML </div>,
            dataIndex: 'master_distribuidoras',
            render: (_,record) => 
            <div title={record.masterclientes_grow ?record.masterclientes_grow.cliente_hml: '-'}>
                {record.masterclientes_grow ?record.masterclientes_grow.cliente_hml: '-'}
            </div>,
            // <div 
            //     title={master_distribuidoras ? master_distribuidoras.region : 'ALVAREZ BOHL - TRUJILLO'}
            // >
            //     {master_distribuidoras ? master_distribuidoras.region : 'ALVAREZ BOHL - TRUJILLO'}
            // </div>,
            width: '100px'
        },
        {
            title: <div className='Cabecera-SO-Indicares-Table-HML' >COD <br/>SKU </div>,
            dataIndex: 'codigo_producto',
            render: (codigo_producto) => (
                <div style={{textAlign: 'end'}} title={codigo_producto}>
                    {codigo_producto}
                </div>
            ),
            width: '60px'
        },
        {
            title: <div className='Cabecera-SO-Indicares-Table-HML' >SKU </div>,
            dataIndex: 'descripcion_producto',
            render: (descripcion_producto) => (
                <div title={descripcion_producto}>
                    {descripcion_producto}
                </div>
            ),
            width: '140px'
        },
        {
            title: <div className='Cabecera-SO-Indicares-Table-HML' >COD <br/>UM </div>,
            dataIndex: 'cod_unidad_medida',
            render: (cod_unidad_medida) => (
                <div title={cod_unidad_medida}>
                    {cod_unidad_medida}
                </div>
            ),
            width: '50px'
        },
        {
            title: <div className='Cabecera-SO-Indicares-Table-HML' >UM </div>,
            dataIndex: 'unidad_medida',
            render: (unidad_medida) => (
                <div title={unidad_medida}>
                    {unidad_medida}
                </div>
            ),
            width: '65px'
        },
        // 
        {
            title: <div className='Cabecera-SO-Indicares-Table-HML'>PRECIO X CANTIDAD SO </div>,
            dataIndex: 'precio_unitario',
            render: (precio_unitario, info) => (
                <>
                <div 
                    style={{textAlign: 'end', marginRight: '6px',}} 
                    title={info.sellout?.promedio ? "S/"+parseFloat(info.sellout.promedio).toFixed(2): ''}
                >
                    {info.sellout.promedio ? "S/"+parseFloat(info.sellout.promedio).toFixed(2) : 'S/' }
                </div>
                <CalendarOutlined 
                    className="Icon-Assign-Table" 
                    onClick={() => {
                        setAbrirModal({key: info.key, data: "PRECIO X CANTIDAD SO"})
                        console.log(info)
                    }}
                />
                {
                    abrirModal.key == info.key && abrirModal.data == "PRECIO X CANTIDAD SO"
                    ?   <ModalNoHomologaciones
                            abrirModal={abrirModal}
                            setAbrirModal={setAbrirModal}
                            keyModal={info.key}
                            dataModal="PRECIO X CANTIDAD SO"
                            typeFilter="sellout"
                            data_seleccionada = {info}
                            positionTable={6}
                        />
                    :   null
                }
                </>
            ),
            width: '80px'
        },
        {
            title: <div className='Cabecera-INV-Indicares-Table-HML'>COD <br/>UM </div>,
            dataIndex: 'inventario',
            render: (inventario) => (
                <div 
                    title={
                        inventario
                        ?inventario.cod_unidad_medida
                        :""
                    }
                >
                    {
                        inventario
                        ?inventario.cod_unidad_medida
                        :""
                    }
                </div>
            ),
            width: '50px'
        },
        {
            title: <div className='Cabecera-INV-Indicares-Table-HML'>UM <br/>STK </div>,
            dataIndex: 'inventario',
            render: (inventario) => (
                <div 
                    title={
                        inventario
                        ?inventario.unidad_medida
                        :""
                    }
                >
                    {
                        inventario
                        ?inventario.unidad_medida
                        :""
                    }
                </div>
            ),
            width: '55px'
        },
        {
            title: <div className='Cabecera-INV-Indicares-Table-HML'>PRECIO X CANTIDAD STK </div>,
            dataIndex: 'inventario',
            render: (inventario, info) => (
                <>
                <div 
                    style={{textAlign: 'end', marginRight: '6px',}} 
                    title={
                        inventario
                        ?"S/"+parseFloat(inventario.promedio).toFixed(2)
                        :""
                    }
                >
                    {
                        inventario.promedio
                        ?"S/"+parseFloat(inventario.promedio).toFixed(2)
                        :"S/"
                    }
                </div>
                <CalendarOutlined 
                    className="Icon-Assign-Table" 
                    onClick={() => setAbrirModal({key: info.key, data: "PRECIO X CANTIDAD INV"})}
                />
                {
                    abrirModal.key == info.key && abrirModal.data == "PRECIO X CANTIDAD INV"
                    ?   <ModalNoHomologaciones
                            abrirModal={abrirModal}
                            setAbrirModal={setAbrirModal}
                            keyModal={info.key}
                            dataModal="PRECIO X CANTIDAD INV"
                            typeFilter="inv"
                            data_seleccionada = {info}
                            positionTable={9}
                        />
                    :   null
                }
                </>
            ),
            width: '85px'
        },
        {
            title: <div className='Cabecera-MPROD-Indicares-Table-HML' >FACTOR <br/>CJ </div>,
            dataIndex: 'producto_hml_seleccionado',
            render: (producto_hml_seleccionado) => (
                <div title={
                    producto_hml_seleccionado
                    ?producto_hml_seleccionado.paquetexbulto
                    :""
                }>
                    {
                        producto_hml_seleccionado
                        ?producto_hml_seleccionado.paquetexbulto
                        :""
                    }
                </div>
            ),
            width: '65px'
        },
        {
            title: <div className='Cabecera-MPRECIOS-Indicares-Table-HML' >PRECIO<br/>LISTA X CJ </div>,
            dataIndex: 'm_precios',
            render: (m_precios, info) => (
                <>
                <div 
                    style={{textAlign: 'end', marginRight: '6px',}} 
                    title={
                        m_precios
                        ?m_precios.length > 0
                            ?m_precios[0].promedio
                                ?"S/"+parseFloat(m_precios[0].promedio).toFixed(2)
                                :""
                            :""
                        :""
                    }
                >
                    {
                        m_precios
                        ?m_precios.length > 0
                            ?m_precios[0].promedio
                                ?"S/"+parseFloat(m_precios[0].promedio).toFixed(2)
                                :"S/"
                            :"S/"
                        :"S/"
                    }
                </div>
                <CalendarOutlined 
                    className="Icon-Assign-Table" 
                    onClick={() => setAbrirModal({key: info.key, data: "PRECIO LISTA X CAJA"})}
                />
                {
                    abrirModal.key == info.key && abrirModal.data == "PRECIO LISTA X CAJA"
                    ?   <ModalNoHomologaciones
                            abrirModal={abrirModal}
                            setAbrirModal={setAbrirModal}
                            keyModal={info.key}
                            dataModal="PRECIO LISTA X CAJA"
                            typeFilter="master"
                            data_seleccionada = {info}
                            positionTable={11}
                        />
                    :   null
                }
                </>
            ),
            width: '85px'
        },
        {
            title: <div className='Cabecera-MPRECIOS-Indicares-Table-HML' >PRECIO<br/>LISTA X UND </div>,
            dataIndex: 'm_precios',
            render: (m_precios, info) => {

                let precio_lista_x_un = ''
                if(info.m_precios && info.m_precios[0].promedio){
                    if(info.producto_hml_seleccionado){
                        if(info.producto_hml_seleccionado.paquetexbulto){
                            precio_lista_x_un = (parseFloat(m_precios[0].promedio) / parseFloat(info.producto_hml_seleccionado.paquetexbulto)).toFixed(2)
                        }
                    }
                }

                return <>
                    <div 
                        style={{textAlign: 'end', marginRight: '6px'}} 
                        title={precio_lista_x_un}
                    >
                        {
                            precio_lista_x_un
                            ? "S/"+precio_lista_x_un
                            : "S/"
                        }
                    </div>
                </>
            },
            width: '80px'
        },
        {
            title: <div className='Cabecera-SI-Indicares-Table-HML' >PRECIO<br/>SELL IN X CJ </div>,
            dataIndex: 'sellin',
            render: (sellin, info) => (
                <>
                <div 
                    style={{textAlign: 'end', marginRight: '6px',}} 
                    title={
                        sellin
                        ?sellin.promedio
                            ?"S/"+parseFloat(sellin.promedio).toFixed(2) 
                            :""
                        :""
                    }
                >
                    {
                        sellin
                        ?sellin.promedio
                            ?"S/"+parseFloat(sellin.promedio).toFixed(2) 
                            :"S/"
                        :"S/"
                    }
                </div>
                <CalendarOutlined 
                    className="Icon-Assign-Table" 
                    onClick={() => setAbrirModal({key: info.key, data: "PRECIO SELL IN X CAJA"})}
                />
                {
                    abrirModal.key == info.key && abrirModal.data == "PRECIO SELL IN X CAJA"
                    ?   <ModalNoHomologaciones
                            abrirModal={abrirModal}
                            setAbrirModal={setAbrirModal}
                            keyModal={info.key}
                            dataModal="PRECIO SELL IN X CAJA"
                            typeFilter="sellin"
                            data_seleccionada = {info}
                            positionTable={13}
                        />
                    :   null
                }
                </>
            ),
            width: '90px'
        },
    ]

    return (
        <div style={{marginRight: '-19px', marginLeft: '-19px'}}>
            <Table 
                pagination={{
                    position: ["none", "none"],
                }}
                scroll={{
                    y: '330px',
                }}
                loading={loading_table}
                columns={columns}
                dataSource={data_table} 
                className="Table-Home" 
                size='small'
            />
        </div>
    )
}

export default TablaIndicadores