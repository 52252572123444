import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Card, Input, Button, Form, Checkbox, Switch, Col, Row, Divider, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
    LoadingOutlined,
    SaveOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'
import { 
    CrearTipoUsuarioReducer, 
    ObtenerDataTipoUsuarioNuevoReducer,
    EditarPermisoTipoUsuarioNuevoReducer
} from '../../../Redux/Actions/Administracion/Administrador/TiposUsuarios'
import '../../../Styles/Components/Administracion/Administrador/TiposUsuarios.css'

const CrearTipoUsuario = (props) => {

    const collapsed = props.collapsed
    const [ creandoUsuario, setCreandoUsuario] = useState(false)
    const [form] = Form.useForm();
    const { Title } = Typography

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const CrearTipoUsuario = async () => {

        setCreandoUsuario(true)
        await dispatch(CrearTipoUsuarioReducer())
        setCreandoUsuario(false)
    }

    const {
        rex_data_tipo_usuario_nuevo
    } = useSelector(({tiposUsuarios}) => tiposUsuarios)

    useEffect(() => {
        dispatch(ObtenerDataTipoUsuarioNuevoReducer())
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s',
                
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<div style={{display:'flex', alignItems:'center'}}>
                        <Button type='primary' ghost size='small' onClick={() => navigate(`/admin/tipos-usuarios`)} className="Button-Title-Module"><ArrowLeftOutlined /></Button>
                        <Title className="Title-Module">Crear tipo de usuario</Title>
                        </div>}
                    className="Card-Item"
                >
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={() => CrearTipoUsuario()}
                        className='Container-Form-Type-User'
                    >
                        <div>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El nombre es necesario' },
                                        ]}
                                        label='Nombre:'
                                        name='nombre'
                                        className="Type-Profile-Item"
                                    >
                                        <Input
                                            value={rex_data_tipo_usuario_nuevo.tpunombre}
                                            autoComplete='off'
                                            onChange={(e) => dispatch(EditarPermisoTipoUsuarioNuevoReducer('tpunombre', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label='Privilegio:'
                                        className="Type-Profile-Item"
                                    >
                                        <Input
                                            name='tpuprivilegio'
                                            autoComplete='off'
                                            value={rex_data_tipo_usuario_nuevo.tpuprivilegio}
                                            onChange={(e) => dispatch(EditarPermisoTipoUsuarioNuevoReducer('tpuprivilegio', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label='Estado: '
                                        className="Type-Profile-Item"
                                    >
                                        <Switch
                                            onChange={(value) => dispatch(EditarPermisoTipoUsuarioNuevoReducer('status',null, value))}
                                            size='small'
                                            checked={rex_data_tipo_usuario_nuevo.estid == 1 ? true : false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider 
                                style={{margin:'0 0 5px 0'}} 
                                orientation="left" 
                                plain
                            >
                                Permisos:
                            </Divider>
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12} className='Column-Left-Permissions-Type-User'>
                                        {Object.entries(rex_data_tipo_usuario_nuevo).length !== 0
                                        ? rex_data_tipo_usuario_nuevo.permisos.map((tpu, tpu_index) => {
                                            if(tpu_index % 2 == 0){
                                                return <div style={{border:'1px solid #EEEDED', padding:'10px', borderRadius:'5px', margin:'0 0 10px 0'}}>
                                                    <div className='Container-Type-Permission-Type-User'>
                                                        <b>{tpu.tpenombre}</b><span></span>
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                dispatch(EditarPermisoTipoUsuarioNuevoReducer('tpeid',  tpu.tpeid, e.target.checked))
                                                            }}
                                                            checked={tpu.todos_permisos}
                                                        />
                                                    </div>
                                                    {tpu.permisos.map((pem) => (
                                                        <div className='Container-Permissions-Type-User'>
                                                            <span>{pem.pemnombre}</span>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    dispatch(EditarPermisoTipoUsuarioNuevoReducer('pemid', pem.pemid, e.target.checked))
                                                                }}
                                                                checked={pem.tiene_permiso}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        })
                                        :<Skeleton /> }
                                    </Col>
                                    <Col span={12}>
                                        {Object.entries(rex_data_tipo_usuario_nuevo).length !== 0
                                            ? rex_data_tipo_usuario_nuevo.permisos.map((tpu, tpu_index) => {
                                                if(tpu_index % 2 != 0){
                                                    return <div style={{border:'1px solid #EEEDED', padding:'10px', borderRadius:'5px', margin:'0 0 10px 0'}}>
                                                            <div className='Container-Type-Permission-Type-User'>
                                                            <b>{tpu.tpenombre}</b><span></span>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    dispatch(EditarPermisoTipoUsuarioNuevoReducer('tpeid',  tpu.tpeid, e.target.checked))
                                                                }}
                                                                checked={tpu.todos_permisos}
                                                            />
                                                        </div>
                                                        {tpu.permisos.map((pem) => (
                                                            <div className='Container-Permissions-Type-User'>
                                                                <span>{pem.pemnombre}</span>
                                                                <Checkbox
                                                                    onChange={(e) => {
                                                                        dispatch(EditarPermisoTipoUsuarioNuevoReducer('pemid', pem.pemid, e.target.checked))
                                                                    }}
                                                                    checked={pem.tiene_permiso}
                                                                />
                                                            </div>
                                                        ))}  
                                                    </div> 
                                                }
                                            })
                                            :<Skeleton /> }
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div>
                        <Form.Item className='Container-Button-Type-User'>
                            <Button 
                                danger
                                className='Button-Action-Type-User'
                                onClick={() => navigate(`/admin/tipos-usuarios`)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                type="primary" 
                                ghost
                                htmlType="submit"
                                className='Button-Action-Type-User'
                            >
                                {creandoUsuario
                                ? <LoadingOutlined />
                                :<><SaveOutlined style={{marginRight:'6px'}}/>
                                    Guardar</>
                                }
                                
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default CrearTipoUsuario