import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { ObtenerMaestraProductosReducer } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import ModalSkuNoHomologaciones from '../../../Components/Homologados/NoHomologados/ModalSkuNoHomologados'
import { Row, Col, Typography, Card, Checkbox, Select, Button, AutoComplete, Input } from 'antd'
import {
    PlusOutlined,
    MinusOutlined,
} from '@ant-design/icons'
import { AddChildrenRowProductNoHomologadoFormReducer, AsignarNoHomologadosReducer, CheckSelectReducer, DeleteRowProductNoHomologadoFormReducer, GetNoHomologadosReducer, InputCodAndUnidadMedidaReducer, InputCodUnidadMedidaReducer, InputCoeficienteReducer, InputUnidadMedidaReducer, InputUnidadMinimaReducer, InputUnidadMinimaUnitarioReducer, SelectBonificadoReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import { ObtenerMaestraPreciosReducer, ObtenerSellInReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/ObtenerData'
import '../../../Styles/Routes/Homologaciones/NoHomologados/AsignarNoHomologados.css'
import dayjs from 'dayjs'
import TablaIndicadores from './TablaIndicadores'
import { funPermisosObtenidos } from '../../../Functions/funPermiso'
dayjs.locale('es')

const AsignarNoHomologados = (props) => {
    const collapsed = props.collapsed
    const [abrirModalSku, setAbrirModalSku] = useState(null)
    
    const [rangoFecha, setRangoFecha] = useState({desde: dayjs(new Date()), hasta: dayjs('30/12/9999', 'DD/MM/YYYY') })
    const [ cargandoNoHomologado, setCargandoNoHomologado ] = useState(false)
    
    const { Title } = Typography
    
    const { id, combo } = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const pathLocal = pathname.split("/")
    
    const {
        rex_data_maestra_productos_copia
    } = useSelector(({maestraProductos}) => maestraProductos)

    const {
        rex_data_user_permissions
    } = useSelector(({login}) => login)

    const {
        rex_data_no_homologados_clone,
        rex_get_data_hml,
    } = useSelector(({asignarNoHomologados}) => asignarNoHomologados)

    const ObtenerDataNoHomologado = async () => {
        setCargandoNoHomologado(true)
        await dispatch(GetNoHomologadosReducer(id, combo, pathLocal[1]))
        await dispatch(ObtenerMaestraProductosReducer())        
        setCargandoNoHomologado(false)
    }

    useEffect(() => {
        ObtenerDataNoHomologado()
    }, [])

    const return_data = () => {
        const x = rex_data_no_homologados_clone.map(data => {
            if(data.checked == true){
                return data
            }else{
                return undefined
            }
        })
        const data = x.filter(d => d!== undefined)

        return data
    }

    // const cantidadSelect = Array.from({ length: 100 }, (_, index) => index+1)
    // const cantidadSelectCoef = Array.from({ length: 300 }, (_, index) => index+1)

    return(
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container Asignar-Hml-Container">
                <Card 
                    title={
                        <Title className="Title-Module">
                            {
                                pathLocal[1] == "asignar-homologados"
                                    ?   "Asignar Homologados"
                                    :   "Asignar No Homologados"
                            }
                        </Title>
                    }
                    className="Card-Item Asignar-Hml"
                >
                    <Row style={{marginBottom: '15px'}}>
                        <Col className="Container-Header-Form" span={24}>
                            <div>#</div>
                            <div>
                                <Button
                                    onClick={async () => {
                                        let respuesta = await dispatch(AsignarNoHomologadosReducer(combo, rangoFecha, pathLocal[1]))
                                        if(respuesta){
                                            if(pathLocal[1] == "asignar-homologados"){
                                                navigate('/homologados')
                                            }else{
                                                navigate('/no-homologados')
                                            }
                                        }
                                    }}
                                    size="small"
                                >
                                    Asignar
                                </Button>
                            </div>
                            {
                                combo == "combo"
                                ?  <div className="Container-Text-Group-Combo">
                                    <div className="Text-Group-Combo">
                                        COMBO
                                    </div>
                                    <span className="Border-Group-Combo"></span>
                                </div>
                                :funPermisosObtenidos(
                                    rex_data_user_permissions,
                                    'homologar.unidad.minima',
                                    <div className="Container-Text-Group-Combo">
                                        <div className="Text-Group-Combo">
                                            SKU HML
                                        </div>
                                        <span className="Border-Group-Combo"></span>
                                    </div>
                                )
                            }
                            {
                                combo == "combo"
                                &&  <div className="Container-Text-Group-Sku">
                                        <div className="Text-Group-Sku">SKU HML</div>
                                        <span className="Border-Group-Sku"></span>
                                    </div>
                            }
                            {/* <PlusOutlined 
                                className="Add-Assign-Button" 
                                onClick={() => dispatch(AddRowProductNoHomologadoFormReducer())}
                            /> */}
                        </Col>
                    </Row>
                    <Row>
                        {
                            rex_data_no_homologados_clone.length > 0 &&
                            rex_data_no_homologados_clone.map((data, index) => {
                                const valueForm = data.valueForm
                                const emptySku = Object.keys(valueForm.sku).length == 0
                                return(
                                <>
                                <Col span={24} key={data.key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '28px'}}>
                                    <div className="Container-Tools">
                                        <div>{data.key + 1}</div>
                                        <Checkbox 
                                            className="Visible-Table-Indicators"
                                            checked={data.checked}
                                            onChange={(e) => dispatch(CheckSelectReducer(e.target.checked, data.key))}
                                        />
                                        <p className="Text-Tools">{data.unidad_medida}</p>
                                        <div 
                                            style={{
                                                marginLeft:'13px'
                                            }}
                                            className="Text-Placeholder-Input-Bottom"
                                        >Unidad de Medida</div>
                                        <div className="Container-Input">
                                            {
                                                !emptySku
                                                ?   <div className="Text-Placeholder-Input">SKU</div>
                                                :   data.master_productos_grow
                                                    ?   <div className="Text-Placeholder-Input">SKU</div>
                                                    :   null
                                            }
                                            <Select
                                                placeholder="SKU"
                                                className="Form-Item-Select"
                                                onClick={() => setAbrirModalSku(data.key)}
                                                open={false}
                                                value={
                                                    !emptySku
                                                        ?   data.valueForm.sku.cod_producto + " - " + data.valueForm.sku.nomb_producto
                                                        :   data.master_productos_grow
                                                            ?   data.master_productos_grow.codigo_material + "-" + data.master_productos_grow.material_softys
                                                            :   null
                                                }
                                            />
                                            <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                        </div>
                                        {
                                            data.key == abrirModalSku   &&  
                                            <ModalSkuNoHomologaciones
                                                abrirModalSku={abrirModalSku}
                                                setAbrirModalSku={setAbrirModalSku}
                                                rex_data_maestra_productos={rex_data_maestra_productos_copia}
                                                idRow={data.key}
                                                id_producto_so={data.id}
                                                producto_so_select={data}
                                                aceptar={() => {
                                                    if(!emptySku){
                                                        dispatch(ObtenerMaestraPreciosReducer(data.valueForm.sku.cod_producto, index))
                                                        dispatch(ObtenerSellInReducer(data.valueForm.sku, index, data.valueForm.sku.id_producto_homologado))
                                                    }
                                                }}
                                            /> 
                                        }
                                        {
                                            combo == "no-combo"
                                            ? funPermisosObtenidos(
                                                rex_data_user_permissions,
                                                'homologar.unidad.minima',
                                                <div className="Container-Input">
                                                    <div className="Text-Placeholder-Input Text-Spaced">UN MIN</div>
                                                    <Input 
                                                        placeholder="UN MIN"
                                                        className="Filter-Input"
                                                        onChange={(e) => {
                                                            if(/^\d*([.,]\d*)?$/.test(e.target.value)){
                                                                dispatch(InputUnidadMinimaReducer(data.key, e.target.value))
                                                            }
                                                        }}
                                                        value={
                                                            valueForm.unmin || valueForm.unmin == ''
                                                                ?   valueForm.unmin
                                                                :   data.unidad_minima
                                                                    ?   data.unidad_minima
                                                                    : 1
                                                        }
                                                    />
                                                    <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                                </div>
                                            )
                                            :<div className="Container-Input">
                                                <div className="Text-Placeholder-Input Text-Spaced">UN MIN</div>
                                                <Input 
                                                    placeholder="UN MIN"
                                                    className="Filter-Input"
                                                    onChange={(e) => {
                                                        if(/^\d*([.,]\d*)?$/.test(e.target.value)){
                                                            dispatch(InputUnidadMinimaReducer(data.key, e.target.value))
                                                        }
                                                    }}
                                                    value={
                                                        valueForm.unmin || valueForm.unmin == ''
                                                            ?   valueForm.unmin
                                                            :   data.unidad_minima
                                                                ?   data.unidad_minima
                                                                : 1
                                                    }
                                                />
                                                <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                            </div>
                                        }
                                        {
                                            combo == "combo" &&
                                            <>
                                            <div className="Container-Input-Un-Med">
                                                <div className="Container-Input">
                                                    {
                                                        valueForm.codunmed
                                                        ? <div className="Text-Placeholder-Input Text-Spaced">COD UN</div>
                                                        : data.cod_unidad_medida_hml
                                                            ?   <div className="Text-Placeholder-Input Text-Spaced">COD UN</div>
                                                            :   null
                                                    }
                                                    <AutoComplete
                                                        className="Input-Cod-Un-Med"
                                                        popupClassName="Input-Cod-Un-Med-Dropdown"
                                                        onSelect={(e) => dispatch(InputCodAndUnidadMedidaReducer(data.key, e))}
                                                        onChange={(e) => dispatch(InputCodUnidadMedidaReducer(data.key, e))}
                                                        options={
                                                            rex_get_data_hml.map(gdh => {
                                                                return {
                                                                    value: gdh.cod_unidad_medida_hml + " - " + gdh.unidad_medida_hml
                                                                }
                                                            })
                                                        }
                                                        placeholder="COD UN"
                                                        // open={
                                                        //     openSelectVisible.key == data.key
                                                        //         ?   openSelectVisible.visible
                                                        //         :   false
                                                        // }
                                                        // onDropdownVisibleChange={(op) => setOpenSelectVisible({key: data.key, visible: op})}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        value={
                                                            valueForm.codunmed || valueForm.codunmed == ''
                                                            ? valueForm.codunmed 
                                                            : data.cod_unidad_medida_hml
                                                                ?   data.cod_unidad_medida_hml
                                                                :   null
                                                        }
                                                    />
                                                    <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                                </div>
                                                <div className="Separation-Input">-</div>
                                                <div className="Container-Input">
                                                    {
                                                        valueForm.unmed
                                                        ? <div className="Text-Placeholder-Input Text-Spaced">UN MED</div>
                                                        : data.unidad_medida_hml
                                                            ?   <div className="Text-Placeholder-Input Text-Spaced">UN MED</div>
                                                            :   null
                                                    }
                                                    <AutoComplete
                                                        className="Input-Un-Med"
                                                        popupClassName="Input-Un-Med-Dropdown"
                                                        onSelect={(e) => dispatch(InputCodAndUnidadMedidaReducer(data.key, e))}
                                                        onChange={(e) => dispatch(InputUnidadMedidaReducer(data.key, e))}
                                                        options={
                                                            rex_get_data_hml.map(gdh => {
                                                                return {
                                                                    value: gdh.cod_unidad_medida_hml + " - " + gdh.unidad_medida_hml
                                                                }
                                                            })
                                                        }
                                                        placeholder="UN MED"
                                                        // onDropdownVisibleChange={(op) => setOpenSelectVisible({key: data.key, visible: !openSelectVisible.visible})}
                                                        open={false}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        value={
                                                            valueForm.unmed || valueForm.unmed == ''
                                                            ? valueForm.unmed 
                                                            : data.unidad_medida_hml
                                                                ?   data.unidad_medida_hml
                                                                :   null
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="Container-Input">
                                                <div className="Text-Placeholder-Input Text-Spaced">COEF</div>
                                                <Input 
                                                    placeholder="COEF"
                                                    className="Filter-Input"
                                                    onChange={(e) => {
                                                        if(/^\d*([.,]\d*)?$/.test(e.target.value)){
                                                            dispatch(InputCoeficienteReducer(data.key, e.target.value))
                                                        }
                                                    }}
                                                    value={
                                                        valueForm.coef || valueForm.coef == ''
                                                            ? valueForm.coef 
                                                            : data.coeficiente
                                                                ?   data.coeficiente
                                                                :   1
                                                    }
                                                />
                                                <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                            </div>
                                            <div className="Container-Input">
                                                <div className="Text-Placeholder-Input Text-Spaced">UN MIN UNI</div>
                                                <Input 
                                                    placeholder="UN MIN UNI"
                                                    className="Filter-Input"
                                                    onChange={(e) => {
                                                        if(/^\d*([.,]\d*)?$/.test(e.target.value)){
                                                            dispatch(InputUnidadMinimaUnitarioReducer(data.key, e.target.value))
                                                        }
                                                    }}
                                                    value={
                                                        valueForm.unminuni || valueForm.unminuni == ''
                                                            ? valueForm.unminuni 
                                                            : data.unidad_minima_unitaria
                                                                ?   data.unidad_minima_unitaria
                                                                :   1
                                                    }
                                                />
                                                <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                            </div>
                                            <div className="Container-Input">
                                                {
                                                    valueForm.bonif || valueForm.bonif == false
                                                    ?   <div className="Text-Placeholder-Input Text-Spaced">BONIF</div>
                                                    :   data.bonificado || data.bonificado == false
                                                        ?   <div className="Text-Placeholder-Input Text-Spaced">BONIF</div>
                                                        :   null
                                                }
                                                <Select
                                                    size='medium'
                                                    placeholder="BONIF"
                                                    className="Filter-Select"
                                                    value={
                                                        valueForm.bonif || valueForm.bonif == false
                                                            ?   valueForm.bonif
                                                            :   data.bonificado || data.bonificado == false
                                                                ?   data.bonificado
                                                                :   null
                                                    }
                                                    options={[
                                                        {
                                                            value: true,
                                                            label: 'Sí',
                                                        },
                                                        {
                                                            value: false,
                                                            label: 'No',
                                                        },
                                                    ]}
                                                    onChange={(e) => dispatch(SelectBonificadoReducer(data.key, e))}
                                                />
                                                <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                            </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        combo == "combo"
                                        &&  <div>
                                                {
                                                    data.delete
                                                    ?   <MinusOutlined 
                                                            className="Remove-Assign-Button" 
                                                            onClick={() => dispatch(DeleteRowProductNoHomologadoFormReducer(data.key))}
                                                        />
                                                    :   <PlusOutlined 
                                                            className="Add-Assign-Button"  
                                                            onClick={() => dispatch(AddChildrenRowProductNoHomologadoFormReducer(data.key, data.cod_unidad_medida, data.id, data.unidad_medida))}
                                                        />
                                                }
                                            </div>
                                    }
                                </Col>
                                </>
                                )
                            })
                        }
                    </Row>

                    <TablaIndicadores 
                        data_table = {return_data()}
                        loading_table = {cargandoNoHomologado}
                    />
                </Card>
            </Card>
        </div>
    )
}

export default AsignarNoHomologados