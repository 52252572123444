import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Typography, Table, Button, Input, Skeleton, Row, Col, Tooltip, Popconfirm, Spin } from 'antd'
import { GetDataAssignmentProductMassive, GetProductAssignmentProductMassive, LoadingAssignmentProductMassive, SelectedProductAssignmentProductMassive, UpdateAssignmentProductMassive } from '../../../Redux/Actions/Administracion/AssignmentProductMassive/AssignmentProductMassive'
import { 
    GetDataHomologationsKeyWordsReducer,
    DeleteHomologationsKeyWordsReducer,
    CreateHomologationsKeyWordsReducer,
    CreateValueHomologationKeyWordReducer,
    UpdateHomologationsKeyWordsReducer,
    UpdateValueHomologationKeyWordReducer,
    ActiveUpdateHomologarionKeyWordReducer,
    ClearHomologarionKeyWordReducer,
} from '../../../Redux/Actions/Administracion/HomologationsKeyWords/HomologationsKeyWords'
import {
    LoadingOutlined,
    SaveOutlined,
    DeleteOutlined,
    FilterOutlined,
    EditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'
import '../../../Styles/Routes/AssignmentProductMassive/AssignmentProductMassive.css'

const AssignmentProductMassive = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const [loadingAssignment, setLoadingAssignment] = useState(false)
    const [loadingKeyWords, setLoadingKeyWords] = useState(false)
    const [loadingEditKeyWords, setLoadingEditKeyWords] = useState(false)
    const [loadingDeleteKeyWords, setLoadingDeleteKeyWords] = useState(false)
    const [numberIdDelete, setNumberIdDelete] = useState(null)
    const [searchText, setSearchText] = useState(null)

    const dispatch = useDispatch()

    const {
        rex_data_assignment_product_massive,
        rex_selected_product,
        rex_loading_assignment_product_massive,
    } = useSelector(({assignmentProductMassive}) => assignmentProductMassive)

    const {
        rex_data_homologations_key_words,
        rex_create_homlogation_key_word,
        rex_update_homlogation_key_word,
        rex_loading_homologations_key_words,
    } = useSelector(({homologationsKeyWords}) => homologationsKeyWords)

    useEffect(() => {
        dispatch(GetProductAssignmentProductMassive())
        dispatch(GetDataHomologationsKeyWordsReducer())
    }, [])

    useEffect(() => {
        const timeOutInactividad = setTimeout(() => {
            dispatch(GetDataAssignmentProductMassive(searchText))
        }, 1000)
    
        return () => clearTimeout(timeOutInactividad)
    }, [searchText])

    const selectedRow = (record) => {
        return {
            onClick: event => {
                dispatch(SelectedProductAssignmentProductMassive({codigo_material: record.codigo_material, material_softys: record.material_softys}))
            }
        }
    }

    const assignmentProductMassive = async () => {
        setLoadingAssignment(true)
        const response = await dispatch(UpdateAssignmentProductMassive())
        if(response) setLoadingAssignment(false)
    }

    const createKeyWords = async () => {
        setLoadingKeyWords(true)
        const response = await dispatch(CreateHomologationsKeyWordsReducer())
        setLoadingKeyWords(false)
        if(response){
            dispatch(GetDataHomologationsKeyWordsReducer())
            dispatch(UpdateValueHomologationKeyWordReducer({}))
        }
    }

    const editKeyWords = async () => {
        setLoadingEditKeyWords(true)
        const response = await dispatch(UpdateHomologationsKeyWordsReducer())
        setLoadingEditKeyWords(false)
        if(response){
            dispatch(GetDataHomologationsKeyWordsReducer())
            dispatch(UpdateValueHomologationKeyWordReducer({}))
        }
    }

    const deleteKeyWords = async (pchid) => {
        setNumberIdDelete(pchid)
        setLoadingDeleteKeyWords(true)
        const response = await dispatch(DeleteHomologationsKeyWordsReducer(pchid))
        setLoadingDeleteKeyWords(false)
        setNumberIdDelete(null)
        if(response){
            dispatch(GetDataHomologationsKeyWordsReducer())
            dispatch(UpdateValueHomologationKeyWordReducer({}))
        }
    }

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color:'blue'}}/>),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    })

    const columns = [
        {
            title: 'Código material',
            dataIndex: 'codigo_material',
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material}>{codigo_material}</div>,
            width: '80px'
        },
        {
            title: 'Material',
            dataIndex: 'material_softys',
            showSorterTooltip: false,
            render: (material_softys) => <div title={material_softys}>{material_softys}</div>,
            width: '120px'
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria_marketing',
            showSorterTooltip: false,
            render: (categoria_marketing) => <div title={categoria_marketing}>{categoria_marketing}</div>,
            width: '80px'
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'subcategoria_marketing',
            showSorterTooltip: false,
            render: (subcategoria_marketing) => <div title={subcategoria_marketing}>{subcategoria_marketing}</div>,
            width: '80px'
        },
    ]

    const columns_keywords = [
        {
            title: 'N°',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (key) => <div title={key}>{key}</div>,
            width: '50px'
        },
        {
            title: 'Palabra Clave',
            dataIndex: 'pchpalabra',
            ...getColumnSearchProps('pchpalabra', 'palabras clave'),
            showSorterTooltip: false,
            render: (pchpalabra, record) => <>
                {
                    record.editable
                    ?   <div>
                            <Input 
                                size='small'
                                onChange={(e) => dispatch(UpdateValueHomologationKeyWordReducer({id: record.pchid, palabra: e.target.value}))}
                                value={rex_update_homlogation_key_word.palabra} 
                            />
                        </div>
                    :   <div title={pchpalabra}>{pchpalabra}</div>
                }
            </>,
            width: '150px'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div style={{display: 'flex', justifyContent: 'center'}}>
                {
                    record.editable
                    ?   <>
                        <Spin
                            spinning={loadingEditKeyWords && record.editable ? true : false}
                            indicator={<LoadingOutlined style={{fontSize: '16px'}} spin />} 
                        >
                            <CheckCircleOutlined 
                                style={{fontSize:'16px', color:'green', cursor:'pointer', margin:'0 2px'}}
                                onClick={() => editKeyWords()}
                            />
                            <CloseCircleOutlined 
                                style={{fontSize:'16px', color:'gray', cursor:'pointer', margin:'0 2px'}}
                                onClick={() => dispatch(ClearHomologarionKeyWordReducer())}
                            />
                        </Spin>
                    </>
                    :   <>
                        <Spin
                            spinning={loadingDeleteKeyWords && numberIdDelete == record.pchid ? true : false}
                            indicator={<LoadingOutlined style={{fontSize: '16px'}} spin />} 
                        >
                            <EditOutlined 
                                onClick={() => dispatch(ActiveUpdateHomologarionKeyWordReducer(record.pchid, record.pchpalabra))}
                                style={{fontSize:'16px', color:'blue', cursor:'pointer', margin:'0 2px'}} 
                            />
                            <Popconfirm
                                title='Eliminar palabra clave'
                                description='Estás seguro de eliminar la palabra clave?'
                                okText='Confirmar'
                                cancelText='Cancelar'
                                placement="bottomRight"
                                onConfirm={() => deleteKeyWords(record.pchid)}
                            >
                                <DeleteOutlined 
                                    style={{fontSize:'16px', color:'red', cursor:'pointer', margin:'0 2px'}}
                                />
                            </Popconfirm>
                        </Spin>
                    </>
                }
            </div>,
            width: '50px',
            align:'center'
        }
    ]

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Row>
                            <Col span={12}>
                                <Title 
                                    className="Title-Module" 
                                    style={{lineHeight: '20px'}}
                                >
                                    Asignacion de Producto para homologación masiva
                                </Title>
                            </Col>
                            <Col span={12}>
                                <Title 
                                    className="Title-Module" 
                                    style={{lineHeight: '20px'}}
                                >
                                    Palabras claves para homologacion automática
                                </Title>
                            </Col>
                        </Row>
                    }
                    className="Card-Item"
                >
                    <Row gutter={20}>
                        <Col span={12}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="Title-Product-Assignment">Producto asignado: </div>
                                    <Tooltip
                                        title="Al momento de escribir en el buscador aparecerán los productos."
                                        color="#4096ff"
                                    >
                                        <Input
                                            style={{marginBottom: '10px', width: '400px'}}
                                            onChange={(e) => {
                                                setSearchText(e.target.value)
                                                if(e.target.value){
                                                    dispatch(LoadingAssignmentProductMassive())
                                                }
                                            }}
                                            placeholder="Buscar productos..." 
                                        />
                                    </Tooltip>
                                </div>
                                <Input 
                                    className="Input-Product-Assignment"
                                    disabled={true} 
                                    value={
                                        rex_selected_product.codigo_material || rex_selected_product.material_softys
                                        ? rex_selected_product.codigo_material + " - " + rex_selected_product.material_softys
                                        : null
                                    } 
                                />
                                <div className="Container-Button-Assignment-Product" style={{marginBottom: '12px'}}>
                                    <Button 
                                        type="primary" 
                                        ghost
                                        style={{ width: '160px' }}
                                        onClick={() => assignmentProductMassive()}
                                    >
                                        { 
                                            loadingAssignment
                                            ? <LoadingOutlined />
                                            : <>
                                                <SaveOutlined style={{marginRight:'6px'}}/>
                                                Asignar Producto
                                            </>
                                        }
                                    </Button>
                                </div>
                            </div>
                            <div>
                            {
                                searchText
                                ? rex_loading_assignment_product_massive
                                    ? <div style={{marginTop:'10px'}}>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </div>
                                    : <Table 
                                        pagination={{
                                            position: ["none", "topRight"],
                                            pageSize: 15,
                                        }}
                                        loading={rex_loading_assignment_product_massive}
                                        onRow={selectedRow}
                                        columns={columns}
                                        dataSource={rex_data_assignment_product_massive} 
                                        className="Table-Home Table-Assignment-Product-Massive"
                                        size='small'
                                    />
                                : null
                            }
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px'}}>
                                <Input 
                                    style={{width: '350px'}}
                                    onChange={(e) => dispatch(CreateValueHomologationKeyWordReducer(e.target.value))}
                                    className="Input-Product-Assignment"
                                    placeholder="Palabras claves"
                                    value={rex_create_homlogation_key_word}
                                />
                                <Button 
                                    type="primary" 
                                    ghost
                                    style={{ width: '160px' }}
                                    onClick={() => createKeyWords()}
                                    loading={loadingKeyWords}
                                >
                                    <SaveOutlined style={{marginRight:'6px'}}/>
                                    Nueva Palabra
                                </Button>
                            </div>
                            {
                                rex_loading_homologations_key_words
                                ? <div style={{marginTop:'10px'}}>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                                : <Table 
                                    pagination={{
                                        position: ["none", "topRight"],
                                        pageSize: 15,
                                    }}
                                    columns={columns_keywords}
                                    dataSource={rex_data_homologations_key_words} 
                                    className="Table-Home Table-Words-Keys"
                                    size='small'
                                />
                            }
                        </Col>
                    </Row>
                </Card>
            </Card>
        </div>
    )
}

export default AssignmentProductMassive