import config from '../../../../config'
import { notification } from 'antd'
import {
    GET_DATA_ZONA_MASTER_CLIENTES,
    GET_DATA_CANAL_ATENCION_MASTER_CLIENTES,
    GET_DATA_SEGMENTO_REGIONAL_MASTER_CLIENTES,
    LOADING_ZONA,
    LOADING_CANAL_ATENCION,
    LOADING_SEGMENTO_REGIONAL,
} from '../../../../Constans/Administracion/Administrador/ManageMasterClientes'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataManageMasterClientesReducer = () => async ( dispatch ) => {

    dispatch({
        type : LOADING_ZONA,
        payload : true       
    })

    dispatch({
        type : LOADING_CANAL_ATENCION,
        payload : true       
    })

    dispatch({
        type : LOADING_SEGMENTO_REGIONAL,
        payload : true       
    })

    await fetch(config.api_url+'manage/get-manage-master-clientes',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_zona = data.data_zona.map(zona => ({ value: zona.zonid, label: zona.zonnombre, active: zona.zonactive}))
            const data_canal_atencion = data.data_canal_atencion.map(canal => ({ value: canal.cnaid, label: canal.cnanombre, active: canal.cnaactive}))
            const data_segmento_regional = data.data_segmento_regional.map(segmento => ({ value: segmento.sgrid, label: segmento.sgrnombre, active: segmento.sgractive}))
            dispatch({
                type: GET_DATA_ZONA_MASTER_CLIENTES,
                payload: data_zona
            })
            dispatch({
                type: GET_DATA_CANAL_ATENCION_MASTER_CLIENTES,
                payload: data_canal_atencion
            })
            dispatch({
                type: GET_DATA_SEGMENTO_REGIONAL_MASTER_CLIENTES,
                payload: data_segmento_regional
            })
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : LOADING_ZONA,
        payload : false       
    })

    dispatch({
        type : LOADING_CANAL_ATENCION,
        payload : false       
    })

    dispatch({
        type : LOADING_SEGMENTO_REGIONAL,
        payload : false       
    })
}

export const UpdateDataManageMasterClientesReducer = () => async (dispatch, getState) => {
    const { 
        rex_data_zona_master_clientes,
        rex_data_canal_atencion_master_clientes,
        rex_data_segmento_regional_master_clientes,
    } = getState().manageMasterClientes

    const req_data_zona = rex_data_zona_master_clientes.filter(zon => zon.active)
    const req_data_canal = rex_data_canal_atencion_master_clientes.filter(can => can.active)
    const req_data_segmento = rex_data_segmento_regional_master_clientes.filter(seg => seg.active)

    await fetch(config.api_url+'manage/update-manage-master-clientes',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_data_zona: req_data_zona,
                req_data_canal_atencion: req_data_canal,
                req_data_segmento_regional: req_data_segmento,
            })
        }
    )
    .then(res => res.json())
    .then(async data => {                
        if(data.response){
            notificacionServidor('success', data.message)
            await dispatch(GetDataManageMasterClientesReducer())
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const ChangeDataManageMasterClientesReducer = (type, data) => async (dispatch, getState) => {
    const { 
        rex_data_zona_master_clientes,
        rex_data_canal_atencion_master_clientes,
        rex_data_segmento_regional_master_clientes,
    } = getState().manageMasterClientes

    if(type === "zona"){
        const data_zona = rex_data_zona_master_clientes.map(zon => {
            const find_zon = data.find(dt => dt === zon.value) 
            if(find_zon){
                return { ...zon, active: true }
            }
            return { ...zon, active: false }
        })

        dispatch({
            type: GET_DATA_ZONA_MASTER_CLIENTES,
            payload: data_zona
        })
    }else if(type === "canal_atencion"){
        const data_canal_atencion = rex_data_canal_atencion_master_clientes.map(canal => {
            const find_canal = data.find(dt => dt === canal.value) 
            if(find_canal){
                return { ...canal, active: true }
            }
            return { ...canal, active: false }
        })

        dispatch({
            type: GET_DATA_CANAL_ATENCION_MASTER_CLIENTES,
            payload: data_canal_atencion
        })
    }else if(type === "segmento_regional"){
        const data_segmento_regional = rex_data_segmento_regional_master_clientes.map(seg => {
            const find_seg = data.find(dt => dt === seg.value) 
            if(find_seg){
                return { ...seg, active: true }
            }
            return { ...seg, active: false }
        })

        dispatch({
            type: GET_DATA_SEGMENTO_REGIONAL_MASTER_CLIENTES,
            payload: data_segmento_regional
        })
    }
}